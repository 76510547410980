import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #f1f1f1;
  gap: 12px;
  background: white;
  padding-inline: 12px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 280px - 2rem);
  padding: 12px;

  @media (max-width: 1199px) {
    width: 100vw;
    left: 0;
    height: 75px;
  }
`;
