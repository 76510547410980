import React, { useCallback, useState, useEffect } from 'react';

import { Hidden } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import DrawerPersist from '../DrawerPersist';
import DrawerSwipeable from '../DrawerSwipeable';
import { Container, ContainerMenu } from './styles';
import { MenuDrawerProps, Route, SelectedRoutes, SubRoute } from './types';

const MenuDrawer = ({
  routes,
  OpenDrawerSwipeable,
  handleChangeOpenDrawerSwipeable,
  isClubeBlack
}: MenuDrawerProps) => {
  const [selectedRoute, setSelectedRoute] = useState<SelectedRoutes>();
  const [paths, setPaths] = useState<Array<string>>();
  const [notificationInSubMenu, setNotificationInSubMenu] = useState('');
  const history = useHistory();

  const { pathname } = useLocation();

  useEffect(() => {
    const filteredPaths = pathname.split('/').map(item => `/${item}`);
    filteredPaths.shift();
    setPaths(filteredPaths);
  }, [pathname]);

  useEffect(() => {
    const saveActualRoutes = () => {
      const [firstUrlPath] = paths;
      const routeId = paths.find(path => /\d/.test(path));
      const actualRoute = routes.find(route => route.path === firstUrlPath);
      const actualSubRoute = actualRoute?.subRoutes?.find(subRoute => paths.includes(subRoute.path));
      setSelectedRoute({ route: actualRoute, subRoute: actualSubRoute, routeId });
    };

    !!paths && saveActualRoutes();
  }, [paths, routes]);

  const goToRoute = useCallback(
    path => {
      history.push(path);
      handleChangeOpenDrawerSwipeable();
    },
    [handleChangeOpenDrawerSwipeable, history]
  );

  useEffect(() => {
    const addNotification = event => {
      if (event.type === 'inform-sale-links') {
        if (event.detail === 'open') {
          setNotificationInSubMenu('/sale-links');
        }
        if (event.detail === 'close') {
          setNotificationInSubMenu('');
        }
      }
    };

    document.addEventListener('inform-sale-links', addNotification);
    return () => document.removeEventListener('inform-sale-links', addNotification);
  }, []);

  const createRouteItem = useCallback(
    (route: Route) => {
      const isSelected = selectedRoute?.route?.path === route.path;

      return (
        <ContainerMenu
          data-testid={`menu-route-item-${route.name}`}
          key={route.name}
          className={isSelected && 'item-route-selected'}
          isClubeBlack={isClubeBlack}
        >
          <div className={`${isSelected ? 'item-menu selected' : 'item-menu'}`} onClick={() => goToRoute(route.path)}>
            <span>{route.icon}</span>
            <span>{route.name}</span>
          </div>
        </ContainerMenu>
      );
    },
    [goToRoute, selectedRoute, isClubeBlack]
  );

  const createSubRouteItem = useCallback(
    (subRoute: SubRoute) => {
      const isActive = selectedRoute.subRoute.path === subRoute.path;
      const createdRoute = selectedRoute.route.path + selectedRoute.routeId + subRoute?.path;
      const hasNotitifcation = subRoute.path === notificationInSubMenu;

      return (
        <div
          className={`${isActive && 'selected'} ${hasNotitifcation && 'hasNotification'} item-sub-menu`}
          key={subRoute.name}
          onClick={() => goToRoute(createdRoute)}
        >
          {subRoute.name}
        </div>
      );
    },
    [goToRoute, selectedRoute, notificationInSubMenu]
  );

  const createMenuContent = useCallback(() => {
    const showSubmenu = selectedRoute?.subRoute;
    return (
      <Container
        className={showSubmenu && 'sub-route-selected'}
        data-testid='menu-drawer-content'
        isClubeBlack={isClubeBlack}
      >
        <div className='main-menu'>
          <div className='body'>{routes.map(createRouteItem)}</div>
        </div>
        {showSubmenu && (
          <div className='sub-menu'>
            {selectedRoute.route?.subRoutes?.filter(subRoute => subRoute.isSubRoute).map(createSubRouteItem)}
          </div>
        )}
      </Container>
    );
  }, [createRouteItem, createSubRouteItem, routes, selectedRoute, isClubeBlack]);

  return (
    <Container data-testid='menu-drawer' style={{ zIndex: '100', position: 'relative' }}>
      <Hidden mdUp>
        <DrawerSwipeable
          data-testid='menu-drawer-swipeable'
          open={OpenDrawerSwipeable}
          onOpen={handleChangeOpenDrawerSwipeable}
          onClose={handleChangeOpenDrawerSwipeable}
        >
          {createMenuContent()}
        </DrawerSwipeable>
      </Hidden>
      <Hidden smDown>
        <DrawerPersist data-testid='menu-drawer-persist' className='drawer-persist'>
          {createMenuContent()}
        </DrawerPersist>
      </Hidden>
    </Container>
  );
};

export default MenuDrawer;
