import { Dialog } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Dialog)`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    display: flex;
    flex-direction: column;

    .modal-delete-container {
      display: flex;
      flex-direction: column;
      padding: 24px;

      .modal-delete-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: ${colors.primary.contrastText};

        h2 {
          font-weight: ${fontWeight('semibold')};
          font-size: 16px;
        }

        .modal-delete-cancel {
          cursor: pointer;
        }
      }

      .modal-delete-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${colors.primary.contrastText};

        margin: 25px 0 60px 0;

        p {
          margin: 0 0 0 10px;
        }
      }

      .modal-delete-buttons {
        display: flex;
        justify-content: flex-end;

        button {
          color: #000000;
          border-color: #d9d9d9;
          margin-left: 16px;
        }
      }
    }

    @media (${breakpoints.md}) {
      .modal-delete-container {
        width: 600px;
      }
    }
  `}
`;
