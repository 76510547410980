import { IEvent } from 'interfaces/event';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import { CDN_URL } from 'settings';

import { CustomTemplateData, PageContent } from './types';

class CustomTemplateService {
  private getTemplateData(data: IEvent): CustomTemplateData {
    return {
      eventHeader: {
        isLive: data.is_live,
        title: data.title,
        eventId: data.id,
        date: { start: `${data.start_date} às ${data.start_hour}`, end: `${data.end_date} às ${data.end_hour}` },
        image: CDN_URL + data?.image_path
      },
      pageContent: {
        page_config: JSON.parse(data.page_config),
        sale_url: data.sale_url,
        description: data.description,
        draft_description: data.description,
        draft_page_config: JSON.parse(data.draft_page_config)
      }
    };
  }

  public get(id: string): Rx.Observable<CustomTemplateData> {
    return eventService.details<IEvent>(id).pipe(RxOp.map(response => this.getTemplateData(response)));
  }

  public put(id: string, data: Partial<PageContent>) {
    delete data['description'];

    return eventService.edit(id, data as unknown as Partial<IEvent>).pipe(RxOp.logError());
  }
}
const customTemplateService = new CustomTemplateService();
export default customTemplateService;
