import React, { PureComponent } from 'react';

import { CheckCircleOutlined, CopyOutlined, TagOutlined } from '@ant-design/icons';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';
import Truncate from 'components/Shared/Truncate';
import { WithRouter } from 'decorators/withRouter';
import { WithStyles } from 'decorators/withStyles';
import { hjTagging } from 'helpers/functions';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';

import RouteButton from '../../RouteButton';
import styles from './styles';
import { IProps, IStates } from './types';

@WithRouter()
@WithStyles(styles)
export default class TableCol extends PureComponent<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: null,
      copied: false
    };
  }

  private phoneMask = (phone: string) => {
    const phoneArray = phone.replace(/(\D)+/g, '').split('');

    const nineDigitsMask = (phone: string[]) => {
      phone.splice(0, 0, ' (');
      phone.splice(3, 0, ') ');
      phone.splice(9, 0, '-');
      return phone.join('');
    };

    const eightDigitsMask = (phone: string[]) => {
      phone.splice(0, 0, ' (');
      phone.splice(3, 0, ') ');
      phone.splice(8, 0, '-');
      return phone.join('');
    };

    const extraDigitsMask = (phone: string[]) => {
      phone.splice(0, 0, '+');
      return phone.join('');
    };

    if (phoneArray.length === 11) {
      return nineDigitsMask(phoneArray);
    }

    if (phoneArray.length === 10) {
      return eightDigitsMask(phoneArray);
    }

    if (phoneArray.length > 11) {
      const nineDigits = phoneArray.slice(phoneArray.length - 11);
      const extraDigits = phoneArray.slice(0, phoneArray.length - 11);
      return extraDigitsMask(extraDigits) + nineDigitsMask(nineDigits);
    }
    return phone;
  };

  private docMask = (document: string) => {
    const docArray = document.replace(/(\D)+/g, '').split('');

    const cpfMask = (doc: string[]) => {
      doc.splice(3, 0, '.');
      doc.splice(7, 0, '.');
      doc.splice(11, 0, '-');
      return doc.join('');
    };

    const cnpjMask = (doc: string[]) => {
      doc.splice(2, 0, '.');
      doc.splice(6, 0, '.');
      doc.splice(10, 0, '/');
      doc.splice(15, 0, '-');
      return doc.join('');
    };

    if (docArray.length === 11) {
      return cpfMask(docArray);
    }

    if (docArray.length === 13 || docArray.length === 14) {
      return cnpjMask(docArray);
    }
    return document;
  };

  public handleClickMenu = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleClose = (event: any, callback?: any) => {
    event.stopPropagation();
    this.setState({ anchorEl: null }, () => callback && typeof callback === 'function' && callback());
  };

  public handleClickCopy = () => {
    this.setState({ copied: true }, () => {
      setTimeout(() => this.setState({ copied: false }), 500);
    });
  };

  private ticketsNamesGroupByName = (datastring: string) => {
    if (!!datastring && datastring.substr(-1, 1) === ';') {
      datastring = datastring.substr(0, datastring.length - 1);
    }
    const dataset = (datastring + '').trim().split(';');
    const counts = {};
    let i, value;
    for (i = 0; i < dataset.length; i++) {
      value = dataset[i];
      if (typeof counts[value] === 'undefined') {
        counts[value] = 1;
      } else {
        counts[value]++;
      }
    }

    return counts;
  };

  public typesRender = () => {
    const { classes, children, type, max, actions, title, customStyle, id, eventId } = this.props;
    const { value } = this.props;
    const { anchorEl, copied } = this.state;

    if (type === 'multipleItemBadge') {
      value.badgeText = ' Múltiplos';
      value.invite_key = (value.invite_key + '').trim().split(' ');

      if (value && value.invite_key.length > 1) {
        hjTagging('page:presencelist-multiple');

        const ticketsNamesGroupByName = this.ticketsNamesGroupByName(value.ticket_names);

        let hasMultiplesTicketTypes = false;
        if (Object.keys(ticketsNamesGroupByName).length > 1) {
          hasMultiplesTicketTypes = true;
        }

        if (!hasMultiplesTicketTypes) {
          const uniqueTicketName = Object.keys(ticketsNamesGroupByName)[0];
          value.badgeText = uniqueTicketName;
        }
      }
    }

    if (type === 'multipleItem') {
      value.badgeText = ' Múltiplos';
      value.invite_key = (value.invite_key + '').trim().split(' ');

      if (value && value.total > 1) {
        hjTagging('page:presencelist-multiple');

        const ticketsNamesGroupByName = this.ticketsNamesGroupByName(value.ticket_names);

        let hasMultiplesTicketTypes = false;
        if (Object.keys(ticketsNamesGroupByName).length > 1) {
          hasMultiplesTicketTypes = true;
        }

        if (!hasMultiplesTicketTypes) {
          const uniqueTicketName = Object.keys(ticketsNamesGroupByName)[0];
          value.badgeText = uniqueTicketName;
        }
      }
    }

    return (
      <>
        {type === 'status' && (value === 'presence_confirmed' || value === 'confirmed') && (
          <Tooltip title={value === 'presence_confirmed' ? 'Presença confirmada.' : 'Participou!'}>
            {value === 'presence_confirmed' ? (
              <CheckCircleOutlined style={{ color: '#777' }} />
            ) : (
              <CheckCircleOutlined style={{ color: 'green' }} />
            )}
          </Tooltip>
        )}

        {type === 'status-text' && <Typography className={classes.typographyString}>{value}</Typography>}

        {type === 'tag' && (
          <div className='tag'>
            {value?.length > 0 &&
              value.map((item: any) => (
                <Tooltip title={item.title} key={item.id + '-' + item.invite_key}>
                  <span style={{ color: item.color }}>
                    <TagOutlined />
                  </span>
                </Tooltip>
              ))}
          </div>
        )}

        {type === 'multipleItemBadge' && (
          <div className='string'>
            {value && value.invite_key.length > 1 && (
              <Tooltip title={value.invite_key.join(' ')} interactive>
                <Chip
                  avatar={
                    <Avatar style={{ color: '#FFFFFF', backgroundColor: '#e2216f' }}>{value.invite_key.length}</Avatar>
                  }
                  label={` ${value.badgeText.substring(0, 15)}...`}
                  style={{ fontWeight: 400, color: '#e2216f' }}
                />
              </Tooltip>
            )}
            {value && value.invite_key.length <= 1 && (
              <Tooltip title={value.invite_key.join(' ')}>
                <span>{value.invite_key[0]}</span>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'multipleItem' && (
          <div className='string'>
            {value && value.total > 1 && (
              <Tooltip title={value.invite_key.join(' ')} interactive>
                <Typography color='primary'>{value.total} ingressos</Typography>
              </Tooltip>
            )}
            {value && value.total <= 1 && (
              <Tooltip title={value.invite_key.join(' ')}>
                <Typography className={classes.typographyString}>{value.invite_key}</Typography>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'string' && (
          <div className='string' style={customStyle}>
            {value && (
              <Tooltip title={value.length > 0 ? value.toString() : ''} placement='top-start'>
                <Typography className={classes.typographyString}>
                  {value.length > max ? value.toString().substring(0, max) + '...' : value.toString()}
                </Typography>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'participant' && (
          <div
            className='string'
            style={{ ...customStyle, ...(this?.props?.onClick && { cursor: 'pointer' }) }}
            {...(this?.props?.onClick && { onClick: this?.props?.onClick })}
          >
            {value && (
              <Tooltip title={value.length > 0 ? value.toString() : ''} placement='top-start'>
                <>
                  <Typography className={classes.typographyString}>
                    {value[0]?.length > max ? value[0].toString().substring(0, max) + '...' : value[0]?.toString()}
                  </Typography>
                  <Typography className={classes.typographyString}>
                    {value[1]?.length > max ? value[1].toString().substring(0, max) + '...' : value[1]?.toString()}
                  </Typography>
                </>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'spacing-100' && (
          <div className='spacing-100' style={{ paddingRight: 100 }}>
            {value && (
              <Tooltip title={value.length > 0 ? value.toString() : ''} placement='top-start'>
                <Typography className={classes.typographyString}>
                  {value.length > max ? value.toString().substring(0, max) + '...' : value.toString()}
                </Typography>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'number-row' && (
          <div className='number-row'>
            <Tooltip title={value.length > 0 ? value.toString() : ''} placement='top-start'>
              <div style={{ justifyContent: 'center' }}>
                <Typography className={classes.typographyString}>{value}</Typography>
              </div>
            </Tooltip>
          </div>
        )}

        {type === 'string-phone' && (
          <div className='string'>
            {value && (
              <Tooltip title={this.phoneMask(value)} placement='top-start'>
                <Typography className={classes.typographyString}>{this.phoneMask(value)}</Typography>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'string-doc' && (
          <div className='string'>
            {value && (
              <Tooltip title={this.docMask(value) ? this.docMask(value) : 'Desconhecido'} placement='top-start'>
                <Typography className={classes.typographyString}>
                  {this.docMask(value) ? this.docMask(value) : 'Desconhecido'}
                </Typography>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'icon' && (
          <div className='string'>
            {!title && <Icon color='primary'>{value}</Icon>}
            {title && (
              <Tooltip title={title} placement='top'>
                <Icon color='primary'>{value}</Icon>
              </Tooltip>
            )}
          </div>
        )}

        {type === 'linkIconButton' && (
          <div className='string' style={customStyle}>
            <a href={value[0]} rel='noopener noreferrer' target='_blank' style={{ textDecoration: 'none' }}>
              <Button
                className={classes.colorButton}
                icon={<Icon style={{ color: 'rgb(234, 2, 105)' }}>{value[1]}</Icon>}
              />
            </a>
          </div>
        )}

        {type === 'copy' && (
          <div className='copy'>
            <Truncate count={max ? max : 40} text={value.toString()} />

            <div className='iconCopy'>
              <Tooltip title={!copied ? 'Copiar Link' : 'Copiado'} placement='top'>
                <CopyToClipboard text={value.toString()} onCopy={() => this.handleClickCopy()}>
                  <Button onClick={() => this.handleClickCopy()} icon={<CopyOutlined />} />
                </CopyToClipboard>
              </Tooltip>
            </div>
          </div>
        )}

        {type === 'image' && <div className='image' style={{ backgroundImage: `url(${value})` }} />}

        {type === 'actions' && (
          <div className='action' style={customStyle}>
            {!!eventId ? (
              <RouteButton id={id || 'menu-actions'} route={`/my-events/${eventId}/basic-data`}>
                Detalhes
              </RouteButton>
            ) : isMobile ? (
              <button style={{ all: 'unset' }} onClick={this.handleClickMenu}>
                ...
              </button>
            ) : (
              <CustomButtonAntd id={id || 'menu-actions'} onClick={this.handleClickMenu}>
                ...
              </CustomButtonAntd>
            )}

            {actions && !eventId && (
              <Menu
                disableAutoFocus
                disableAutoFocusItem
                id='menu-actions'
                anchorEl={anchorEl}
                classes={{ paper: classes.submenu }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                getContentAnchorEl={null}
                elevation={1}
                open={Boolean(anchorEl)}
                onClose={e => this.handleClose(e)}
              >
                {actions.map((item, index) => {
                  if (item.hide) {
                    return null;
                  }

                  return (
                    <MenuItem
                      key={`item-${index}`}
                      disabled={item.disabled}
                      onClick={e => this.handleClose(e, item.callback)}
                      id={item.id}
                      className={item.icon === 'menuitem_whatsapp' ? classes.menuItemWhatsappOption : null}
                    >
                      {typeof item.icon === 'string' ? (
                        <>
                          <Icon>{item.icon !== 'menuitem_whatsapp' && item.icon}</Icon> &nbsp; {item.label}
                        </>
                      ) : (
                        <>
                          {item.icon} &nbsp; {item.label}
                        </>
                      )}
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          </div>
        )}

        {type === 'not-found' && <div className='not-found'>{value}</div>}
        {type === 'any' && <div className='string'>{children}</div>}
        {type === 'regular' && <div>{children}</div>}
        {type === 'regular-center' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{children}</div>
        )}
      </>
    );
  };

  public render(): React.ReactNode {
    const { classes, type, colSpan, sm, xs, iconSVG } = this.props;

    return (
      <>
        <Hidden smDown>
          <td className={`${type === 'image' ? '--image-col' : ''}`} colSpan={colSpan ? colSpan : 1}>
            {!iconSVG && this.typesRender()}

            {iconSVG && (
              <div className='type-with-icon'>
                <div>{this.typesRender()}</div>

                {iconSVG.title && (
                  <Tooltip title={iconSVG.title} placement='top'>
                    <div className='iconSVG'>
                      <img src={iconSVG.icon} alt={iconSVG.title} />
                    </div>
                  </Tooltip>
                )}

                {!iconSVG.title && (
                  <div className='iconSVG'>
                    <img src={iconSVG.icon} alt={iconSVG.title} />
                  </div>
                )}
              </div>
            )}
          </td>
        </Hidden>

        <Hidden mdUp xsDown>
          {sm && !sm.hidden && (
            <Grid item sm={sm.size} className={`${classes.el} ${type === 'status' ? '--status-col' : ''}`}>
              {this.typesRender()}
            </Grid>
          )}
        </Hidden>

        <Hidden smUp>
          {xs && !xs.hidden && (
            <Grid item xs={xs.size} className={`${classes.el} ${type === 'status' ? '--status-col' : ''}`}>
              {this.typesRender()}
            </Grid>
          )}
        </Hidden>
      </>
    );
  }
}
