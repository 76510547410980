export function trim(str: string) {
  return str.replace(/^\s+|\s+$/gm, '');
}

export default function rgbaToHex(rgba: string): string {
  const parts = rgba.substring(rgba.indexOf('(')).split(',');
  const r = parseInt(trim(parts[0].substring(1)), 10);
  const g = parseInt(trim(parts[1]), 10);
  const b = parseInt(trim(parts[2]), 10);
  const a = Number(parseFloat(trim(parts[3].substring(0, parts[3].length - 1))).toFixed(2));

  return '#' + r.toString(16) + g.toString(16) + b.toString(16) + (a * 255).toString(16).substring(0, 2);
}
