import { makeStyles } from '@material-ui/core';
import { fontSizePxToRem, setMargin } from 'helpers/functions';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1280,
    margin: 'auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  headerImage: {
    width: 84,
    height: 84,
    minWidth: 84,
    minHeight: 84,
    borderRadius: 4,
    marginRight: 12,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
      minWidth: 60,
      minHeight: 60
    },
    '& img': {
      width: '100%',
      height: '100%',
      display: 'block'
    }
  },
  headerTitle: {
    textDecoration: 'none',
    fontWeight: 700,
    fontSize: fontSizePxToRem(24),
    lineHeight: 1,
    paddingBottom: 12,
    display: 'block',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizePxToRem(16),
      paddingBottom: 8
    }
  },
  headerDate: {
    fontSize: fontSizePxToRem(16),
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizePxToRem(10)
    }
  },
  headerCalendar: {
    width: 16,
    height: 16,
    marginRight: 12,
    fill: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  lastUpdate: {
    fontSize: fontSizePxToRem(14),
    textAlign: 'right',
    ...setMargin(8, null, 0, null),
    color: theme.palette.primary.contrastText,
    fontWeight: 600
  },
  sectionTitle: {
    fontSize: fontSizePxToRem(18),
    fontWeight: 600,
    fontFamily: "'Roboto', sans-serif",
    color: '#000000',
    lineHeight: '150%'
  },
  content: {
    display: 'grid',
    gridTemplateAreas: `
    'event-statistics     event-statistics'
    'event-statistics     event-statistics'
    'telescope . '
    `,
    gridTemplateColumns: 'auto 230px',
    gridTemplateRows: 'auto',
    gap: '0 12px',
    marginBottom: 12,
    [theme.breakpoints.down('md')]: {
      gridTemplateAreas: `
        'event-statistics   '
        'telescope          '
      `,
      gridTemplateColumns: '1fr'
    }
  },
  eventStatistics: {
    gridArea: 'event-statistics'
  }
}));

export default useStyles;
