import { useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IEventForm } from 'interfaces/event';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import FormContext from '../../Context';
import FormFive from './FormFive';
import FormOne from './FormOne';
import FormThree from './FormThree';
import FormTwo from './FormTwo';
import { ticketDefault, ticketLotDefault } from './mock';
import { schemaFormOne, schemaFormThree, schemaFormTwo } from './scheme';
import { FormProps } from './types';

const FORM_STEPS = {
  '1': <FormOne />,
  '2': <FormTwo />,
  '3': <FormThree />,
  '4': <FormFive />
};

const FORM_SCHEMES = {
  '1': schemaFormOne,
  '2': schemaFormTwo,
  '3': schemaFormThree
};

const Form = ({ isCreate, defaultValue }: FormProps) => {
  const { currentStep, setCurrentStep } = useContext(FormContext);
  const { type } = useParams<{ type: string }>();

  const { setValue, ...methods } = useForm<IEventForm & { isCreate: boolean }>({
    resolver: yupResolver(FORM_SCHEMES[currentStep]),
    mode: 'onChange',
    defaultValues: {
      type,
      isCreate,
      active_precheckout: 0,
      active_postcheckout: 0,
      sale_url: '',
      ticket: [
        { ...ticketDefault, identificator: v4(), lot: [{ ...ticketLotDefault, identificator: v4(), name: 'Lote 1' }] }
      ],
      hasReserveTime: true,
      reserve_time: 10,
      ...defaultValue
    }
  });

  window['formErrors'] = methods?.formState?.errors;

  useEffect(() => {
    if (defaultValue) {
      setValue('ticket', defaultValue.ticket);
    }

    const goToStep = sessionStorage.step;
    sessionStorage.removeItem('step');
    if (goToStep) {
      setCurrentStep(goToStep);
    }
  }, [defaultValue, setValue, setCurrentStep]);

  return <FormProvider {...{ ...methods, setValue }}>{FORM_STEPS[currentStep]}</FormProvider>;
};

export default Form;
