import { Dialog } from '@mui/material';
import styled from 'styled-components';

const CONTRAST_TEXT = '#000000';
const SEMIBOLD = 600;
const REGULAR = 400;

export const Modal = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    background: #ffffff;

    h2 {
      display: flex;
      align-items: center;
      margin: 0 11px 0 0;
      font-weight: ${SEMIBOLD};
      color: ${CONTRAST_TEXT};
      font-size: 16px;

      span {
        color: ${CONTRAST_TEXT};
        margin: 0 10px 0 0;
      }
    }

    .MuiDialogContent-root {
      p {
        font-size: 14px;
        font-weight: ${REGULAR};
        color: ${CONTRAST_TEXT};
      }
    }
  }
`;
