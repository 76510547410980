import React, { PureComponent } from 'react';

import Script from 'react-load-script';
import { GOOGLE_MAPS_PLACE_APIKEY } from 'settings';

class GoogleMapsLib extends PureComponent {
  handleScriptCreate = () => {
    this.setState({ scriptLoaded: false });
  };

  handleScriptError = () => {
    this.setState({ scriptError: true });
  };

  handleScriptLoad = () => {
    this.setState({ scriptLoaded: true });
  };

  render(): React.ReactNode {
    return (
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_PLACE_APIKEY}&libraries=places&region=BR`}
        onCreate={this.handleScriptCreate}
        onError={this.handleScriptError}
        onLoad={this.handleScriptLoad}
      />
    );
  }
}

export default GoogleMapsLib;
