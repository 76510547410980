import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles: AppStyle = theme => ({
  headString: {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: fontSizePxToRem(12),
    lineHeight: '150%',
    color: '#000000'
  }
});

export default styles;
