import React from 'react';

import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Loading from 'components/Shared/Loading';
import { TableCol } from 'components/Shared/Table';

import TableRow from '../TableRow';
import styles from './styles';
import { IProps } from './types';

function TableBody({ children, loading, classes, rows, emptyMessage }: IProps) {
  if (loading) {
    return (
      <>
        <Hidden smDown>
          <tbody>
            <Fade in={true} timeout={500}>
              <tr style={{ background: 'none' }}>
                <td colSpan={1000}>
                  <Loading showLoading={loading} content={[{ type: 'list', sizes: { xs: 12 }, height: 380 }]} />
                </td>
              </tr>
            </Fade>
          </tbody>
        </Hidden>

        <Hidden mdUp>
          <Fade in={true} timeout={500}>
            <Loading showLoading={loading} content={[{ type: 'list', sizes: { xs: 12 }, height: 380 }]} />
          </Fade>
        </Hidden>
      </>
    );
  }

  if (!children || children === '' || rows === 0) {
    return (
      <>
        <Hidden smDown>
          <tbody>
            <TableRow>
              <TableCol
                xs={{ size: 12 }}
                sm={{ size: 12 }}
                colSpan={1000}
                type='not-found'
                value={emptyMessage || 'Nenhum registro encontrado...'}
              />
            </TableRow>
          </tbody>
        </Hidden>

        <Hidden mdUp>
          <div className={classes.notfound}>
            <p>{emptyMessage || 'Nenhum registro encontrado...'}</p>
          </div>
        </Hidden>
      </>
    );
  }

  return (
    <>
      <Hidden smDown>
        <tbody>{children}</tbody>
      </Hidden>

      <Hidden mdUp>{children}</Hidden>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(TableBody);
