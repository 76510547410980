import React from 'react';

import { Steps } from 'antd';
import { Modal, Typography, Button, Col, Row, Divider } from 'components/Shared/Antd';
import { FormProvider, useForm } from 'react-hook-form';

import { EventDetailContext } from '../../../HeaderList';
import FinishedStep from './FinishedStep';
import TemplateStep from './TemplateStep';
import TicketStep from './TicketStep';
import UploadStep from './UploadStep';

type MultipleDialogProps = {
  open: boolean;
  onClose: () => void;
};

const STEPS = {
  TICKET: 1,
  TEMPLATE: 2,
  UPLOAD: 3,
  FINISHED: 4
} as const;

const { TICKET, TEMPLATE, UPLOAD, FINISHED } = STEPS;

const MultipleDialog = ({ open, onClose }: MultipleDialogProps) => {
  const [activeStep, setActiveStep] = React.useState(1);
  const [enabledForward, setEnabledForward] = React.useState(false);
  const [uploadCompleted, setUploadCompleted] = React.useState(false);
  const { openAddParticipantDialogTest, eventDetail } = React.useContext(EventDetailContext);

  const methods = useForm({
    defaultValues: {
      useStock: true,
      ticket_id: eventDetail?.tickets[0]?.id,
      file_name: ''
    }
  });

  const enableForward = () => {
    setEnabledForward(true);
  };

  const hasUploadCompleted = () => {
    setUploadCompleted(true);
  };

  const prevStep = () => {
    if (activeStep === TICKET) {
      onClose();
      openAddParticipantDialogTest();
      return;
    }
    setActiveStep(step => step - 1);
  };

  const nextStep = () => {
    if (activeStep === FINISHED) return;
    setActiveStep(step => step + 1);
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        width={800}
        zIndex={3000}
        destroyOnClose
        title={
          <>
            <Typography.Title level={3}>Adicionar Participantes</Typography.Title>
            <Divider />
          </>
        }
        footer={
          activeStep === FINISHED
            ? [
                <Button type='primary' size='large' onClick={onClose} key='close-add-participant-modal'>
                  Fechar
                </Button>
              ]
            : [
                <React.Fragment key='text'>
                  <Divider />
                  <Button
                    disabled={activeStep === UPLOAD && uploadCompleted}
                    type='text'
                    size='large'
                    onClick={prevStep}
                    key='add-participant-step-back'
                  >
                    Voltar
                  </Button>
                </React.Fragment>,
                <Button
                  disabled={activeStep === UPLOAD && !enabledForward}
                  type='primary'
                  size='large'
                  onClick={nextStep}
                  key='add-participant-step-forward'
                >
                  Próximo
                </Button>
              ]
        }
      >
        <Row>
          <Col md={8} xs={24}>
            <Steps
              direction='vertical'
              size='small'
              current={activeStep}
              items={[
                { title: 'Tipo de Inclusão' },
                {
                  title: 'Ingresso'
                },
                {
                  title: 'Template'
                },
                {
                  title: 'Upload Planilha'
                },
                {
                  title: 'Concluído'
                }
              ]}
            />
          </Col>
          <Col md={16} xs={24}>
            <FormProvider {...methods}>
              {activeStep === TICKET && <TicketStep />}
              {activeStep === TEMPLATE && <TemplateStep />}
              {activeStep === UPLOAD && (
                <UploadStep enableForward={enableForward} hasUploadCompleted={hasUploadCompleted} />
              )}
              {activeStep === FINISHED && <FinishedStep />}
            </FormProvider>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MultipleDialog;
