import { Tour as AntdTour } from 'antd';

import { TourProps } from './types';

const Tour = ({ steps, open, onClose }: TourProps) => {
  const defaultSteps = steps.map(step => ({
    ...step,
    prevButtonProps: {
      children: 'Voltar'
    },
    nextButtonProps: {
      children: step.isLastStep ? 'Entendi' : 'Próximo'
    }
  }));

  return (
    <AntdTour
      steps={defaultSteps}
      open={open}
      onClose={onClose}
      mask={true}
      indicatorsRender={(current, total) => (
        <span>
          {current + 1} de {total}
        </span>
      )}
    />
  );
};

export default Tour;
