import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, lineHeight } }) => css`
    .event-local-title {
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('semibold')};
      line-height: ${lineHeight('compact')};
    }
    .event-local-content {
      .event-local-adress,
      .event-local-location,
      .event-local-online,
      .event-online-no-link {
        display: flex;
        margin: 12px 0;
        max-width: 370px;

        p {
          color: ${colors.primary.contrastText};
          margin: 0 5px 0 5px;
        }

        svg {
          fill: ${colors.primary.contrastText};
        }
      }

      .event-local-online {
        text-decoration: unset;
        display: flex;
        align-items: center;
        cursor: pointer;

        .event-local-online-icon {
          display: flex;
          align-items: center;
        }
        svg {
          width: 16px;
        }
      }

      .event-online-no-link {
        text-decoration: unset;
        display: flex;
        align-items: center;

        .event-online-no-link-icon {
          display: flex;
          align-items: center;
        }
        svg {
          width: 16px;
        }
      }
    }
  `}
`;
