import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem, setMargin } from 'helpers/functions';

const styles: AppStyle = theme => ({
  root: {
    textAlign: 'center',
    padding: 20,
    width: 350,
    maxWidth: '100%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: 9
    }
  },
  icon: {
    opacity: 0.7
  },
  button: {
    ...setMargin(20, null, null, null)
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizePxToRem(12)
    }
  }
});

export default styles;
