import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    .event-widget-text {
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('semibold')};
      font-size: 14px;

      &.desktop {
        display: none;
      }
    }

    .event-widget-radio-container {
      margin: 5px 10px;
    }

    .event-widget-button-container {
      display: flex;

      button {
        color: #000000;
        margin: 0 0 11px auto;
        width: 144px;
        border-color: #d9d9d9;

        &:hover {
          border-color: #000000;
          background-color: #fff;
        }
      }
    }

    .event-widget-text-field {
      margin: 0 0 10px 0;

      textarea {
        max-height: 190px;
        overflow: auto;
      }
    }

    @media (${breakpoints.md}) {
      .event-widget-text {
        &.desktop {
          display: block;
        }

        &.mobile {
          display: none;
        }
      }
    }
  `}
`;
