export const PinIcon = ({ ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none' {...rest}>
    <path
      fillRule='evenodd'
      d='M7.75 2.5a.75.75 0 1 0 0 1.5h.747v8.882l-2.281 2.336c-.467.478-.128 1.282.541 1.282h4.493v5.25a.75.75 0 1 0 1.5 0V16.5h4.493c.669 0 1.008-.804.541-1.282l-2.281-2.336V4h.747a.75.75 0 1 0 0-1.5h-8.5zM14.002 4H9.998v9.493L8.526 15h6.947l-1.472-1.507V4z'
      fill='currentColor'
    />
  </svg>
);

export const FilledPinIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
    <path
      d='M7.75 2a.75.75 0 1 0 0 1.5h.747v8.882l-2.281 2.336c-.467.478-.128 1.282.541 1.282h4.493v5.25a.75.75 0 1 0 1.5 0V16h4.493c.669 0 1.008-.804.541-1.282l-2.281-2.336V3.5h.747a.75.75 0 1 0 0-1.5h-8.5z'
      fill='currentColor'
    />
  </svg>
);

export const AddIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='#00000'>
    <path fillRule='evenodd' d='M12 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0zm-1.5 0a2 2 0 1 1-4 0 2 2 0 1 1 4 0z' />
    <path d='M3.5 15.5a2 2 0 0 1 2-2h6a2 2 0 0 1 1.842 1.218 6.03 6.03 0 0 1 1.129-1.071A3.5 3.5 0 0 0 11.5 12h-6A3.5 3.5 0 0 0 2 15.5v4.25a.75.75 0 0 0 .75.75h9.591c-.168-.475-.278-.978-.321-1.5H3.5v-3.5zm14.5-1a.75.75 0 0 0-.75.75v2.5h-2.5a.75.75 0 1 0 0 1.5h2.5v2.5a.75.75 0 1 0 1.5 0v-2.5h2.5a.75.75 0 1 0 0-1.5h-2.5v-2.5a.75.75 0 0 0-.75-.75z' />
  </svg>
);

export const FilterIcon = ({ fill = '#000000', ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' {...rest}>
    <path
      d='M16.395 1.811H1.605c-.492 0-.8.536-.552.964l4.673 7.943v4.834a.64.64 0 0 0 .639.643h5.271a.64.64 0 0 0 .639-.643v-4.834l4.675-7.943c.245-.428-.062-.964-.554-.964zm-5.559 12.937H7.164v-3.134h3.674v3.134h-.002zm.193-4.753l-.191.333H7.162l-.191-.333-3.984-6.738h12.025l-3.984 6.738z'
      fill={fill}
    />
  </svg>
);

export const ArrowIcon = ({ fill = '#000000', ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' {...rest}>
    <path
      d='M12.469 6.271h-1.266c-.072 0-.141.031-.189.086l-3.42 3.942V1.501c0-.069-.056-.125-.125-.125h-.937c-.069 0-.125.056-.125.125v8.798l-3.42-3.942c-.047-.055-.116-.086-.189-.086H1.531c-.106 0-.164.127-.094.206l5.186 5.977a.5.5 0 0 0 .377.172.5.5 0 0 0 .377-.172l5.184-5.977c.07-.081.012-.206-.094-.206z'
      fill={fill}
    />
  </svg>
);

export const BrazilIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
    <path
      d='M2 5.25A1.25 1.25 0 0 1 3.25 4h17.5A1.25 1.25 0 0 1 22 5.25v13.5A1.25 1.25 0 0 1 20.75 20H3.25A1.25 1.25 0 0 1 2 18.75V5.25z'
      fill='#009c3b'
    />
    <path d='M12 5.5L20.5 12L12 18.5L3.5 12L12 5.5Z' fill='#ffdf00' />
    <circle cx='12' cy='12' r='3.625' fill='#002776' />
    <path
      d='M15.444 13.134A3.62 3.62 0 0 0 15.625 12c0-.162-.011-.321-.031-.477A14.94 14.94 0 0 0 9 10h-.024c-.295.445-.494.959-.569 1.513L9 11.5a13.44 13.44 0 0 1 6.444 1.634z'
      fill='#fff'
    />
  </svg>
);
