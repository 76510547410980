import { EventAdminRoutes } from './types';

const eventAdminRoutes: EventAdminRoutes[] = [
  {
    path: '/event',
    props: 'updateUser',
    import: 'Admin/Event'
  },
  {
    path: '/event-duplicate-live/:id/',
    import: 'Admin/Event'
  }
];
//alterar pasta pra Event(no singular)
export default eventAdminRoutes;
