import React from 'react';

import { Checkbox, LabelDisplayedRowsArgs, TablePagination } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Table, { TableBody, TableHead } from 'components/Shared/Table';
import { IAttendaceList } from 'interfaces/attendanceList';

import ListItem from './ListItem';
import { IProps } from './types';

const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => `${from}-${to} de ${count}`;
export default function PresenceList({
  items,
  loading,
  pagination,
  loadData,
  handleOpenModalResendTicket,
  handleOpenModalLink,
  handleOpenModalEdit,
  handlePaginationChange,
  handleOpenModalLabelManager,
  handleOpenAttendanceDetail,
  handleCheckinAttendances,
  selectedParticipants,
  handleOpenModalSendEmailToParticipants,
  handleUpdateParentState,
  isEventLiveOnline,
  attendanceItems,
  allParticipantsOfPageAreSelected,
  allPagesParticipantsAreSelected
}: IProps) {
  const attendanceListIds = [];

  const tableHead = [
    { label: 'PARTICIPANTE' },
    { label: 'Nº INGRESSO' },
    { label: 'STATUS' },
    { label: 'TIPO DE INGRESSO' },
    { label: 'COMPRADOR' },
    { label: 'CHECK-IN', textAlign: 'center' },
    { label: 'AÇÕES', textAlign: 'center' }
  ];

  const updateSelectedParticipants = (participant: IAttendaceList, isSelected: boolean) => {
    if (isSelected) {
      if (!selectedParticipants.includes(participant)) {
        selectedParticipants.push(participant);
      }
    } else {
      selectedParticipants.splice(selectedParticipants.indexOf(participant), 1);
    }
    handleUpdateParentState({ selectedParticipants: [...selectedParticipants] });
  };

  const handleSelectAll = (isSelected: boolean) => {
    const updatedSelectedParticipants = isSelected
      ? [
          ...selectedParticipants,
          ...attendanceItems.filter(
            participant => selectedParticipants.findIndex(item => item.id === participant.id) === -1
          )
        ]
      : selectedParticipants.filter(
          participant => attendanceItems.findIndex(item => item.id === participant.id) === -1
        );

    handleUpdateParentState({ selectedParticipants: updatedSelectedParticipants });
  };

  const tablePaginationProps = {
    labelRowsPerPage: 'items',
    component: 'div',
    labelDisplayedRows: labelDisplayedRows,
    count: pagination.totalRows,
    rowsPerPage: pagination.size,
    backIconButtonProps: { disabled: loading || pagination.page === 1 },
    nextIconButtonProps: { disabled: loading || pagination.page === pagination.totalPages },
    page: pagination.page - 1,
    onChangePage: (e, page: number) => loadData(page + 1),
    onChangeRowsPerPage: () => ({}),
    rowsPerPageOptions: []
  };

  return (
    <>
      <Hidden mdUp>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Checkbox
              checked={allParticipantsOfPageAreSelected || allPagesParticipantsAreSelected}
              onChange={e => handleSelectAll(e.target.checked)}
              style={{ padding: '0 0 8px 24px' }}
              disabled={allPagesParticipantsAreSelected}
              color='primary'
              size='small'
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle2'>Nome</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle2'>Status</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='subtitle2'>Ações</Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Table>
        <TableHead
          head={tableHead}
          onSelectAll={handleSelectAll}
          allParticipantsAreSelected={allParticipantsOfPageAreSelected}
          disableSelectAll={allPagesParticipantsAreSelected}
        />
        <TableBody loading={loading}>
          {items.length > 0 &&
            items.map(row => {
              attendanceListIds.push(row.id);

              row.attendance_list_ids = attendanceListIds;

              return (
                <ListItem
                  key={row.id}
                  item={row}
                  handleOpenModalResendTicket={handleOpenModalResendTicket}
                  handleOpenModalLink={handleOpenModalLink}
                  handleOpenModalEdit={handleOpenModalEdit}
                  handleOpenModalLabelManager={handleOpenModalLabelManager}
                  handleOpenAttendanceDetail={handleOpenAttendanceDetail}
                  handleCheckinAttendances={handleCheckinAttendances}
                  reloadData={loadData}
                  updateSelectedParticipants={updateSelectedParticipants}
                  selectedParticipants={selectedParticipants}
                  handleOpenModalSendEmailToParticipants={handleOpenModalSendEmailToParticipants}
                  isEventLiveOnline={isEventLiveOnline}
                  disableCheckboxes={allPagesParticipantsAreSelected}
                />
              );
            })}
        </TableBody>
      </Table>

      <Grid container style={{ marginTop: 12, marginBottom: 32 }}>
        <Grid
          item
          sm={4}
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ marginRight: '0.5em' }}>Itens por página:&nbsp;</span>
          {!loading ? (
            <TextField
              select
              name='pageSize'
              value={pagination.size}
              onChange={(e: any) => handlePaginationChange(e.target.value)}
              id='presencelist-pagesize-input'
            >
              <MenuItem value={25} id='presencelist-pagesize-input-25'>
                25
              </MenuItem>
              <MenuItem value={50} id='presencelist-pagesize-input-50'>
                50
              </MenuItem>
              <MenuItem value={100} id='presencelist-pagesize-input-100'>
                100
              </MenuItem>
            </TextField>
          ) : (
            '...'
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          <TablePagination {...tablePaginationProps} />
        </Grid>
      </Grid>
    </>
  );
}
