import { grey, red } from '@material-ui/core/colors';
import { Overrides } from '@material-ui/core/styles/overrides';
import { fontSizePxToRem, setMargin, setPadding } from 'helpers/functions';

const overrides: Overrides = {
  MuiTypography: {
    root: { fontFamily: 'Roboto !important' },
    colorSecondary: { color: '#000000' },
    colorTextSecondary: { color: '#546E7A' }
  },

  // cards
  MuiCardContent: {
    root: {
      padding: 24,
      '@media (max-width:780px)': {
        padding: 2
      },
      backgroundColor: '#f3f3f3'
    }
  },

  // pagination
  MuiTablePagination: {
    root: {
      border: 0
    },
    input: {
      marginTop: '0 !important',
      margin: '0 16px'
    },
    toolbar: {
      justifyContent: 'center'
    },
    spacer: {
      display: 'none'
    },
    selectRoot: {
      ...setMargin(null, 0, null, 0)
    },
    selectIcon: {
      top: 1,
      right: 2
    },
    select: {
      ...setPadding(null, 8, null, null),
      borderRadius: 4,
      minWidth: 36
    },
    actions: {
      ...setMargin(null, null, null, 8)
    }
  },

  // form
  MuiFormControl: {
    root: {
      marginTop: '0 !important',
      marginBottom: '0 !important'
    }
  },

  // input
  MuiInputBase: {
    root: {
      border: '1px solid #E0E2F8',
      borderRadius: 4,
      transition: 'border-color, box-shadow, 0.3s',
      background: '#FFFFFF',

      '&$focused': {
        borderColor: '#b7bcf7'
      }
    }
  },

  MuiOutlinedInput: {
    root: {
      border: 'none'
    },
    input: {
      padding: '11px 14px',
      color: '#546E7A',
      '&::placeholder': {
        opacity: 2,
        color: '#546E7A'
      }
    }
  },

  MuiInputAdornment: {
    root: {
      color: '#ccc',
      marginLeft: '16px !important',
      marginRight: 12
    }
  },

  MuiIconButton: {
    root: {
      padding: 5
    }
  },

  MuiInput: {
    root: {
      '&$error': {
        borderColor: red[800]
      }
    },

    input: {
      padding: '10px 12px',
      fontSize: fontSizePxToRem(14),
      color: 'black',
      transition: 'border-color, box-shadow, 0.3s',
      opacity: 1,

      '&::placeholder': {
        opacity: 1,
        color: '#888'
      }
    },
    underline: {
      '&:before, &:after': {
        display: 'none'
      }
    },

    inputMultiline: {
      ...setMargin(4, null, null, null)
    }
  },

  // label
  MuiFormLabel: {
    root: {
      fontSize: fontSizePxToRem(14),
      color: '#000000'
    }
  },

  MuiInputLabel: {
    shrink: {
      transform: 'none'
    },
    formControl: {
      transform: 'none'
    },
    outlined: {
      transform: 'translate(14px, 14px) scale(1)',
      opacity: 2,
      color: '#546E7A'
    }
  },

  // select
  MuiSelect: {
    select: {
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:focus': {
        borderRadius: 4
      }
    },

    icon: {
      top: 'calc(50% - 12px)',
      right: 4
    },

    selectMenu: {
      whiteSpace: 'normal'
    }
  },

  MuiExpansionPanelDetails: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16
    }
  },

  MuiSwitch: {
    switchBase: {
      color: '#f4f3f3',
      checked: {
        ['& + $bar' as any]: {
          backgroundColor: '#93E35D'
        }
      }
    },
    thumb: {
      background: '#98A5BC'
    },
    colorSecondary: {
      color: '#ccc'
    }
  },

  MuiFormControlLabel: {
    root: {
      ...setMargin(null, 10, null, 0),
      '& > span': {
        padding: '3px 3px 3px 0',
        '& p': {
          marginLeft: 0,
          fontSize: fontSizePxToRem(14)
        }
      }
    }
  },

  MuiCheckbox: {
    root: {
      color: 'unset',
      '&$checked': {
        color: '#FFBC00'
      }
    }
  },

  // snackbar
  MuiSnackbarContent: {
    root: {
      backgroundColor: '#27ae60',
      zIndex: 9999999
    },
    message: {
      fontWeight: 500
    }
  },

  MuiSnackbar: {
    root: {
      zIndex: 9999999
    }
  },

  // chips
  MuiChip: {
    root: {
      height: 18,
      ...setMargin(null, 4, 2, null),
      fontSize: fontSizePxToRem(14),
      background: grey[200]
    }
  },

  // Dialog
  MuiDialog: {
    paperScrollPaper: {
      maxHeight: 'calc(100% - 30px)'
    },
    paperWidthFalse: {
      '@media (max-width:959px)': {
        width: '100vw',
        maxWidth: 'calc(100vw - 32px)',
        margin: 'auto 16px'
      }
    }
  },

  MuiDialogTitle: {
    root: {
      padding: 16
    }
  },

  MuiDialogContent: {
    root: {
      padding: '0 16px'
    }
  },

  MuiDialogActions: {
    root: {
      '@media (max-width:959px)': {
        padding: 16
      }
    }
  },

  MuiTooltip: {
    tooltipPlacementTop: {
      fontSize: fontSizePxToRem(14)
    }
  },

  // FabButton
  MuiFab: {
    primary: {
      color: '#000000'
    }
  },

  // Button
  MuiButton: {
    root: {
      textTransform: 'unset'
    },
    containedPrimary: {
      color: '#000000'
    }
  },

  //Accordion
  MuiAccordion: {
    root: {
      marginTop: 12,
      boxShadow: 'none',
      borderRadius: 8,
      '&::before': {
        display: 'none'
      }
    }
  },

  MuiAccordionSummary: {
    root: {
      minHeight: 'unset !important',
      flexDirection: 'row-reverse',
      paddingLeft: 0
    },
    expandIcon: {
      transform: 'rotate(-90deg)',
      color: '#FFBC00',
      '& svg': {
        width: 22,
        height: 22
      },
      '&$expanded': {
        transform: 'rotate(0)'
      }
    },
    content: {
      margin: '10px 0 10px 10px !important',
      '& > p': {
        fontWeight: 600,
        fontSize: fontSizePxToRem(14),
        lineHeight: '150%',
        color: '#FFBC00',
        textTransform: 'uppercase'
      }
    }
  }
};

export default overrides;
