import { AppStyle } from 'decorators/withStyles';

const styles: AppStyle = theme => ({
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      top: '24px',
      left: 'auto',
      right: '24px'
    }
  },
  contentError: {
    background: theme.palette.error.main
  },
  close: {
    width: 8 * 4,
    height: 8 * 4
  }
});

export default styles;
