import React, { useState } from 'react';

import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { withStyles } from '@material-ui/core/styles';
import { Button, Col, Form, Input, Row, Spin, Tooltip } from 'antd';
import ColorInput from 'components/Shared/ColorInput';
import Toast from 'components/Shared/Toast';
import errorHandler, { errorMessage } from 'errors/errorHandler';
import { ITags } from 'interfaces/tags';
import attendanceListService from 'services/attendanceList';

import ArchiveMarker from './ArchiveMarker';
import styles from './styles';

interface IProps {
  marker: ITags;
  onCompleteEdit: (tag: Partial<ITags>) => void;
  onComplete: (tag: Partial<ITags>) => void;

  onMarkerRemoved: (tag: Partial<ITags>) => void;
  classes?: any;
}

const MarkerEditDialogItem: React.FC<IProps> = ({ classes, marker, onComplete, onCompleteEdit, onMarkerRemoved }) => {
  const [model, setModel] = useState<ITags>(marker);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showModalArchiveMarker, setShowModalArchiveMarker] = useState(false);
  const [showItem, setShowItem] = useState(true);

  const editMarker = (isValid: boolean) => {
    if (!isValid) {
      Toast.error('Verifique os campos obrigatórios');
      return;
    }

    setSubmitLoading(true);

    if (model.isNew) {
      const modelToPost = {
        color: model.color,
        title: model.title
      };

      attendanceListService.addTag({ tags: [modelToPost] }).subscribe(
        res => {
          const newModel = {
            color: model.color,
            title: model.title,
            id_temp: model.id,
            id: res.data[0].id
          };
          Toast.show('Marcador salvo');
          setModel(newModel);
          setSubmitLoading(false);
          onComplete(newModel);
        },
        ({ data: err }) => {
          setSubmitLoading(false);
          errorHandler(err, errorMessage.generic('ao editar marcador'));
        }
      );
    } else {
      attendanceListService.editTag(model).subscribe(
        () => {
          Toast.show('Marcador salvo');
          onCompleteEdit(model);
          setSubmitLoading(false);
        },
        ({ data: err }) => {
          setSubmitLoading(false);
          errorHandler(err, errorMessage.generic('ao editar marcador'));
        }
      );
    }
  };

  const handleChange = (value: any, name: string) => {
    setModel({
      ...model,
      [name]: value
    });
  };

  const removeMarkerOnComplete = (tag: ITags) => {
    setShowModalArchiveMarker(false);
    setShowItem(false);
    onMarkerRemoved(tag);
  };

  return (
    <Form onFinish={editMarker} layout='vertical'>
      {showItem && (
        <Row gutter={[8, 8]} align={'bottom'} className={classes.markerItem}>
          <Col span={12}>
            <Form.Item
              label='Título'
              required
              rules={[
                { required: true, message: 'O campo nome é obrigatório' },
                { whitespace: true },
                { max: 225, message: 'O nome não pode conter mais que 225 caracteres' }
              ]}
            >
              <Input
                size='large'
                placeholder='Título do marcador'
                onChange={e => handleChange(e.target.value, 'title')}
                value={model.title || ''}
                id='markerdialog-markertitle-input'
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label='Cor' required>
              <ColorInput
                value={model.color || ''}
                handleChangeColor={handleChange}
                id='markerdialog-markercolor-input'
              />
            </Form.Item>
          </Col>

          {!submitLoading && (
            <Col span={2}>
              <Form.Item>
                <Tooltip title='Remover Marcador' zIndex={99999} color='#d9d9d9'>
                  <Button
                    size='large'
                    className={classes.labelButton}
                    onClick={() => {
                      if (!model.isNew) {
                        return setShowModalArchiveMarker(true);
                      }
                      removeMarkerOnComplete(model);
                    }}
                    icon={<DeleteOutlined />}
                    id='markerdialog-deletemarker-button'
                  />
                </Tooltip>
              </Form.Item>
            </Col>
          )}
          {!submitLoading && (
            <Col span={2}>
              <Form.Item>
                <Tooltip title='Salvar Alterações' zIndex={99999} color='#d9d9d9'>
                  <Button
                    size='large'
                    htmlType='submit'
                    className={classes.labelButton}
                    icon={<CheckCircleOutlined />}
                    id='markerdialog-submitmarker-button'
                  />
                </Tooltip>
              </Form.Item>
            </Col>
          )}
          {submitLoading && (
            <Col span={4}>
              <Spin />
            </Col>
          )}
        </Row>
      )}

      {showModalArchiveMarker && (
        <ArchiveMarker
          markerId={model.id}
          markerTitle={model.title}
          onComplete={() => removeMarkerOnComplete(model)}
          handleClose={() => setShowModalArchiveMarker(false)}
          open={showModalArchiveMarker}
        />
      )}
    </Form>
  );
};

export default withStyles(styles)(MarkerEditDialogItem);
