import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { WithStyles } from 'decorators/withStyles';

import styles from './styles';
import { IProps } from './types';

@WithStyles(styles)
export default class TableRow extends PureComponent<IProps> {
  public render(): React.ReactNode {
    const { classes, children, onDoubleClick, onClick, id, parentComponent } = this.props;

    return (
      <>
        <Hidden smDown>
          {children && (
            <tr
              className={onDoubleClick ? '--dbclick' : onClick && classes.tableSmall}
              onDoubleClick={onDoubleClick ? () => onDoubleClick() : null}
              onClick={
                onClick
                  ? e => {
                      e.preventDefault();
                      onClick();
                    }
                  : null
              }
              id={id}
            >
              {children}
            </tr>
          )}
        </Hidden>

        <Hidden mdUp>
          {children && (
            <div
              className={onDoubleClick || onClick ? classes.tableSmall : classes.row}
              onClick={onClick ? () => onClick() : null}
              id={id}
            >
              <Grid
                container
                spacing={2}
                alignItems='center'
                style={parentComponent === 'presence' ? { justifyContent: 'space-between', padding: '0px 10px' } : null}
              >
                {children}
              </Grid>
            </div>
          )}
        </Hidden>
      </>
    );
  }
}
