import { AppStyle } from 'decorators/withStyles';
import { setMargin } from 'helpers/functions';

const styles: AppStyle = theme => ({
  labelIconButton: {
    ...setMargin(20, null, null, null),
    color: theme.palette.primary.contrastText
  },
  markerItem: {
    ...setMargin(8, null, 8, null)
  }
});

export default styles;
