import React from 'react';

import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from 'antd';

import styles from './styles';
import { IProps } from './types';

function IconMessage({ classes }: IProps) {
  return (
    <div className={classes.root}>
      <Hidden smDown>
        <DownloadOutlined style={{ fontSize: 30 }} />
      </Hidden>
      <Typography className={classes.text}>Arraste e solte a imagem aqui</Typography>
    </div>
  );
}
export default withStyles(styles)(IconMessage);
