import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    .event-content-title {
      font-weight: ${fontWeight('bold')};
      color: ${colors.primary.contrastText};
      font-size: 14px;
      margin: 24px 0 8px;
    }

    .event-content-text-field {
      background-color: white;

      input {
        pointer-events: unset;
        cursor: unset;
      }

      label {
        font-size: 14px;
        font-weight: ${fontWeight('semibold')};
        color: ${colors.primary.contrastText};
      }

      .action-image-adornment {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .event-container-default-image {
      display: none;
    }

    @media (${breakpoints.md}) {
      .event-content-title {
        margin: 24px 0 24px;
      }
      .event-container-image {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin: 0 0 18px 0;

        .event-container-default-image {
          height: 68px;
          width: 79px;
          border: solid 1px #b0bec5;
          margin: 0 16px 0 0;
          display: flex;
          border-radius: 4px;

          .event-content-image {
            width: 100%;
          }
        }

        .event-content-text-field {
          margin: 0;
          width: 100%;
        }
      }
    }
  `}
`;

export const RichTextOutlined = styled.div`
  ${({ theme: { lineHeight, fontWeight, colors, customBreakpoints: breakpoints } }) => css`
    position: relative;
    margin: 0 0 10px 0;

    label {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      position: absolute;
      z-index: 1;
      transform: translate(16px, -6px) scale(0.7) !important;
      transform-origin: top left;
      pointer-events: none;
      top: 1px;
      left: 4px;
      display: block;

      &.focus {
        color: ${colors.primary.main};
      }
    }

    fieldset {
      width: 100%;
      border-color: rgba(176, 190, 197, 0.5);
      height: 146px;
      padding: 0 5px 0 10px;
      border-radius: 5px;
      background-color: white;
      max-width: 100%;

      .rich-text-oulined-input {
        width: 100%;
        height: 90%;
        .ql-toolbar {
          &.ql-snow {
            display: none;
          }
        }

        .ql-container {
          &.ql-snow {
            border: none;
            margin: 0 0 0 -8px;
          }
        }
      }

      legend {
        font-size: 14px;
        line-height: ${lineHeight('normal')};
        font-weight: 500;
        color: ${colors.primary.contrastText};
        font-family: 'Roboto', sans-serif;
        padding: 0 8px;
      }
    }

    textarea {
      width: 100%;
      border: none;
      outline: none;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: ${fontWeight('regular')};
      line-height: ${lineHeight('normal')};
      color: ${colors.grey[300]};
    }

    &.focus {
      fieldset {
        border: solid 2px ${colors.primary.main};
      }
    }

    &.teste {
      border: 2px transparent solid;
    }

    @media (${breakpoints.md}) {
      fieldset {
        height: 191px;
        .rich-text-oulined-input {
          width: 100%;
          height: 90%;
          .ql-toolbar {
            &.ql-snow {
              display: block;
              border-left: 0;
              border-right: 0;
              margin: 12px 22px 10px 14px;
            }
          }

          .ql-container {
            height: 65%;
            &.ql-snow {
              border: none;
              margin: 0 0 0 -8px;
            }
          }
        }
      }
    }
  `}
`;
