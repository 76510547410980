import React, { Component } from 'react';

import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';

interface IProps {}
interface IState {
  component: any;
}

export default function AsyncComponent(importComponent: any, functions?: any) {
  class AsyncFunc extends Component<IProps, IState> {
    mounted: boolean;
    constructor(props: IProps) {
      super(props);
      this.mounted = true;
      this.state = {
        component: null
      };
    }

    UNSAFE_componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component customFunctions={functions} {...this.props} />
        });
      }
    }

    render() {
      const Component = this.state.component || <div />;
      return (
        <ReactPlaceholder type='media' rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
