import React, { PureComponent } from 'react';

import Hidden from '@material-ui/core/Hidden';
import { IStyledProps, WithStyles } from 'decorators/withStyles';

import styles from './styles';

@WithStyles(styles)
export default class Table extends PureComponent<IStyledProps, {}> {
  public render(): React.ReactNode {
    const { classes, children } = this.props;

    return (
      <>
        <Hidden smDown>
          <table className={classes.table} cellSpacing={0} cellPadding={0}>
            {children}
          </table>
        </Hidden>

        <Hidden mdUp>
          <div className={classes.card}>{children}</div>
        </Hidden>
      </>
    );
  }
}

export { default as TableHead } from './components/TableHead';
export { default as TableBody } from './components/TableBody';
export { default as TableRow } from './components/TableRow';
export { default as TableCol } from './components/TableCol';
export { default as TablePaginationDefault } from './components/TablePaginationDefault';
