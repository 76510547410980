import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { EventHeader } from 'components/Presentational/EventHeader/types';
import { IEvent, IEventPageEdit, ITicketDetail } from 'interfaces/event';
import { PageContent } from 'Pages/MyEvents/CustomTemplate/types';
import { advertisementLinksConfig, PartnerConfig } from 'Pages/MyEvents/EventPage/types';

type Props = {
  data: EventDetail | null;
  loading: boolean;
  children: ReactNode;
};

type Context = {
  data: EventDetail | null;
  loading: boolean;
  setEventData: Dispatch<SetStateAction<EventDetail>>;
};

export type EventDetail = {
  event: IEvent;
  eventHeader: EventHeader;
  pageContent: PageContent;
  eventTickets: ITicketDetail[];
  advertisementLinks: advertisementLinksConfig;
  page_config: IEventPageEdit;
  partnerConfig: PartnerConfig;
};

export const EventDetailContext = createContext<Context | null>(null);

export const EventDetailProvider = ({ data, loading, children }: Props) => {
  const [eventData, setEventData] = useState(data);

  return (
    <EventDetailContext.Provider value={{ data: eventData, loading, setEventData: setEventData }}>
      {children}
    </EventDetailContext.Provider>
  );
};

export function useEventDetail() {
  return useContext(EventDetailContext);
}
