import styled, { css } from 'styled-components';

import { WrapperProps } from './types';

const wrapperModifier = {
  left: () => css`
    justify-content: flex-start;
  `,
  center: () => css`
    justify-content: center;
  `,
  right: () => css`
    justify-content: flex-end;
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ align = 'left' }) => css`
    width: 100%;
    display: flex;

    ${wrapperModifier[align]}
  `}
`;

export const Counter = styled.span`
  color: #626464;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  font-family: 'Roboto', sans-serif;
`;
