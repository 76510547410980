import dateFnsFormat from 'date-fns/format';
import dateFnsIsValid from 'date-fns/isValid';
import locale from 'date-fns/locale/pt-BR';
import dateFnsParse from 'date-fns/parse';

export function dateParse(value: any, format: string = null): Date {
  if (!value) return value;
  if (value instanceof Date) return value;

  const date = !format ? new Date(value) : dateFnsParse(value, format, new Date(), { locale });
  if (!dateFnsIsValid(date)) return value;

  return date;
}

export function dateFormat(date: any, format = 'dd/MM/yyyy, HH:mm'): string {
  let newDate = date;
  if (typeof date === 'string' && date.trim() !== '') {
    newDate = new Date(date.replace(/-/g, '/'));
  }
  if (!newDate || typeof newDate.getTime !== 'function' || isNaN(newDate.getTime())) {
    return '';
  }
  return dateFnsFormat(newDate, format, { locale });
}
