import { PureComponent } from 'react';

import { Checkbox } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { WithStyles } from 'decorators/withStyles';

import styles from './styles';
import { IProps } from './types';

@WithStyles(styles)
export default class TableHead extends PureComponent<IProps, {}> {
  render() {
    const { allParticipantsAreSelected, disableSelectAll, head, onSelectAll } = this.props;

    return (
      <Hidden smDown>
        <thead>
          <tr>
            <th>
              <Checkbox
                checked={allParticipantsAreSelected || disableSelectAll}
                color='primary'
                onChange={e => onSelectAll(e.target.checked)}
                style={{ paddingLeft: 0 }}
                disabled={disableSelectAll}
              />
            </th>
            {head.map((item, index) => (
              <th
                key={'head-' + index}
                className={`${item.numeric ? '--numeric' : ''}${item.status ? '--status' : ' headString'}`}
                style={item?.textAlign && { textAlign: item?.textAlign }}
              >
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
      </Hidden>
    );
  }
}
