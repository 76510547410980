import jwtDecode from 'jwt-decode';
import { Observable, BehaviorSubject, of } from 'rxjs';
import * as rxjsOperators from 'rxjs-operators';

export interface IAuth {
  token: string;
  refresh: string;
}

export class TokenService {
  private tokens$: BehaviorSubject<IAuth>;

  constructor() {
    this.tokens$ = new BehaviorSubject(null);
  }

  public getAccessToken(): Observable<string> {
    return this.tokens$.pipe(
      rxjsOperators.map(tokens => {
        return tokens?.token;
      }),
      rxjsOperators.distinctUntilChanged()
    );
  }

  public getTokens(): Observable<IAuth> {
    return this.tokens$.pipe(rxjsOperators.distinctUntilChanged());
  }

  public setTokens(tokens: IAuth) {
    window.sessionStorage.setItem('refreshToken', tokens.refresh);
    this.tokens$.next(tokens);
    return of(tokens);
  }

  public clearToken() {
    this.setTokens(null);
  }

  public decode<T>(token: string): T {
    try {
      return jwtDecode<T>(token);
    } catch (err) {
      console.error('failed to decode token!');
      return null;
    }
  }
}

const tokenService = new TokenService();
export default tokenService;
