import React, { useMemo, useCallback } from 'react';

import SupportChat from 'components/Container/PrivateLayout/SupportChat';
import AttendanceList from 'components/Pages/Admin/AttendanceList';
import TabsMyEvents from 'components/Pages/Admin/TabsMyEvents';
import AsyncComponent from 'components/Shared/AsyncComponent';
import FIDCAlert from 'components/Shared/FIDCAlert';
import PageLoader from 'components/Shared/PageLoader';
import { useCustomObservable } from 'hooks';
import EventCreate from 'Pages/Events/Create';
import DuplicateEvent from 'Pages/Events/Duplicate';
import EditEvent from 'Pages/Events/Edit';
import { Redirect, Route, Switch } from 'react-router-dom';
import authService from 'services/auth';
import { User } from 'services/user/types';

import attendanceRoutes from './attendanceRoutes';
import { AttendanceRoutes } from './attendanceRoutes/types';
import eventAdminRoutes from './eventAdminRoutes';
import { EventAdminRoutes } from './eventAdminRoutes/types';
import eventRoutes from './eventRoutes';
import { EventRoutes } from './eventRoutes/types';
import generalRoutes from './generalRoutes';
import { GeneralRoutes } from './generalRoutes/types';
import myEventsRoutes from './myEventsRoutes';
import { MyEventsRoutes } from './myEventsRoutes/types';
import myTicketsRoutes from './myTicketsRoutes';
import { MyTicketsRoutes } from './myTicketsRoutes/types';
import parcitipantsRoutes from './ParcitipantsRoutes';
import { ParcitipantsRoutes } from './ParcitipantsRoutes/types';

const AuthenticatedRoutes = ({ attendanceProps, classes }: any) => {
  const { value: userData } = useCustomObservable<User, string, string>(() => authService.getUser(), null);

  const AttendanceListProps = useMemo(
    () => ({
      AttendanceList: props => <AttendanceList {...props} {...attendanceProps} />
    }),
    [attendanceProps]
  );

  const renderAdminEventRoutes = useCallback(
    (route: EventAdminRoutes) => {
      const eventProps = { userData };
      const ImportComponent = route.newDirectory
        ? AsyncComponent(() => import('Pages/' + route.import))
        : AsyncComponent(() => import('components/Pages/' + route.import), eventProps[route.props]);

      return <Route key={route.path} exact path={route.path} component={ImportComponent} />;
    },
    [userData]
  );

  const renderEventRoutes = useCallback((route: EventRoutes) => {
    const map = {
      'Events/Create': EventCreate,
      'Events/Edit': EditEvent,
      'Events/Duplicate': DuplicateEvent
    };
    return <Route key={route.path} exact path={route.path} component={map[route.import]} />;
  }, []);

  const renderMyEventsRoutes = useCallback((route: MyEventsRoutes) => {
    const ImportComponent = route.newDirectory
      ? AsyncComponent(() => import('Pages/' + route.import))
      : AsyncComponent(() => import('components/Pages/' + route.import));

    return <Route key={route.path} exact path={route.path} component={ImportComponent} />;
  }, []);

  const renderMyTicketsRoutes = useCallback((route: MyTicketsRoutes) => {
    return (
      <Route
        key={route.path}
        path='/my-tickets'
        component={() => {
          window.location.href = route.import;
          return null;
        }}
      />
    );
  }, []);

  const renderAttendanceRoutes = useCallback(
    (route: AttendanceRoutes) => {
      return (
        <Route
          render={route.render && AttendanceListProps[route.render]}
          key={route.path}
          exact
          path={route.path}
          component={!route.render && AsyncComponent(() => import('components/Pages/' + route.import))}
        />
      );
    },
    [AttendanceListProps]
  );

  const renderParcitipantsRoutes = useCallback((route: ParcitipantsRoutes) => {
    return (
      <Route
        exact
        key={route.path}
        path={route.path}
        component={AsyncComponent(() => import('Pages/' + route.import))}
      />
    );
  }, []);

  const renderGeneralRoutes = useCallback((route: GeneralRoutes) => {
    return (
      <Route
        key={route.path}
        exact
        path={route.path}
        component={AsyncComponent(() => import('components/Pages/' + route.import))}
      />
    );
  }, []);

  if (!userData) {
    return <PageLoader />;
  }

  return (
    <div className={classes.content}>
      <FIDCAlert />
      <SupportChat />
      <Switch>
        <Redirect exact from='/' to='/home' />

        <Route
          exact
          path='/presence-list/:eventId/participant/:cliCod'
          component={AsyncComponent(() => import('Pages/Participants/Detail'))}
        />

        {generalRoutes.map(renderGeneralRoutes)}

        {eventAdminRoutes.map(renderAdminEventRoutes)}

        {eventRoutes.map(renderEventRoutes)}

        {myEventsRoutes.map(renderMyEventsRoutes)}

        <Route exact path='/my-events/:id/:page' component={TabsMyEvents} />

        <Route exact path='/my-events/:id/:page/:subPage' component={TabsMyEvents} />

        {myTicketsRoutes.map(renderMyTicketsRoutes)}

        {attendanceRoutes.map(renderAttendanceRoutes)}

        {parcitipantsRoutes.map(renderParcitipantsRoutes)}

        <Route exact path='*' component={AsyncComponent(() => import('components/Pages/Admin/Home'))} />
      </Switch>
    </div>
  );
};

export default AuthenticatedRoutes;
