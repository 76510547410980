/* eslint-disable max-len */
import React, { useState } from 'react';

import CopyOutlined from '@ant-design/icons/CopyOutlined';
import { Button, Input, Typography, Tooltip, Radio } from 'components/Shared/Antd';
import { useParams } from 'react-router-dom';

import * as S from './styles';
import { PageParams } from './types';

const EventWidget = () => {
  const [radioOption, setRadioOption] = useState('v1');
  const { id: eventId } = useParams<PageParams>();

  const handleRadioOption = e => {
    setRadioOption(e.target.value);
  };

  const scriptWidget = `<div id="blinketWidget_${radioOption}"></div>
      <script>
        eval(atob("dmFyIGxvYWRXaWRnZXQgPSBmdW5jdGlvbihjb25maWcpIHsNCiAgICAgICAgICAgICAgICB3aW5kb3cuYmxpbmtldCA9IE9iamVjdC5hc3NpZ24od2luZG93LmJsaW5rZXQsIGNvbmZpZyk7DQogICAgICAgICAgICAgICAgY29uc3QgZGF0ZSA9IG5ldyBEYXRlKCkuZ2V0VGltZSgpOw0KICAgICAgICAgICAgICAgIGRvY3VtZW50LndyaXRlKCc8c2NyaXB0IHNyYz0iaHR0cHM6Ly9jZG4uZWR1enpjZG4uY29tL2JsaW5rZXQtd2lkZ2V0L2JsaW5rZXQtd2lkZ2V0LmpzPycgKyBkYXRlICsgJyI+PFwvc2NyaXB0PicpOw0KICAgICAgICAgICAgfQ0KICAgICAgICAgICAgd2luZG93LmJsaW5rZXQgPSB7DQogICAgICAgICAgICAgICAgc3RhcnQ6IGxvYWRXaWRnZXQNCiAgICAgICAgICAgIH07"))

        blinket.start({
          selector: '#blinketWidget_${radioOption}',
          id: '${eventId}', /* Este é o ID de seu evento */
          template: '${radioOption}' /* v1 ou v2 */
        });
      </script>`;

  return (
    <S.Container>
      <p className='event-widget-text desktop'>Selecione a versão do Widget que deseja utilizar:</p>
      <p className='event-widget-text mobile'>Selecione a versão do Widget:</p>

      <Radio.Group onChange={handleRadioOption} style={{ marginTop: '16px', marginBottom: '16px' }} value={radioOption}>
        <Radio value='v1'>Basic</Radio>
        <Radio value='v2'>Pop</Radio>
      </Radio.Group>

      <div className='event-widget-button-container'>
        <Tooltip title={<Typography style={{ color: 'white' }}>Copiado!</Typography>} trigger='click'>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(scriptWidget);
            }}
            icon={<CopyOutlined />}
          >
            Copiar
          </Button>
        </Tooltip>
      </div>

      <Input.TextArea disabled rows={10} value={scriptWidget} className='event-widget-text-field' />
    </S.Container>
  );
};

export default EventWidget;
