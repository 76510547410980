import axios, { AxiosError, Method } from 'axios';
import Toast from 'components/Shared/Toast';
import ApiError from 'errors/api';
import { apiResponseFormatter } from 'formatters/apiResponse';
import * as rxjs from 'rxjs';
import { empty } from 'rxjs';
import { logError } from 'rxjs-operators';
import * as rxjsOperators from 'rxjs/operators';
import { v4 } from 'uuid';

import { API_ENDPOINT, REACT_SECRET, REACT_TOKEN } from '../settings';
import tokenService, { IAuth } from './token';
import userService from './user';

type RequestOptions = {
  retry?: boolean;
  supressError?: boolean;
};

export class ApiService {
  private tokenRefreshRequest$: rxjs.Observable<IAuth>;

  constructor(private apiEndpoint: string) {}

  public get<T = any>(url: string, params?: any, options?: RequestOptions): rxjs.Observable<T> {
    return this.request('GET', url, params, options);
  }

  public post<T = any>(url: string, body: any): rxjs.Observable<T> {
    return this.request('POST', url, body);
  }

  public put<T = any>(url: string, body: any): rxjs.Observable<T> {
    return this.request('PUT', url, body);
  }

  public patch<T = any>(url: string, body: any): rxjs.Observable<T> {
    return this.request('PATCH', url, body);
  }

  public delete<T = any>(url: string, params?: any): rxjs.Observable<T> {
    return this.request('DELETE', url, params);
  }

  public handleErrorMessage(err: any) {
    if (err.message === 'Você não possui permissão para realizar esta ação') {
      Toast.error(err.message, 6000);
    } else {
      if (err.details !== undefined) {
        const errMsg = Array.isArray(err.details) ? err.details[0] : err.details;
        Toast.error(errMsg);
      } else {
        Toast.error('Ocorreu um erro. Tente novamente');
      }
    }
  }

  private request<T>(
    method: Method,
    url: string,
    data: any = null,
    option: RequestOptions = null,
    ignoreBearerToken = false
  ): rxjs.Observable<T> {
    option = option || {};
    option = {
      retry: false,
      supressError: false,
      ...option
    };
    const progress$ = new rxjs.BehaviorSubject(0);

    return rxjs.of(true).pipe(
      rxjsOperators.switchMap(() => (ignoreBearerToken ? rxjs.of(null) : this.getBearerToken())),
      rxjsOperators.map(token => (token ? { Authorization: token } : null)),
      rxjsOperators.map(headers => {
        return {
          token: REACT_TOKEN,
          secret: REACT_SECRET,
          'X-Request-Uuid-Signature': v4(),
          'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
          ...headers
        };
      }),
      rxjsOperators.switchMap(headers => {
        return axios.request({
          baseURL: this.apiEndpoint,
          url,
          method,
          timeout: 90000,
          headers: {
            'Content-type': 'application/json',
            ...headers
          },
          params: method === 'GET' ? data : null,
          data: method !== 'GET' ? data : null,
          onUploadProgress: (progress: ProgressEvent) => {
            const result = progress.loaded / progress.total;
            progress$.next(result * 100);
          }
        });
      }),
      rxjsOperators.tap(() => progress$.next(100)),
      rxjsOperators.map(res => {
        if (res?.data.code === 'ERR_DP409') {
          throw res;
        }

        return apiResponseFormatter(res.data) || undefined;
      }),
      rxjsOperators.catchError(err => {
        progress$.complete();
        return this.handleError(err, option);
      })
    );
  }

  private getBearerToken() {
    return rxjs.combineLatest([tokenService.getTokens(), userService.getUser()]).pipe(
      rxjsOperators.first(),
      rxjsOperators.switchMap(([tokens, user]) => {
        if (!tokens || !user) return rxjs.of(null);

        const now = (Date.now().valueOf() + 500) / 1000;
        if (!user.exp || user.exp > now) {
          return rxjs.of(tokens.token);
        }

        if (!this.tokenRefreshRequest$) {
          this.tokenRefreshRequest$ = rxjs
            .from(this.request('POST', '/auth_v2/refresh', { jwt_refresh_token: tokens.refresh }, {}, true))
            .pipe(
              rxjsOperators.map(({ data }) => ({
                token: data.jwt_token,
                refresh: data.jwt_refresh_token
              })),
              rxjsOperators.switchMap((tokens: IAuth) => tokenService.setTokens(tokens)),
              rxjsOperators.tap(() => (this.tokenRefreshRequest$ = null)),
              logError(),
              rxjsOperators.catchError(() => {
                window.location.reload();
                return rxjs.NEVER;
              }),
              rxjsOperators.shareReplay(1)
            );
        }

        return this.tokenRefreshRequest$.pipe(rxjsOperators.map(tokens => tokens?.token));
      }),
      rxjsOperators.map(accessToken => (accessToken ? `Bearer ${accessToken}` : null))
    );
  }

  private handleError(err: AxiosError, option: RequestOptions) {
    if (!err.config) {
      return this.formatError(err, option);
    }

    if (err.response.status === 401) {
      const jwtOptions = { retry: false, suppressError: true };
      return userService.getUser().pipe(
        rxjsOperators.skip(1),
        rxjsOperators.switchMap(user => {
          if (!user) {
            return this.formatError(err, jwtOptions);
          }

          return this.request(err.config.method, err.config.url, err.config.data || err.config.params);
        })
      );
    }

    if (err.response?.data?.dataValidation) {
      Toast.error('Por favor preencha todos os campos corretamente');
    }

    return this.formatError(err, option);
  }

  private formatError(err: AxiosError, option: RequestOptions) {
    if (option.supressError) {
      return empty();
    }

    if (!err.config) {
      return rxjs.throwError(err);
    }

    return rxjs.throwError(new ApiError(err.config, err.response, err));
  }
}

const apiService = new ApiService(API_ENDPOINT);
export default apiService;
