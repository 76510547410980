import { AppStyle } from 'decorators/withStyles';

const styles: AppStyle = theme => ({
  buttonSubmit: {
    background: theme.variables.mainColor,
    color: '#FFFFFF',
    width: 140
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dialogContent: { overflow: 'hidden' }
});

export default styles;
