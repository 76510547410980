import { DialogContent, DialogTitle, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomizedDialogTitle = styled(DialogTitle)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const CustomizedDialogContent = styled(DialogContent)`
  padding-top: 0;
  padding-left: 40px;
  padding-right: 24px;
  padding-bottom: 24px;
`;

export const Title = styled(Typography)`
  color: #000000;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.6;
`;

export const CustomizedTypography = styled(Typography)`
  font-size: 16px;
  color: #000000;
`;
