import styled from '@emotion/styled';
import { Button } from 'antd';

export const DateWrapper = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  padding: 5px;
  margin: 10px 0;
  justify-content: space-between;
`;

export const Day = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  min-width: 50px;
`;

export const Info = styled.h3`
  font-size: 1rem;
`;

export const MenuButton = styled(Button)`
  margin: 0px;
  padding: 0px;
  border: none;
  font-weight: 600;
`;
