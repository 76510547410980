import React, { PureComponent } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import { WithStyles } from 'decorators/withStyles';

import styles from './styles';
import { IProps, IState } from './types';

@WithStyles(styles)
export default class Loader extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { refs: [] };
  }

  show = (ref: string): void => {
    if (typeof ref !== 'string') {
      throw new Error('Loader.show needs a ref string value');
    }

    const { refs } = this.state;
    if (refs.includes(ref)) return;

    this.setState({ refs: [...refs, ref] });
  };

  hide = (ref: string): void => {
    if (typeof ref !== 'string') {
      throw new Error('Loader.hide needs a ref string value');
    }

    const { refs } = this.state;
    const index = refs.indexOf(ref);
    if (index === -1) return;

    refs.splice(index, 1);
    this.setState({ refs: [...refs] });
  };

  render(): JSX.Element {
    const { refs } = this.state;
    const { classes } = this.props;

    return (
      <Dialog open={!!refs.length} PaperProps={{ className: classes.paper }}>
        <LoadingOutlined className={classes.loader} spin />
      </Dialog>
    );
  }
}
