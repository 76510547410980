import { Grid } from '@material-ui/core';

import { StepButtonsGroup, Form } from '../components';
import { FormContextProvider } from '../Context';
import * as S from './styles';

const CreateEvent = () => {
  return (
    <FormContextProvider isCreate>
      <Grid container spacing={4} style={{ marginBottom: 40 }}>
        <Grid item xs={12}>
          <S.Title>Criar evento</S.Title>
          <S.Subtitle>Você está um passo mais próximo de tornar seu evento uma realidade!</S.Subtitle>
        </Grid>
        <Grid item xs={12}>
          <StepButtonsGroup />
        </Grid>
        <Grid item xs={12}>
          <Form isCreate />
        </Grid>
      </Grid>
    </FormContextProvider>
  );
};
export default CreateEvent;
