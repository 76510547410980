import { EventHeader } from 'components/Presentational/EventHeader/types';
import { ITicketDetail } from 'interfaces/event';
import { IOffer } from 'interfaces/offer';
import { DeleteOffer } from 'services/offers/types';

export enum ActionTypes {
  offers = 'data/offers',
  openModalOffer = 'openModal/offer',
  offersAddLoading = '/offers/add/loading',
  generateLinkOffer = 'data/link/offer',
  loadingLinkOfferId = 'data/link/offer/id',
  loadingPage = 'page/loading',
  offersDelete = 'offers/delete',
  offersDeleteSuccess = 'offers/delete/success'
}

export type PageParams = {
  id: string;
};

export interface EventData {
  eventHeader: EventHeader;
  eventTicks: ITicketDetail[];
}
export interface StateProps {
  offers: IOffer[];
  openModalOffer: boolean;
  offersAddLoading: boolean;
  generateLinkOffer: string;
  loadingLinkOfferId: string;
  loadingPage: boolean;
  deleteOfferIds: DeleteOffer;
}

export interface ActionProps {
  type: ActionTypes;
  value?: boolean | string | IOffer[] | DeleteOffer;
}
