import { createContext, useState } from 'react';

import useEventCategory from 'hooks/useEventCategory';

import { FormContextValues, Steps, Step, FormContextProps } from './types';

const FormContext = createContext<FormContextValues>(null);

export const FormContextProvider = ({
  children,
  isCreate,
  handleEdit,
  loadingEdit,
  jobsLotPending
}: FormContextProps) => {
  const [currentStep, setCurrentStep] = useState<Steps>('1');
  const [stepsEnables, setStepsEnables] = useState<Steps[]>(['1']);
  const steps: Step[] = [
    {
      key: '1',
      title: 'Dados Básicos'
    },
    {
      key: '2',
      title: 'Local do Evento'
    },
    {
      key: '3',
      title: 'Ingressos e Datas'
    },
    {
      key: '4',
      title: 'Resumo'
    }
  ];
  const eventCategories = useEventCategory();

  return (
    <FormContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        setStepsEnables,
        stepsEnables,
        steps,
        isCreate,
        handleEdit,
        loadingEdit,
        jobsLotPending,
        eventCategories
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
