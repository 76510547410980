import * as React from 'react';
import { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ColorPicker from 'components/Shared/ColorPicker';
import { WithStyles } from 'decorators/withStyles';
import rgbaToHex from 'helpers/rgbaToHex';

import Toast from '../Toast';
import styles from './styles';
import { IProps, IState } from './types';

@WithStyles(styles)
export default class ColorInput extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      color: props.value || '#000000'
    };
  }

  componentDidMount() {
    if (this.props.RGBFormat) {
      const color = rgbaToHex(this.props.value);
      this.setState({ color });
    }
  }

  handleDisplaySketchPicker = (event: any) => {
    ColorPicker.getColor(event, this.state.color).subscribe(
      color => {
        if (this.props.id) {
          this.props.handleChangeColor(color.hex, 'color', this.props.id);
        } else {
          this.props.handleChangeColor(color.hex, 'color');
        }
        this.setState({ color: color.hex });
      },
      ({ data: err }) => {
        if (err.message === 'Você não possui permissão para realizar esta ação') {
          Toast.error(err.message, 6000);
        }
      }
    );
  };

  changeColor = (event: any) => {
    let color = event.target.value.replace(/[^a-fA-F0-9]/gi, '').substring(0, 6);
    if (color.charAt(0) !== '#') {
      color = '#' + color;
    }
    this.setState({ color });

    if (this.props.id) {
      this.props.handleChangeColor(color, 'color', this.props.id);
    } else {
      this.props.handleChangeColor(color, 'color');
    }
  };

  render() {
    const { classes } = this.props;
    const { color } = this.state;
    return (
      <div className={classes.root}>
        <Grid container alignItems='flex-start'>
          <Grid item xs={12}>
            <TextField
              id='color-picker'
              label={this.props.label}
              required={true}
              onChange={this.changeColor}
              onClick={this.handleDisplaySketchPicker}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                disableUnderline: true,
                className: classes.input,
                startAdornment: (
                  <InputAdornment position='start'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '15px',
                        width: '15px',
                        borderRadius: '6px',
                        backgroundColor: this.state.color
                      }}
                    />
                  </InputAdornment>
                )
              }}
              fullWidth
              value={color}
              margin='normal'
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
