import { Dialog } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Dialog)`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    display: flex;
    flex-direction: column;

    .modal-link-content {
      display: flex;
      flex-direction: column;
      padding: 16px;

      .modal-link-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h2 {
          font-size: 16px;
          color: ${colors.primary.contrastText};
          font-weight: ${fontWeight('semibold')};
        }

        .modal-link-cancel {
          cursor: pointer;

          svg {
            color: ${colors.primary.contrastText};
          }
        }
      }

      .modal-link-advice {
        display: flex;
        flex-direction: row;
        margin: 23px 0;

        span {
          color: ${colors.primary.contrastText};
          margin: 4px 0 0 0;
        }

        p {
          margin: 0 0 0 10px;
          font-size: 16px;
          color: ${colors.primary.contrastText};
          font-weight: ${fontWeight('regular')};
        }
      }

      .modal-link-fields {
        display: flex;
        flex-direction: row;
        align-items: center;

        .mobile {
          width: 100%;
        }

        .desktop {
          display: none;
        }
      }
    }

    @media (${breakpoints.md}) {
      .modal-link-content {
        padding: 24px;

        .modal-link-fields {
          flex-direction: row;

          div {
            div {
              fieldset {
                margin: 0 10px 0 0;
              }
            }
          }

          .mobile {
            display: none;
          }

          .desktop {
            align-items: center;
            display: flex;
            width: 144px;
          }
        }
      }
    }
  `}
`;
