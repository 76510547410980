import React, { memo, useCallback, useContext, useState } from 'react';

import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalWarning } from 'components/Presentational';
import { Button } from 'components/Shared/Antd';
import Toast from 'components/Shared/Toast';
import { isEmpty } from 'helpers/lodash';
import { IEventPageEdit } from 'interfaces/event';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import FormContext from '../../context';
import EventContent from '../EventContent';
import EventHeading from '../EventHeading';
import * as S from './styles';

const Form = memo(() => {
  const { handleSaveEdit, isSaving, ...props } = useContext(FormContext);
  const [continueModalWarning, setContinueModalWarning] = useState<(() => void) | null>(null);

  const history = useHistory();
  const form = useForm({
    resolver: yupResolver(
      yup.object().shape({
        helpsupport_email: yup.string().email(),
        main_video_url: yup.string().url(),
        sale_url: yup.string().required('Campo obrigatório')
      })
    ),
    mode: 'onChange'
  });

  const handleSaveForm = (isDraft: boolean) => {
    if (!isEmpty(form?.formState?.errors)) {
      Toast.error('Verifique todos os campos');
      return;
    }
    handleSaveEdit({ ...(form.getValues() as unknown as IEventPageEdit) }, isDraft);
  };

  const handlePreview = useCallback(() => {
    if (props.hasChangedData && !props.draftSaved) {
      const onContinue = () => {
        setContinueModalWarning(null);
        props.handleOpenPreview(true);
      };

      return setContinueModalWarning(() => onContinue);
    }
    return props.handleOpenPreview(true);
  }, [props]);

  const handleReturn = useCallback(() => {
    history.push(`/my-events/${props.eventId}/event-page`);
  }, [history, props.eventId]);

  return (
    <S.Container>
      <FormProvider {...form}>
        <EventHeading />
        <hr className='custom-template-hr' />
        <EventContent />
        <div className='form-button-container'>
          <div className='form-button-container-left'>
            <Button
              type='text'
              htmlType='submit'
              size='large'
              onClick={handleReturn}
              disabled={isSaving}
              icon={<ArrowLeftOutlined style={{ color: '#000000', marginRight: '5px' }} />}
            >
              Voltar
            </Button>
            <Button size='large' onClick={() => handleSaveForm(true)} disabled={isSaving}>
              <FileTextOutlined style={{ color: '#000000', marginRight: '5px' }} /> Salvar rascunho
            </Button>
          </div>

          <div className='form-button-container-right'>
            <Button size='large' onClick={handlePreview} disabled={isSaving}>
              <EyeOutlined style={{ color: '#000000', marginRight: '5px' }} />
              Visualizar
            </Button>
            <Button size='large' type='primary' onClick={() => handleSaveForm(false)} disabled={isSaving}>
              Salvar
            </Button>
          </div>
        </div>
      </FormProvider>
      <ModalWarning
        title='Atenção'
        content='Você não salvou suas alterações, deseja continuar sem salvar? Se continuar, suas alterações não serão mostradas.'
        open={!!continueModalWarning}
        onCancel={() => setContinueModalWarning(null)}
        onContinue={continueModalWarning}
      />
    </S.Container>
  );
});
export default Form;
