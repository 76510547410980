import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const Content = styled.div`
  margin: 0 auto;
`;

export const ImageError = styled.img`
  height: 315px;
  width: 490px;
`;

export const MessageWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[400]};
  display: flex;
  flex-direction: column;
  margin: 32px 0 0 0;
  text-align: center;

  h3 {
    font-weight: 700;
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 0 0;
    max-width: 480px;
  }
`;

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  height: 100px;
  padding: 20px 40px;
  width: 100%;
`;

export const EduzzLogo = styled.img`
  height: 56px;
  margin: 0 24px 0 0;
  width: 176px;
`;

export const CopyRight = styled.span`
  color: ${({ theme }) => theme.colors.grey[500]};
  font-size: 14px;
  font-weight: 400;
`;
