import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'antd';
import Toast from 'components/Shared/Toast';
import attendanceListService from 'services/attendanceList';

interface IProps {
  open: boolean;
  markerId: string;
  markerTitle: string;
  handleClose: () => void;
  onComplete: () => void;
}

export default function ArchiveMarker({ open, handleClose, markerId, markerTitle, onComplete }: IProps) {
  const [loading, setLoading] = useState(false);

  const confirmArchiveMarker = () => {
    setLoading(true);
    attendanceListService.archiveMarker(markerId).subscribe(
      () => {
        setLoading(false);
        Toast.show('Marcador removido com sucesso');
        onComplete();
      },
      ({ data: err }) => {
        setLoading(false);
        if (err.message === 'Você não possui permissão para realizar esta ação') {
          Toast.error(err.message, 6000);
        } else {
          Toast.error('Houve um erro ao remover o marcador. Tente novamente');
        }
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Remover Marcador</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          O marcador <b>{markerTitle}</b> será excluído e removido de todos que o possuem. Deseja continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type='text' id='participants-removemarkerdialog-cancelbutton' disabled={loading} onClick={handleClose}>
          Cancelar
        </Button>

        <Button
          autoFocus
          type='primary'
          id='participants-removemarkerdialog-confirmbutton'
          disabled={loading}
          onClick={() => confirmArchiveMarker()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
