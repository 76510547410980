import React, { memo, useContext } from 'react';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Typography, Divider, Button, Input, Row, Col } from 'components/Shared/Antd';
import CurrencyInput from 'components/Shared/CurrencyInput';
import Field from 'components/Shared/Field';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { AddOffers, TicketsOffers } from 'services/offers/types';
import { NumberSchema } from 'yup';
import * as yup from 'yup';

import OffersContext from '../../context';
import Tickets from '../Tickets';
import { ModalOffersProps } from './types';

const ModalOffers = memo(({ onClose, onSubmitForm, open, isSubmitting }: ModalOffersProps) => {
  const { eventId } = useContext(OffersContext);

  const form = useForm<AddOffers>({
    defaultValues: {
      offerTitle: undefined,
      eventId: undefined,
      tickets: [
        {
          id: 0,
          ticketId: '',
          ticketQuantity: '0',
          ticketValue: '0',
          updatedStockCurrentLot: 0
        }
      ]
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        offerTitle: yup.string().required('Este campo é obrigatório'),
        tickets: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              ticketId: yup.string().required('Este campo é obrigatório'),
              ticketQuantity: yup
                .number()
                .required('Este campo é obrigatório')
                .when('ticketId', (ticketId: string, schema: NumberSchema) => {
                  const currentStock = ticketId?.split('/stock:')[1];
                  if (parseInt(currentStock) === 0) {
                    return schema.max(parseInt(currentStock), 'Sem stock disponível');
                  }
                  return !!currentStock ? schema.max(parseInt(currentStock), 'Sem stock disponível') : schema;
                })
                .typeError('')
                .min(1, 'O Valor mínimo é 1'),
              ticketValue: yup
                .number()
                .required('Este campo é obrigatório')
                .typeError('')
                .min(1, 'O Valor mínimo é 1,00')
            })
          )
          .required()
      })
    )
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'tickets',
    keyName: 'identificator'
  });

  const onSubmit = values => {
    const payload = {
      offerTitle: values.offerTitle,
      eventId,
      tickets: values.tickets.map((ticket: TicketsOffers, index) => ({
        id: index,
        ticketId: ticket.ticketId.split('/stock:')[0],
        ticketQuantity: ticket.ticketQuantity,
        ticketValue: ticket.ticketValue.toString().replace('.', ','),
        updatedStockCurrentLot: parseInt(ticket.ticketId.split('/stock:')[1])
      }))
    };
    onSubmitForm(payload);
  };

  const totalValue = form
    .watch('tickets')
    ?.reduce((acc: number, cur: { ticketValue: string; ticketQuantity: string }) => {
      const { ticketValue, ticketQuantity } = cur;
      return acc + (parseInt(ticketValue) || 0) * (parseInt(ticketQuantity) || 0);
    }, 0);

  const handleAddTicket = () => {
    const newTicket = {
      id: 0,
      ticketId: '',
      ticketQuantity: '0',
      ticketValue: '0',
      updatedStockCurrentLot: 0
    };
    append(newTicket);
  };

  const handleRemoveTicket = (index: number) => {
    remove(index);
  };

  return (
    <Modal
      zIndex={4000}
      open={open}
      onCancel={onClose}
      width={640}
      centered
      title={
        <>
          <Typography.Title level={4}>Adicionar oferta personalizada</Typography.Title>
          <Divider />
        </>
      }
      footer={[
        <React.Fragment key='primary'>
          <Divider />
          <Button
            type='primary'
            id='add-offer-btn'
            htmlType='submit'
            size='large'
            onClick={form.handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            Salvar
          </Button>
        </React.Fragment>
      ]}
    >
      <FormProvider {...form}>
        <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
          <Col md={14} xs={24}>
            <Typography.Text>Nome</Typography.Text>
            <Field name='offerTitle' render={<Input size='large' placeholder='Nome da oferta' />} />
          </Col>
          <Col md={10} xs={24}>
            <Typography.Text>Valor total da oferta</Typography.Text>
            <CurrencyInput value={totalValue} disabled />
          </Col>
        </Row>
        <Typography.Text>Ingressos</Typography.Text>
        {fields.map((ticket, index) => (
          <Tickets index={index} key={ticket.identificator} handleRemove={handleRemoveTicket} />
        ))}
        <Button icon={<PlusOutlined />} onClick={handleAddTicket}>
          Adicionar
        </Button>
      </FormProvider>
    </Modal>
  );
});

export default ModalOffers;
