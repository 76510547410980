import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, lineHeight, fontWeight } }) => css`
    position: relative;
    margin: 0 0 10px 0;

    label {
      font-size: 14px;
      font-weight: 600;
      color: #546e7a;
      position: absolute;
      z-index: 1;
      transform: translate(16px, -6px) scale(0.7) !important;
      transform-origin: top left;
      pointer-events: none;
      top: 1px;
      left: 4px;
      display: block;
    }

    fieldset {
      padding: 0 7px 0 12px;
      border-radius: 4px;
      border: solid 1px rgba(0, 0, 0, 0.23);
      background-color: white;
      width: 100%;

      legend {
        width: auto;
        height: 11px;
        display: block;
        padding: 0;
        font-size: 0.75em;
        max-width: 0.01px;
        text-align: left;
        transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        visibility: hidden;
        max-width: 1000px;

        span {
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    textarea {
      width: 100%;
      border: none;
      outline: none;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: ${fontWeight('regular')};
      line-height: ${lineHeight('normal')};
      color: ${colors.grey[300]};
    }

    &.focus {
      fieldset {
        border: solid 2px ${colors.primary.main};
      }
    }
  `}
`;
