import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: auto;

  ${({ theme: { colors, fontWeight, lineHeight } }) => css`
  .event-key {
      font-size: 20px;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 6px;
      font-weight: ${fontWeight('semibold')};
    }

    .event-description {
      margin: 16px 0;
      max-width: 100%;

      .event-description-title {
        color: ${colors.primary.contrastText};
        font-weight: ${fontWeight('semibold')};
        line-height: ${lineHeight('compact')};
      }

      .event-description-text-mobile {
        color: ${colors.primary.contrastText};
        font-size: 14px;
        line-height: ${lineHeight('compact')};
        margin: 8px 0 0 0;

        span {
          color: ${colors.primary.contrastText};
          font-size: 14px;
          font-weight: ${fontWeight('semibold')};
          margin: 0 0 0 5px;
          text-decoration: underline;
        }
      }

      .event-description-text-desk {
        display: none;
      }

      .event-key-description {
        font-size: 14px;
        color: ${colors.primary.contrastText};
        line-height: ${lineHeight('comfortable')};
      }
    }
      .event-description {
        max-width: 624px;

        .event-description-text-mobile {
          display: none;
        }
        .event-description-text-desk {
          color: ${colors.primary.contrastText};
          display: flex;
          font-size: 16px;
          margin: 8px 0 0 0;
        }
      }
    }
  `}
`;
