import React from 'react';

import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { Button } from 'antd';

interface IProps {
  classes?: any;
  filter: any;
  handleUpdateParentState: Function;
  handleAddFilter: Function;
  handleSubmitFilter: Function;
}

const Filter = ({ classes, filter, handleUpdateParentState, handleAddFilter, handleSubmitFilter }: IProps) => {
  const [search, setSearch] = React.useState<string>(filter.search || '');
  const [timeoutSearch, setTimeoutSearch] = React.useState<any>(0);

  const setOnlyNumbers = (value: string): string | null => {
    const removedSpecialChars = value.replace(/[^A-Z0-9]/gi, '');
    const hasOnlyNumber = /^[0-9]+$/.test(removedSpecialChars);

    return hasOnlyNumber ? removedSpecialChars : null;
  };
  const changeFilter = (e: any) => {
    e.persist();
    const text = e.target.value;
    setSearch(text);

    timeoutSearch && clearTimeout(timeoutSearch);

    setTimeoutSearch(
      setTimeout(() => {
        const searchSanitized = setOnlyNumbers(text) || text;
        return handleChangeFilter(searchSanitized);
      }, 1000)
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    timeoutSearch && clearTimeout(timeoutSearch);

    const searchSanitized = setOnlyNumbers(search) || search;
    return handleChangeFilter(searchSanitized, true);
  };

  const handleChangeFilter = (search: string, submitNow = false) => {
    const newFilter = { ...filter };
    newFilter['search'] = search;
    handleUpdateParentState({ filter: newFilter }, () => {
      handleAddFilter(search, 'search');
      if (((search.length >= 3 || search.length === 0) && !submitNow) || submitNow) {
        handleSubmitFilter();
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form className={classes.searchInputClient} onSubmit={handleSubmit}>
            <InputBase
              className={classes.searchInputClientInputText}
              placeholder='Quem você procura? Nome, Telefone, E-mail, Fatura, CPF'
              name='search'
              onChange={changeFilter}
              value={search || ''}
              inputProps={{ 'aria-label': 'search google maps' }}
              id='presencelist-event-search-input'
            />
            <Button
              type='text'
              htmlType='submit'
              onClick={handleSubmit}
              aria-label='search'
              id='presencelist-event-search-button'
              icon={<SearchOutlined />}
            />
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default Filter;
