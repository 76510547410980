import React from 'react';

import Table, { TableBody } from 'components/Shared/Table';

import InviteListItem from './ListItem';

interface IProps {
  items?: any[];
  type?: string;
  onComplete: (inviteKey: string, type: string) => void;
  id?: string;
}

export default function InviteListPage({ items, onComplete, type, id }: IProps) {
  return (
    <>
      <Table>
        <TableBody loading={false}>
          {items.map((item, index) => (
            <InviteListItem key={item} item={item} type={type} onComplete={onComplete} id={id + '-' + index} />
          ))}
        </TableBody>
      </Table>

      <div style={{ paddingBottom: 20 }} />
    </>
  );
}
