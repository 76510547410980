import React, { useCallback } from 'react';

import { Button } from 'antd';
import { useHistory } from 'react-router';

import { RouteButtonProps } from './types';

const RouteButton = ({ id, route, children, ...props }: RouteButtonProps) => {
  const history = useHistory();

  const handleGoToRoute = useCallback(() => {
    history.push(route);
  }, [route, history]);

  return (
    <Button id={id} onClick={handleGoToRoute} {...props}>
      {children}
    </Button>
  );
};
export default RouteButton;
