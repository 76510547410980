import React, { useCallback, useMemo, useState } from 'react';

import { TextareaAutosize } from '@material-ui/core';

import * as S from './styles';
import { TextAreaAutosizeOutlineProps } from './types';

const TextAreaAutosizeOutlined = ({
  className = '',
  label,
  onFocus,
  onBlur,
  ...props
}: TextAreaAutosizeOutlineProps) => {
  const [TextAreaFocus, setTextAreaFocus] = useState(false);

  const handleOnFocus = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setTextAreaFocus(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setTextAreaFocus(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const customClassName = useMemo(() => `${className} ${TextAreaFocus ? 'focus' : ''}`, [TextAreaFocus, className]);

  return (
    <S.Container className={customClassName}>
      <label>{label}</label>
      <fieldset>
        <legend>
          <span>{label}</span>
        </legend>
        <TextareaAutosize {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />
      </fieldset>
    </S.Container>
  );
};

export default TextAreaAutosizeOutlined;
