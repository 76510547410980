import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles: AppStyle = () => ({
  notfound: {
    color: '#777',
    fontSize: fontSizePxToRem(14),
    padding: '8px 0',
    textAlign: 'center',
    display: 'block'
  }
});

export default styles;
