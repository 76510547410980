import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors } }) => css`
    .tickets {
      display: flex;
      margin: 8px 0 0 0;
      flex-direction: column;
      background: ${colors.grey[200]};
      padding: 18px 12px;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  `}
`;
