import add from 'date-fns/add';
import format from 'date-fns/format';
import set from 'date-fns/set';
import { IEventForm, ILoteDetail, ILoteForm } from 'interfaces/event';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import { EventDetails } from 'services/event/types';
import { v4 } from 'uuid';

class DuplicateEventService {
  private converteEventDetailToEventForm(eventData: EventDetails): IEventForm {
    const startDate = set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });
    const endDate = add(startDate, { days: 7 });
    const lotStartDate = add(startDate, { days: 1 });
    const lotEndDate = add(startDate, { days: 6 });

    eventData.start_date_default = startDate;
    eventData.start_date = format(startDate, 'dd/MM/yyyy');
    eventData.start_hour = format(startDate, 'HH:mm');

    eventData.end_date_default = add(endDate, { hours: 2 });
    eventData.end_date = format(endDate, 'dd/MM/yyyy');
    eventData.end_hour = format(endDate, 'HH:mm');

    const convertLot = (lotData: ILoteDetail[]): ILoteForm[] => {
      return lotData.map((lot, index) => ({
        ...lot,
        id: null,
        is_active: lot.is_active === 1 || lot.is_active === null ? 1 : 0,
        dateEnd: lotEndDate,
        dateStart: lotStartDate,
        quantity: lot.quantity,
        quantity_sold: lot.quantity_sold,
        value: lot.value,
        end_sale: lotEndDate,
        start_sale: lotStartDate,
        identificator: v4(),
        name: lot.name ? lot.name : `Lote ${index + 1}`
      }));
    };

    const eventDataForm: IEventForm = {
      active_precheckout: eventData.active_precheckout,
      active_postcheckout: eventData.active_postcheckout,
      hasReserveTime: eventData.reserve_time > 0,
      cloned: true,
      dateEnd: eventData.end_date_default,
      dateStart: eventData.start_date_default,
      end_date_default: eventData.end_date_default,
      start_date_default: eventData.start_date_default,
      ticket: eventData.tickets.map(item => ({
        ...item,
        id: null,
        is_active: item.is_active === 1 || item.is_active === null ? 1 : 0,
        buy_limit: item.buy_limit ? item.buy_limit : null,
        is_paid: !!item.is_paid,
        is_unlimited: !!item.is_unlimited,
        show_on_page: !!item.show_on_page,
        identificator: v4(),
        lot: convertLot(item.lot)
      })),
      description: eventData.description,
      title: `${eventData.title} (Cópia)`,
      type: eventData.type,
      sale_url: v4(),
      undefined_date: eventData.undefined_date,
      image_name: eventData.image_name,
      image_path: `${process.env.REACT_APP_CDN_URL}${eventData.image_path}`,
      image_id: eventData.image_id,
      reserve_time: eventData.reserve_time,
      city: eventData.city,
      state: eventData.state,
      place: eventData.place,
      zip: eventData.zip,
      street: eventData.street,
      stream_url: eventData.stream_url,
      number: eventData.number?.toString(),
      reference: eventData.reference,
      complement: eventData.complement,
      country: eventData.country,
      district: eventData.district,
      event_dates: eventData.event_dates.map(date => {
        return {
          ...date,
          date: endDate,
          arrayId: v4(),
          end_time: eventData.end_date_default.toString(),
          start_time: startDate.toString()
        };
      }),
      helpsupport_email: eventData.helpsupport_email,
      location: eventData.location,
      id: eventData.id,
      category_id: eventData.category_id,
      category_title: eventData.category_title
    };

    return eventDataForm;
  }

  public detail(event_id: string): Rx.Observable<IEventForm> {
    return eventService
      .details<EventDetails>(event_id)
      .pipe(RxOp.map(response => this.converteEventDetailToEventForm(response)));
  }
}

const duplicateEventService = new DuplicateEventService();
export default duplicateEventService;
