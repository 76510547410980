import React, { PureComponent } from 'react';

import { TableCol, TableRow } from 'components/Shared/Table';
import { IRouteProps, WithRouter } from 'decorators/withRouter';

interface IProps extends IRouteProps {
  item: string;
  classes?: any;
  type: string;
  onComplete: (inviteKey: string, type: string) => void;
  id?: string;
}
@WithRouter()
export default class InviteListItem extends PureComponent<IProps> {
  render() {
    const { onComplete, id } = this.props;
    return (
      <TableRow key={this.props.item} onClick={() => onComplete(this.props.item, this.props.type)} id={id}>
        <TableCol xs={{ size: 6 }} sm={{ size: 6 }} type='string' value={this.props.item} />
        <TableCol xs={{ size: 8 }} sm={{ size: 8 }} type='spacing-100' value={'                           '} />
        <TableCol xs={{ size: 2 }} sm={{ size: 2 }} type='icon' value='chevron_right' />
      </TableRow>
    );
  }
}
