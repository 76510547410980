import { IImageSelectorResult } from 'components/Shared/ImageSelector';

import IFormAdapter from '@eduzz/houston-core/formAdapter';

export enum ActionTypes {
  cropperConfig = 'config/Cropper',
  cropperResetConfig = 'config/ResetCropper',
  localimage = 'image/local',
  resumeimage = 'image/resume',
  reactQuillClassNamePromation = 'className/Promation',
  reactQuillClassNameDescription = 'className/Description'
}

export interface StateProps {
  cropperConfig: CropperConfigProps;
  localimage: IImageSelectorResult;
  resumeimage: IImageSelectorResult;
  reactQuillClassNamePromation: string;
  reactQuillClassNameDescription: string;
}

export interface ActionProps {
  type: ActionTypes;
  value?: string | CropperConfigProps | IImageSelectorResult;
}

export interface CropperConfigProps {
  opened: boolean;
  width: number;
  height: number;
  onComplete: (value: IImageSelectorResult) => void;
}

export interface EventContentProps {
  form: IFormAdapter<Record<string, never>>;
}
