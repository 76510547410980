import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IEvent } from 'interfaces/event';
import { IDataFormatting, IDisplayInfo, IPrintFormatting } from 'interfaces/labelInfo';

interface IProps {
  printFormatting: IPrintFormatting;
  displayInfo: IDisplayInfo;
  dataFormatting: IDataFormatting;
  eventDetail: Partial<IEvent>;
  participantChoice: { value: string; label: string }[];
  allParticipantsAreSelected: boolean;
  totalOfParticipants: number;
}

export default class LabelSummary extends PureComponent<IProps> {
  getPrintFormattingInfo = () => {
    const printFormatting = this.props.printFormatting;
    const printFormat = printFormatting.printFormat;
    const firstLabel = printFormatting.firstLabel;

    return (
      <>
        <p>{`Formato de folha de etiquetas: ${printFormat}`}</p>
        <p>{`Posição da primeira etiqueta impressa: ${firstLabel}`}</p>
      </>
    );
  };

  getDisplayedFields = () => {
    const displayInfo = this.props.displayInfo;
    const isDisplayName = displayInfo.isDisplayName;
    const displayQR = displayInfo.displayQR;
    const displayEvent = displayInfo.displayEvent;
    const displayTicket = displayInfo.displayTicket;
    const nameDisplay =
      displayInfo.nameDisplay === 'full' ? 'Nome completo, sobrenomes abreviados' : 'Primeiro e último nome';

    return (
      <>
        {isDisplayName && <p>{nameDisplay}</p>}
        {displayQR && <p>QR Code</p>}
        {displayEvent && <p>Evento</p>}
        {displayTicket && <p>Ingresso</p>}
      </>
    );
  };

  getTextFormatting = () => {
    const dataFormatting = this.props.dataFormatting;
    const displayedInfo = this.props.displayInfo;
    const ticketSize = dataFormatting.ticketFontSize;
    const eventSize = dataFormatting.eventFontSize;
    const nameSize = dataFormatting.nameFontSize;
    const isDisplayName = displayedInfo.isDisplayName;
    const displayEvent = displayedInfo.displayEvent;
    const displayTicket = displayedInfo.displayTicket;
    const textAlign = dataFormatting.textAlign;

    const nameSizeToText = {
      3: 'Pequeno',
      4: 'Médio',
      5: 'Grande'
    };

    const textAlignToText = {
      left: 'Alinhado à esquerda',
      right: 'Alinhado à direita',
      center: 'Centralizado'
    };

    return (
      <>
        {isDisplayName && <p>{`Tamanho do nome: ${nameSizeToText[nameSize]}`}</p>}
        {displayEvent && <p>{`Tamanho do evento: ${nameSizeToText[eventSize]}`}</p>}
        {displayTicket && <p>{`Tamanho do ingresso: ${nameSizeToText[ticketSize]}`}</p>}
        <p>Alinhamento do conteúdo: {textAlignToText[textAlign]}</p>
      </>
    );
  };

  getParticipantList = () => {
    const { participantChoice } = this.props;
    const participantChoiceNames = participantChoice.map(participant => participant.label);
    return participantChoiceNames.join(', ');
  };

  getSummarizedParticipantsList = () => {
    const { participantChoice, totalOfParticipants } = this.props;
    if (totalOfParticipants > participantChoice.length) {
      const participantsName = participantChoice.map(participant => participant.label);
      participantsName.push(`...mais ${totalOfParticipants - participantsName.length} (total ${totalOfParticipants})`);
      return participantsName.join(', ');
    }

    return this.getParticipantList();
  };

  render() {
    const { allParticipantsAreSelected } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 600, fontSize: '1.3em' }}>Resumo</Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant={'subtitle1'} color={'secondary'}>
              <strong>
                {allParticipantsAreSelected ? 'Todos os participantes foram selecionados' : 'Participantes'}
              </strong>
            </Typography>

            {allParticipantsAreSelected ? this.getSummarizedParticipantsList() : this.getParticipantList()}

            <Typography variant={'subtitle1'} color={'secondary'}>
              <strong>Formato de impressão</strong>
            </Typography>

            {this.getPrintFormattingInfo()}
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant={'subtitle1'} color={'secondary'}>
              <strong>Campos exibidos</strong>
            </Typography>

            {this.getDisplayedFields()}

            <Typography variant={'subtitle1'} color={'secondary'}>
              <strong>Formatação do conteúdo</strong>
            </Typography>

            {this.getTextFormatting()}
          </Grid>
        </Grid>
      </div>
    );
  }
}
