import React, { PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { WithStyles } from 'decorators/withStyles';

import styles from './styles';

interface IProps {
  classes?: any;
  open: boolean;
  onClose: () => void;
  saleUrl: string;
}

@WithStyles(styles)
class DraftPreview extends PureComponent<IProps, {}> {
  render() {
    const { onClose, open, saleUrl, classes } = this.props;

    const url = `${process.env.REACT_APP_BLINKET_EVENT_URL}/${saleUrl}?preview=1&draft=1`;

    return (
      <Dialog fullScreen scroll='paper' open={open} onClose={onClose} aria-labelledby='responsive-dialog-title'>
        <DialogContent className={classes.draftContent}>
          <iframe
            id='event-page-preview'
            title='event-page-preview'
            src={url}
            style={{ width: '100%', height: '100%' }}
            frameBorder='0'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} id='myevents-draftpreview-close-button'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DraftPreview;
