import * as coreOperators from 'rxjs/operators';

import { bindComponent } from './bindComponent';
import { loader } from './loader';
import { logError } from './logError';
import { tapSubscribe } from './tapSubscrible';

export * from 'rxjs/operators';
export * from './bindComponent';
export * from './loader';
export * from './logError';

const RxOp = {
  ...coreOperators,
  bindComponent,
  loader,
  logError,
  tapSubscribe
};

export default RxOp;
