import { AppStyle } from 'decorators/withStyles';

const styles: AppStyle = theme => ({
  buttonNext: {
    background: theme.variables.mainColor,
    color: '#FFFFFF',
    width: 140
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%'
  },

  finished: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '2em'
  },

  iconFinished: {
    color: 'green',
    fontSize: '8em'
  }
});

export default styles;
