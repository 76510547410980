import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';
import { IProps } from './types';

const LabelFieldsForm: React.FC<IProps> = ({ classes, data, onChangeDisplayInfo, onChangeNameDisplay }) => {
  return (
    <>
      <Typography className={classes.labelStrong}>Escolha como os nomes aparecerão na etiqueta</Typography>

      <FormControlLabel
        className={classes.formControlLabel}
        label='Exibir nome'
        control={
          <Checkbox
            onChange={(e: any) => onChangeDisplayInfo(e.target.value)}
            value='isDisplayName'
            color='primary'
            checked={data.isDisplayName}
          />
        }
      />

      {data.isDisplayName && (
        <RadioGroup
          onChange={(e: any) => onChangeNameDisplay(e.target.value)}
          value={data.nameDisplay}
          className={classes.radioGroup}
        >
          <FormControlLabel
            control={<Radio value='full' color='primary' checked={data.nameDisplay === 'full'} />}
            label='Nome completo, sobrenomes do meio abreviados'
          />
          <FormControlLabel
            control={<Radio value='first-last' color='primary' checked={data.nameDisplay === 'first-last'} />}
            label='Primeiro e último nome'
          />
        </RadioGroup>
      )}

      <Typography className={classes.labelStrong}>
        Escolha os campos adicionais que deseja inserir na etiqueta
      </Typography>

      <div className={classes.checkList}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e: any) => onChangeDisplayInfo(e.target.value)}
              value='displayEvent'
              color='primary'
              checked={data.displayEvent}
            />
          }
          label='Evento'
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(e: any) => onChangeDisplayInfo(e.target.value)}
              value='displayQR'
              color='primary'
              checked={data.displayQR}
            />
          }
          label='QR Code'
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(e: any) => onChangeDisplayInfo(e.target.value)}
              value='displayTicket'
              color='primary'
              checked={data.displayTicket}
            />
          }
          label='Ingresso'
        />
      </div>
    </>
  );
};

export default withStyles(styles)(LabelFieldsForm);
