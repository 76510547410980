import { AppStyle } from 'decorators/withStyles';
import { setMargin } from 'helpers/functions';

const styles: AppStyle = theme => ({
  root: { overflow: 'auto' },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: { color: theme.palette.primary.main },
  cardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    '&:last-child': { paddingBottom: 12 }
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      ...setMargin(null, null, null, 8)
    }
  }
});

export default styles;
