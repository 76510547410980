import { useContext } from 'react';

import Toast from 'components/Shared/Toast';
import { isEmpty } from 'helpers/lodash';

import FormContext from '../../Context';
import { StepButton } from '../index';
import * as S from './styles';

const StepButtonsGroup = () => {
  const { stepsEnables, currentStep, setCurrentStep, steps, isCreate } = useContext(FormContext);

  const lastItem = steps[steps.length - 1];

  const setTheStep = (key: typeof steps[0]['key']) => {
    if (!isEmpty(window['formErrors'])) {
      Toast.error('Verifique todos os campos');
      return;
    }
    setCurrentStep(key);
  };
  return (
    <S.Wrapper>
      {steps.map(step => (
        <StepButton
          key={step.key}
          onClick={() => setTheStep(step.key)}
          isEnable={!isCreate || stepsEnables.includes(step.key)}
          step={step.key}
          title={step.title}
          active={currentStep === step.key}
          itsLast={lastItem.key === step.key}
        />
      ))}
    </S.Wrapper>
  );
};

export default StepButtonsGroup;
