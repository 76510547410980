import React from 'react';

import CalendarOutlined from '@ant-design/icons/CalendarOutlined';

import * as S from './styles';
import { EventHeaderProps } from './types';

const EventHeader = ({ image, title, date, ...props }: EventHeaderProps) => {
  return (
    <S.Container className={props.className}>
      <div className='event-header-infos'>
        <img src={image} alt={`Imagem do evento ${title}`} />
        <div className='event-header-description'>
          <h2>{title}</h2>
          <div className='event-header-date'>
            <CalendarOutlined />
            <p>{date.start}</p>
            <p className='event-header-date-end'>{`a ${date.end}`}</p>
          </div>
        </div>
      </div>
    </S.Container>
  );
};

export default EventHeader;
