import React from 'react';

import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import { Button, List, Typography } from 'antd';

import * as S from './styles';

const TemplateStep = () => {
  return (
    <React.Fragment>
      <Typography.Text strong>
        Utilize nossos templates para fazer o upload de sua lista de participante.
      </Typography.Text>
      <List>
        <List.Item
          key={'template-csv'}
          actions={[
            <Button
              key={'template-csv-action'}
              type={'link'}
              href='https://cdn.eduzzcdn.com/blinket/csv-example.csv'
              target='_blank'
              icon={<DownloadOutlined />}
              style={{ color: 'black' }}
            />
          ]}
        >
          <Typography.Text key={'template-csv-text'}>
            <FileTextOutlined key={'template-csv-icon'} style={{ marginRight: '5px' }} />
            Template em .CSV
          </Typography.Text>
        </List.Item>
        <List.Item
          key={'template-xlsx'}
          actions={[
            <Button
              key={'template-xlsx-action'}
              href='https://cdn.eduzzcdn.com/blinket/xlsx-example.xlsx'
              type={'link'}
              icon={<DownloadOutlined />}
              style={{ color: 'black' }}
            />
          ]}
        >
          <Typography.Text key={'template-xlsx-text'}>
            <FileTextOutlined key={'template-xlsx-text'} style={{ marginRight: '5px' }} />
            Template em .XLSX
          </Typography.Text>
        </List.Item>
      </List>
      <Typography.Link
        href='https://ajuda.eduzz.com/hc/pt-br/articles/4408565955867-Como-fa%C3%A7o-para-adicionar-os-participantes-do-meu-evento-Blinket'
        target='_blank'
        underline
        style={{ color: 'black' }}
      >
        Sobre templates de planilhas{' '}
      </Typography.Link>
      <ArrowRightOutlined />
      <S.ListOptionalFields>
        Campos opcionais no caso de importação via planilha:
        <S.ListItemOptionalFields>
          Telefone (Se não informado, o webhook também o informará vazio).
        </S.ListItemOptionalFields>
      </S.ListOptionalFields>
    </React.Fragment>
  );
};

export default TemplateStep;
