import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Dialog, Grid } from '@mui/material';
import { Button } from 'antd';
import { compareAsc, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { DateDetail } from 'interfaces/event';

import * as S from './styles';

interface EventDatesModalProps {
  eventDates: DateDetail[];
  open: boolean;
  onClose: () => void;
}
const EventDatesModal = ({ eventDates, open, onClose }: EventDatesModalProps) => {
  const eventDatedOrdered = (): DateDetail[] => {
    const orderFields = [...eventDates];

    orderFields.sort((a, b) => compareAsc(a.date, b.date));

    return orderFields;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '440px',
          width: '440px'
        }
      }}
    >
      <S.CustomizedDialogTitle>
        <S.Title variant='h6'>Datas e horários</S.Title>
        <Button type='text' onClick={onClose} icon={<CloseOutlined />} />
      </S.CustomizedDialogTitle>
      <S.CustomizedDialogContent>
        <Grid container spacing={3}>
          {eventDatedOrdered().map(eventDate => {
            return (
              <Grid container item spacing={3} key={eventDate.identificator}>
                <Grid container item xs={6}>
                  <CalendarOutlined style={{ marginRight: '6px' }} />
                  <S.CustomizedTypography>
                    {format(eventDate.date, 'dd')} de {format(eventDate.date, 'MMMM', { locale: ptBR })}
                  </S.CustomizedTypography>
                </Grid>
                <Grid container item xs={6}>
                  <ClockCircleOutlined style={{ marginRight: '6px' }} />
                  <S.CustomizedTypography>
                    {format(new Date(eventDate.start_time), 'HH:mm')} às {format(new Date(eventDate.end_time), 'HH:mm')}{' '}
                  </S.CustomizedTypography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </S.CustomizedDialogContent>
    </Dialog>
  );
};

export default EventDatesModal;
