import React from 'react';

import { Col, Form, Row, Select } from 'antd';
import { Typography } from 'components/Shared/Antd';
import Field from 'components/Shared/Field';
import { useFormContext } from 'react-hook-form';

import { EventDetailContext } from '../../..';

const TicketStep = () => {
  const { eventDetail, getAvailableStock, getCurrentTicketName } = React.useContext(EventDetailContext);

  const form = useFormContext();

  const options = eventDetail?.tickets?.map(ticket => ({
    value: ticket.id,
    label: ticket.name
  }));

  const ticketSelected = form.watch('ticket_id');

  const availableStock = getAvailableStock(ticketSelected);
  const currentTicket = getCurrentTicketName(ticketSelected);

  return (
    <Form>
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          <Typography.Text>
            Os participantes serão vinculados a categoria de ingresso selecionada abaixo.
          </Typography.Text>
        </Col>
        <Col md={24} xs={24}>
          <Typography.Text>Categoria de Ingresso</Typography.Text>
          <Field
            name='ticket_id'
            key='ticket-type-input-multiple-participant'
            render={
              <Select
                defaultValue={ticketSelected}
                style={{ display: 'block' }}
                getPopupContainer={node => node.parentNode}
                options={options}
              />
            }
          />
        </Col>
        <Col md={24} xs={24}>
          <Typography.Text>
            Seu evento possui <b>{availableStock}</b> ingressos na categoria <b>{currentTicket}</b> disponíveis em
            estoque.
          </Typography.Text>
        </Col>
        <Col md={24} xs={24}>
          <Typography.Text>
            Participantes adicionados manualmente receberão ingressos do <b>lote vigente</b> da categoria de ingresso
            selecionada.
          </Typography.Text>
        </Col>
      </Row>
    </Form>
  );
};

export default TicketStep;
