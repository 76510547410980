import styled from 'styled-components';

export const Container = styled.div`
  max-height: 150px;
  overflow-y: auto;
  position: relative;
  background: rgba(0, 0, 0, 0.04);
  padding: 10px;
  margin-bottom: 20px;
`;
