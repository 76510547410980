import * as React from 'react';

import { ColorResult, SketchPicker } from 'react-color';
import { fromEvent, Subject } from 'rxjs';
import rxjsOperators from 'rxjs-operators';

import Toast from '../Toast';
import { IProps, IState } from './types';

export default class ColorPicker extends React.PureComponent<IProps, IState> {
  private static color$: Subject<ColorResult>;
  private static ref: ColorPicker = null;

  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      color: '#000000',
      node: null
    };

    ColorPicker.ref = this;

    fromEvent(document, 'mousedown')
      .pipe(rxjsOperators.filter(() => !!(this.state && this.state.node)))
      .subscribe(
        event => {
          if (!this.state.node.contains(event.target)) {
            this.close();
          }
        },
        ({ data: err }) => {
          if (err.message === 'Você não possui permissão para realizar esta ação') {
            Toast.error(err.message, 6000);
          }
        }
      );
  }

  public static getColor(event: MouseEvent, color: string) {
    ColorPicker.color$ = new Subject<ColorResult>();

    let x = event.pageX + 5,
      y = event.pageY + 5;

    if (y + 250 > window.innerHeight) {
      y = window.innerHeight - 250;
    }

    if (x + 250 > window.innerWidth) {
      x = window.innerWidth - 250;
    }

    ColorPicker.ref.setState({
      show: true,
      color,
      position: { x, y }
    });

    return ColorPicker.color$.asObservable();
  }

  selectColor = (color: ColorResult) => {
    ColorPicker.color$.next(color);
  };

  selectColorComplete = (color: ColorResult) => {
    this.selectColor(color);
  };

  close = () => {
    ColorPicker.color$.complete();
    this.setState({ show: false });
  };

  handleSetRef = (node: any) => {
    this.setState({ node });
  };

  render() {
    if (!this.state || !this.state.show) {
      return null;
    }

    const p = this.state.position;

    return (
      <div
        ref={node => this.handleSetRef(node)}
        style={{
          top: p.y,
          left: p.x,
          position: 'absolute',
          zIndex: 10000
        }}
      >
        <SketchPicker
          color={this.state.color}
          onChange={this.selectColor}
          onChangeComplete={this.selectColorComplete}
          disableAlpha={true}
          presetColors={[]}
        />
      </div>
    );
  }
}
