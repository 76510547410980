import React, { useEffect, useState } from 'react';

import { MailOutlined, PushpinOutlined, ReloadOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Typography } from 'antd';
import { ArrowIcon } from 'components/Shared/Icons';
import { TicketIcon } from 'helpers/customIcons';
import { hjTagging } from 'helpers/functions';

import PresenceFilterAdvanced from './FilterAdvanced';
import { SelectedOptType } from './FilterAdvanced/types';
import styles from './styles';
import { IProps } from './types';

const ToolbarPresence = ({
  classes,
  tags,
  eventDetail,
  eventIdSelected,
  user,
  handleUpdateParentState,
  filter,
  handleAddFilter,
  selectedParticipants,
  loadData,
  refreshData,
  handleSetFilterSelected,
  handleSubmitFilter,
  handleOpenModalResendTicket,
  handleOpenModalLink,
  handleOpenModalLabelManager,
  handleOpenModalSendEmailToParticipants,
  disableLabelManager
}: IProps) => {
  const [isOpenAdvanced, setIsOpenAdvanced] = useState(false);
  const [hasSelectedParticipants, setHasSelectedParticipants] = useState(false);
  const [timeoutSearch, setTimeoutSearch] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAttendees, setSelectedAttendees] = useState(null);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const buttonOrderByRef = React.useRef<HTMLButtonElement>(null);
  const [isOpenOrderByMenu, setIsOpenOrderByMenu] = React.useState(false);

  const [orderByValue, setOrderByValue] = React.useState({
    text: 'Nº Ingresso (a-z)',
    value: 'invite_key asc',
    icon: <ArrowIcon />
  });

  useEffect(() => {
    setHasSelectedParticipants(!!selectedParticipants.length);
  }, [selectedParticipants]);

  const selectTagsHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedTags(selectedOpt);
    const allTagsValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, tags: allTagsValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allTagsValues, 'tags'));
  };

  const selectTicketsHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedTickets(selectedOpt);
    const allTicketValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, tickets: allTicketValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allTicketValues, 'tickets'));
  };

  const selectStatusHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedStatus(selectedOpt);
    const allStatusValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, status: allStatusValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allStatusValues, 'status'));
  };

  const selectAttendeesHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedAttendees(selectedOpt);
    const allAttendeesValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, attendance_situation: allAttendeesValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allAttendeesValues, 'attendance_situation'));
  };

  const cleanFilter = () => {
    setSelectedTags(null);
    setSelectedTickets(null);
    setSelectedStatus(null);
    setSelectedAttendees(null);
    handleSetFilterSelected([
      { type: 'search', value: '' },
      { type: 'status', value: 'all' },
      { type: 'ticket_id', value: 'all' },
      { type: 'tags', value: 'all' }
    ]);

    //não pode resetar a ordenação, é uma coisa a parte
    if (filter?.order) {
      handleUpdateParentState({ filter: { search: filter.search, order: filter.order } });
      return;
    }

    handleUpdateParentState({ filter: { search: filter.search } });
  };

  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;

    hjTagging('page:presencelist-detail-filter');
    e.persist();
    filter[name] = value;

    handleUpdateParentState({ filter }, () => handleAddFilter(value, name));

    if (name === 'search' && (value.length >= 3 || value.length === 0)) {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
      setTimeoutSearch({
        timeoutSearch: setTimeout(() => {
          handleSubmitFilter();
        }, 2000)
      });
    }
  };

  const handleClickTooltip = () => {
    hjTagging('page:presencelist-detail-filter');
    setIsOpenAdvanced(!isOpenAdvanced);
  };

  const handleRefreshData = () => {
    if (selectAllIsChecked) {
      setSelectAllIsChecked(false);
    }
    refreshData();
  };

  const options = [
    { text: 'Participante (a-z)', value: 'name asc', icon: <ArrowIcon /> },
    { text: 'Participante (z-a)', value: 'name desc', icon: <ArrowIcon style={{ transform: 'scaleY(-1)' }} /> },
    { text: 'Nº Ingresso (a-z)', value: 'invite_key asc', icon: <ArrowIcon /> },
    { text: 'Nº Ingresso (z-a)', value: 'invite_key desc', icon: <ArrowIcon style={{ transform: 'scaleY(-1)' }} /> },
    { text: 'Comprador (a-z)', value: 'invoice_customer_name asc', icon: <ArrowIcon /> },
    {
      text: 'Comprador (z-a)',
      value: 'invoice_customer_name desc',
      icon: <ArrowIcon style={{ transform: 'scaleY(-1)' }} />
    }
  ] as const;

  type optionsType = typeof options[number];

  const setTheOrderByValue = (option: optionsType) => () => {
    setOrderByValue(option);
    setIsOpenOrderByMenu(false);
    loadData({ ...filter, order: option.value });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.toolbar}>
            <div className={classes.iconButtons}>
              <Tooltip title='Reenviar ingresso'>
                <span>
                  <Button
                    type='text'
                    disabled={!hasSelectedParticipants}
                    id={'btn-resend-ticket-multiple'}
                    onClick={() => handleOpenModalResendTicket(true, null, true)}
                    icon={TicketIcon()}
                  />
                </span>
              </Tooltip>

              <Tooltip title='Adicionar marcador'>
                <span>
                  <Button
                    type='text'
                    disabled={!hasSelectedParticipants}
                    id={'btn-marker-multiple'}
                    onClick={() => {
                      if (selectedParticipants.length > 1) {
                        handleOpenModalLink(true, null, true);
                      } else {
                        handleOpenModalLink(true, selectedParticipants[0], false);
                      }
                    }}
                    icon={<PushpinOutlined />}
                  />
                </span>
              </Tooltip>

              <Tooltip title='Gerar etiqueta'>
                <span>
                  <Button
                    type='text'
                    disabled={!hasSelectedParticipants || disableLabelManager}
                    id={'btn-label-multiple'}
                    onClick={() => handleOpenModalLabelManager(true, selectedParticipants)}
                    icon={<TagOutlined />}
                  />
                </span>
              </Tooltip>

              {hasSelectedParticipants && handleOpenModalSendEmailToParticipants && (
                <Tooltip title='Enviar e-mail'>
                  <Button
                    type='text'
                    id={'btn-label-send-email'}
                    onClick={() => handleOpenModalSendEmailToParticipants()}
                    icon={<MailOutlined />}
                  />
                </Tooltip>
              )}
            </div>

            <div className={classes.actionButtonsContainer}>
              <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setIsOpenOrderByMenu(false)}
                open={isOpenOrderByMenu}
                anchorEl={buttonOrderByRef.current}
                getContentAnchorEl={null}
              >
                {options.map(option => (
                  <MenuItem key={option.text} onClick={setTheOrderByValue(option)}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                      {option.icon}
                      <Typography.Text>{option.text}</Typography.Text>
                    </div>
                  </MenuItem>
                ))}
              </Menu>

              <Button
                ref={buttonOrderByRef}
                id='order-by-btn'
                onClick={() => setIsOpenOrderByMenu(true)}
                icon={orderByValue.icon}
              >
                <Typography.Text>{orderByValue.text}</Typography.Text>
              </Button>

              <Button id={'btn-refresh-list'} icon={<ReloadOutlined />} onClick={handleRefreshData}>
                <Typography.Text>Atualizar</Typography.Text>
              </Button>

              <Tooltip title='Filtro avançado'>
                <Button
                  onClick={handleClickTooltip}
                  id='advanced-filter-btn'
                  type={isOpenAdvanced ? 'primary' : 'default'}
                  {...(isOpenAdvanced && { color: 'primary' })}
                  icon={<SearchOutlined />}
                >
                  <Typography.Text style={isOpenAdvanced ? { color: 'black' } : null}>Filtrar</Typography.Text>
                </Button>
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <PresenceFilterAdvanced
          tags={tags}
          tickets={eventDetail.tickets || []}
          loadData={loadData}
          filter={{ ...filter }}
          eventIdSelected={eventIdSelected}
          user={user}
          isOpen={isOpenAdvanced}
          handleChangeFilter={handleChangeFilter}
          selectTagsHandleChange={selectTagsHandleChange}
          selectTicketsHandleChange={selectTicketsHandleChange}
          selectStatusHandleChange={selectStatusHandleChange}
          selectAttendeesHandleChange={selectAttendeesHandleChange}
          cleanFilter={cleanFilter}
          submitFilter={handleSubmitFilter}
          selectedTags={selectedTags}
          selectedTickets={selectedTickets}
          selectedStatus={selectedStatus}
          selectedAttendees={selectedAttendees}
        />
      </Grid>
    </>
  );
};

export default withStyles(styles)(ToolbarPresence);
