import React from 'react';

import { withStyles } from '@material-ui/core';
import TelescopeCard from 'components/Pages/Admin/MyEvents/Dashboard/TelescopeCard';
import { GroupUsersIcon, RefundIcon, TicketIcon } from 'helpers/customIcons';

import HintsCard from './HintsCard';
import Indicator from './Indicator';
import styles from './styles';
import { IProps } from './types';

const tooltipTicketsDetail = 'Detalhes dos ingressos consiste nos detalhes das vendas e adições manuais de cada lote';
const tooltipTicketsSummary = 'Ingressos totais consiste na soma dos ingressos vendidos e adicionados manualmente';
const tooltipRefundSummary = 'Os dados de solicitações e reembolso são baseados apenas nas vendas já confirmadas.';

function IndicatorCards({ classes, statistics, event }: IProps) {
  const { refundsSummary, ticketsSummary, ticketsDetail } = statistics;

  const refundSummaryData = [
    { name: 'solicitações pendentes', value: refundsSummary.locked },
    { name: 'reembolsos realizados', value: refundsSummary.refunded }
  ];

  const ticketsSummaryData = [
    {
      name: 'Ingressos totais',
      value: ticketsSummary.attendancesTotal,
      items: [
        {
          name: 'Ingressos vendidos (' + ticketsSummary.totalInvoices + ' faturas)',
          value: ticketsSummary.attendancesOfInvoices
        },
        {
          name: 'Ingressos adicionados manualmente',
          value: ticketsSummary.attendancesOfManual
        }
      ]
    },
    { name: 'Ingressos disponíveis', value: ticketsSummary.ticketsTotalAvailable }
  ];

  const ticketsDetailData = [
    ...ticketsDetail.map((item: any) => {
      const items = item.lots.map(lotItem => {
        return {
          name: lotItem.lotName,
          value: lotItem.attendances
        };
      });
      return { name: item.ticketName.toUpperCase() + ' Total', value: item.attendances, items: items };
    })
  ];

  return (
    <div className={classes.root}>
      <Indicator
        key={'indicator-card-tickets'}
        title='Ingressos'
        icon={GroupUsersIcon}
        tooltipText={tooltipTicketsSummary}
        data={ticketsSummaryData}
        event={event}
      />

      <Indicator
        key={'indicator-card-refounded-tickets'}
        title='Reembolsos'
        icon={RefundIcon}
        tooltipText={tooltipRefundSummary}
        data={refundSummaryData}
      />

      {/* 2 rows */}
      <Indicator
        key={'indicator-card-ticket-details'}
        title='Detalhes dos ingressos'
        icon={TicketIcon}
        tooltipText={tooltipTicketsDetail}
        data={ticketsDetailData}
        event={event}
      />

      <HintsCard event={event} />

      <TelescopeCard />
    </div>
  );
}

export default withStyles(styles)(IndicatorCards);
