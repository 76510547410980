import { CSSProperties } from 'react';

import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div``;

export const DescriptionEventTitle = styled(Typography)`
  color: #000000;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
`;

export const CustomizedTypography = styled(Typography)<CSSProperties>`
  align-items: ${props => (props.alignItems ? props.alignItems : null)};
  color: ${props => (props.color ? props.color : null)};
  cursor: ${props => (props.cursor ? props.cursor : null)};
  display: ${props => (props.display ? props.display : null)};
  font-size: ${props => (props.fontSize ? props.fontSize : null)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : null)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : null)};
  margin-right: ${props => (props.marginRight ? props.marginRight : null)};
  word-break: break-all;
`;

export const DetailsButton = styled(Button)`
  color: #000000;
  font-size: 0.8em;
  font-weight: 600;
  margin-top: -3px;
  padding: 0;
`;

export const TicketAndLotsTitle = styled(Typography)`
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
`;
export const Title = styled.h6`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;

  ${media.greaterThan('medium')`
    font-size: 1.25rem;
  `}
`;

export const Subtitle = styled.h6`
  color: #595959;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 10px;
  ${media.greaterThan('medium')`
  font-size: 1rem;
`}
`;
