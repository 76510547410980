import { useContext } from 'react';

import { Tabs } from 'antd';
import Toast from 'components/Shared/Toast';
import { isEmpty } from 'lodash';
import FormContext from 'Pages/Events/Context';
import { Steps } from 'Pages/Events/Context/types';

const EditEventTabs = () => {
  const { currentStep, setCurrentStep, steps } = useContext(FormContext);

  const handleChangeTab = (step: Steps) => {
    if (!isEmpty(window['formErrors'])) {
      Toast.error('Verifique todos os campos');
      return;
    }
    setCurrentStep(step);
  };

  return (
    <Tabs
      activeKey={currentStep}
      onTabClick={value => handleChangeTab(value as Steps)}
      items={steps.map(step => ({
        label: step.title,
        key: step.key
      }))}
    ></Tabs>
  );
};

export default EditEventTabs;
