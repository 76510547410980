import { green } from '@material-ui/core/colors';
import grey from '@material-ui/core/colors/grey';
import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem, setMargin, setPadding } from 'helpers/functions';

const styles: AppStyle = theme => ({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 4px',

    '& thead': {
      '& th': {
        fontSize: fontSizePxToRem(13),
        textTransform: 'uppercase',
        color: theme.palette.text.secondary,
        textAlign: 'left',
        padding: '0 16px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '&:nth-child(2)': {
          ...setPadding(null, null, null, 0)
        },

        '&.--numeric': {
          textAlign: 'right'
        },

        '&.--status, .--moderation': {
          width: 16
        }
      }
    },

    '& tbody': {
      '& tr': {
        background: grey[100],

        '&.--dbclick': {
          cursor: 'pointer',

          '&:hover': {
            background: grey[200]
          }
        },

        '& td': {
          minHeight: 50,
          padding: '8px 16px',
          fontSize: fontSizePxToRem(14),
          color: grey[900],
          position: 'relative',

          '&:first-child': {
            borderRadius: '4px 0 0 4px'
          },

          '&:nth-child(2)': {
            ...setPadding(null, null, null, 0)
          },

          '&:last-child': {
            borderRadius: '0 4px 4px 0'
          },

          '&.--image-col': {
            width: 75
          },

          // status
          '& .status, .moderation': {
            width: 8,
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: grey[400],
            borderRadius: '4px 0 0 4px',

            '&.--active': {
              background: green[500]
            }
          },

          // string
          '& .string': {
            '& a': {
              color: theme.palette.primary.main,
              transition: '0.2s',

              '&:hover': {
                color: theme.palette.primary.dark
              }
            }
          },

          // copy
          '& .copy': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',

            '& .iconCopy': {
              ...setMargin(null, null, null, 8),

              '& span.material-icons': {
                fontSize: fontSizePxToRem(15)
              }
            }
          },

          // moderation
          '& .moderation': {},

          // image
          '& .image': {
            width: 50,
            height: 50,
            background: grey[400],
            borderRadius: 4,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          },

          //type-icon
          '& .type-with-icon': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',

            '& .iconSVG': {
              ...setMargin(null, null, null, 8),
              lineHeight: 0,

              '& img': {
                width: 18
              }
            }
          },

          //icon
          '& .icon': {
            width: 30,
            height: 30,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          },

          // number
          '& .number': {
            textAlign: 'right'
          },

          // not found
          '& .not-found': {
            textAlign: 'center',
            padding: 24
          },

          // action
          '& .action': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: 'auto',

            '& span.material-icons': {
              fontSize: fontSizePxToRem(38),
              cursor: 'pointer',
              width: 38
            }
          }
        }
      }
    }
  }
});

export default styles;
