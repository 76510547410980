import * as S from './styles';
import { StepButtonProps } from './types';

const StepButton = ({ step, title, ...props }: StepButtonProps) => {
  return (
    <S.Wrapper {...props}>
      <S.Step>{step}</S.Step>
      <S.WrapperTitles>
        <h2>{title}</h2>
      </S.WrapperTitles>
      <S.Line />
    </S.Wrapper>
  );
};

export default StepButton;
