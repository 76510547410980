import * as rxjs from 'rxjs';
import rxjsOperators from 'rxjs-operators';

import tokenService, { TokenService } from './token';
import userService from './user';
import { User } from './user/types';

export class AuthService {
  private user$: rxjs.Observable<User>;
  private typeUser$: rxjs.BehaviorSubject<number>;
  private userPhoto$: rxjs.BehaviorSubject<string> = new rxjs.BehaviorSubject('');
  private isClubeBlack$: rxjs.BehaviorSubject<boolean> = new rxjs.BehaviorSubject(false);

  constructor(private tokenService: TokenService) {
    this.typeUser$ = new rxjs.BehaviorSubject(0);

    this.user$ = this.tokenService.getAccessToken().pipe(
      rxjsOperators.map(token => {
        if (!token) {
          console.log('token not found');
          return null;
        }

        const user = this.tokenService.decode<User>(token);

        if (!user) {
          console.log('invalid token user');
          return null;
        }

        this.setClubeBlack(user.is_clube_black);
        userService.setUser(user);
        return user;
      }),
      rxjsOperators.catchError(() => {
        console.log('failed to observe user');
        return rxjs.of(null);
      })
    );
  }

  public logout() {
    this.tokenService.clearToken();
  }

  public getUser() {
    return this.user$;
  }

  public setUserPhoto(photo: string) {
    return this.userPhoto$.next(photo);
  }

  public getUserPhoto() {
    return this.userPhoto$.asObservable();
  }

  public isClubeBlack() {
    return this.isClubeBlack$.asObservable();
  }

  public isAuthenticated(): rxjs.Observable<boolean> {
    return this.tokenService.getAccessToken().pipe(rxjsOperators.map(token => !!token));
  }

  public getTypeUser() {
    return this.typeUser$.asObservable();
  }

  private setClubeBlack(isClubeBlack: boolean) {
    return this.isClubeBlack$.next(isClubeBlack);
  }
}

const authService = new AuthService(tokenService);
export default authService;
