import { formatUrlWithUtm } from 'helpers/functions';
import { BLINKET_TICKETS_APP_URL } from 'settings';

import { MyTicketsRoutes } from './types';

const myTicketsRoutes: MyTicketsRoutes[] = [
  {
    path: '/my-tickets',
    import: formatUrlWithUtm(BLINKET_TICKETS_APP_URL, 'blinket', 'produtor')
  }
];

export default myTicketsRoutes;
