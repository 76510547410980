import { AppStyle } from 'decorators/withStyles';
import { setMargin } from 'helpers/functions';

const styles: AppStyle = () => ({
  row: {
    cursor: 'pointer',
    background: '#fff !important',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    padding: 10,
    borderRadius: 4,
    ...setMargin(null, null, 4, null),
    minHeight: 48,
    position: 'relative',
    '&:hover': {
      background: '#eeeeee'
    }
  },

  tableSmall: {
    cursor: 'pointer',
    background: '#fff !important',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    padding: 10,
    borderRadius: 4,
    minHeight: 48,
    '&:hover': {
      background: '#eeeeee'
    }
  }
});

export default styles;
