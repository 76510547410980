import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import userService from 'services/user';
import { User } from 'services/user/types';

import useCustomObservable from './useCustomObservable';

const useTabTour = (pageLink: string) => {
  const [showTour, setShowTour] = useState(false);
  const { pathname, search } = useLocation();
  const { value: userData } = useCustomObservable<User, string, string>(() => userService.getUser(), null);

  useEffect(() => {
    if (userData?.tours && userData?.tours.includes(pageLink)) {
      return;
    }
    const hasKey = hasSessionKey(pageLink);
    if (hasKey) {
      return;
    }

    const currentPage = pathname.split('/')[3];
    if (currentPage !== pageLink) {
      return;
    }

    const query = new URLSearchParams(search);
    let hasQuery = false;
    if (!query.has('showTabTour')) {
      return;
    }

    hasQuery = query.get('showTabTour') === 'true';
    if (hasQuery) {
      setShowTour(true);
    }
  }, [pageLink, pathname, search, userData?.tours]);

  const closeTour = () => {
    handleSessionStorage(pageLink);
    userService
      .hasUsedTour({
        name: pageLink,
        dismissed: true,
        completed: true
      })
      .subscribe();

    setShowTour(false);
  };

  const openTour = () => {
    setShowTour(true);
  };

  return { showTour, openTour, closeTour };
};

const hasSessionKey = (pageLink: string) => {
  const session = sessionStorage.getItem('dontShowTabTour');
  if (!session) {
    return false;
  }
  const showedTours: { [x: string]: boolean } = JSON.parse(session);
  if (!Object.keys(showedTours).includes(pageLink)) {
    return false;
  }
  return true;
};

const handleSessionStorage = (pageLink: string) => {
  const session = sessionStorage.getItem('dontShowTabTour');
  if (!session) {
    sessionStorage.setItem('dontShowTabTour', JSON.stringify({ [pageLink]: true }));
  }
  const showedTours = JSON.parse(session);
  const newShowedTours = JSON.stringify({
    ...showedTours,
    [pageLink]: true
  });
  sessionStorage.setItem('dontShowTabTour', newShowedTours);
};

export default useTabTour;
