import React, { useMemo } from 'react';

import PageLoader from 'components/Shared/PageLoader';
import { useCustomObservable } from 'hooks';
import { Redirect, Route } from 'react-router-dom';
import tokenService, { IAuth } from 'services/token/index';
import userService from 'services/user/index';
import { User } from 'services/user/types';
import { Roles } from 'types/roles';

const Gateway = ({ component: Component, role, attendanceProps, ...rest }: any) => {
  const { value: userData } = useCustomObservable<User, string, string>(() => userService.getUser(), null);
  const { value: storedToken } = useCustomObservable<IAuth, string, string>(() => tokenService.getTokens(), null);

  const haveNoToken = useMemo(() => !storedToken, [storedToken]);
  const isProducer = useMemo(() => role === Roles.producer && !!userData?.is_producer, [role, userData]);

  if (haveNoToken) {
    return <PageLoader />;
  }

  if (!isProducer) {
    <Redirect to='/' />;
  }

  return (
    <Route
      {...rest}
      render={props => <Component attendanceProps={attendanceProps} classes={rest.classes} {...props} />}
    />
  );
};
export default Gateway;
