import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Radio, Space } from 'components/Shared/Antd';
import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';

import * as S from './styles';
import { ModalNotifyEditProps } from './types';

const ModalNotifyEdit = ({
  onCancel,
  onContinue,
  optionSelectNotifyAttendancesDialog,
  handleRadioOption,
  ...props
}: ModalNotifyEditProps) => {
  return (
    <S.Modal {...props}>
      <DialogTitle>
        <h4>Notificar participantes de alterações</h4>
      </DialogTitle>

      <DialogContent>
        <p className='modal-notify-content'>
          A Blinket envia um email informando as alterações realizadas para todos os participantes que já adquiriram
          ingressos deste evento.
        </p>
        <p className='modal-notify-custom-p'>O que você deseja fazer?</p>
        <div className='modal-notify-container-radio'>
          <Radio.Group onChange={e => handleRadioOption(parseInt(e.target.value))} defaultValue={1}>
            <Space direction='vertical'>
              <Radio value={1} checked={optionSelectNotifyAttendancesDialog === 1}>
                Notificar participantes
              </Radio>
              <Radio value={3} checked={optionSelectNotifyAttendancesDialog === 3}>
                Não notificar participantes
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </DialogContent>

      <DialogActions>
        <CustomButtonAntd onClick={onCancel} id='modalnotify-back-btn'>
          Voltar
        </CustomButtonAntd>

        <Button
          onClick={() => {
            onContinue();
            onCancel();
          }}
          type='primary'
          id='modalnotify-continue-btn'
        >
          Continuar
        </Button>
      </DialogActions>
    </S.Modal>
  );
};

export default ModalNotifyEdit;
