import { useCustomObservable } from 'hooks';
import { IEventForm } from 'interfaces/event';
import { useParams } from 'react-router-dom';

import { Form, StepButtonsGroup } from '../components';
import { FormContextProvider } from '../Context';
import duplicateEventService from './service';
import * as S from './styles';
import { PageParams } from './types';

const DuplicateEvent = () => {
  const { id: eventId } = useParams<PageParams>();

  const { value: eventData, loading } = useCustomObservable<IEventForm, void, void>(
    () => duplicateEventService.detail(eventId),
    null
  );

  return (
    <FormContextProvider isCreate>
      <S.Wrapper>
        <StepButtonsGroup />
        {!loading && <Form defaultValue={eventData} />}
      </S.Wrapper>
    </FormContextProvider>
  );
};
export default DuplicateEvent;
