import { ITicketForm, ILoteForm, DateDetail } from 'interfaces/event';
import { v4 } from 'uuid';

export const ticketLotDefault: ILoteForm = {
  identificator: v4(),
  quantity: undefined,
  quantity_sold: 0,
  quantity_available: null,
  value: 0,
  dateStart: null,
  dateEnd: null,
  is_active: 1,
  id: ''
};

export const ticketDefault: ITicketForm = {
  name: '',
  identificator: v4(),
  is_active: 1,
  is_paid: true,
  is_unlimited: false,
  buy_limit: null,
  buy_minimum: 1,
  show_on_page: true,
  lot: [
    {
      ...ticketLotDefault
    }
  ]
};

export const eventDatesDefault: DateDetail = {
  id: '',
  date: new Date(),
  start_time: '',
  end_time: ''
};
