import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles: AppStyle = theme => ({
  btnPublish: {
    background: '#FFBC00',
    color: theme.palette.primary.contrastText,
    borderColor: 'd9d9d9',
    fontSize: fontSizePxToRem(12)
  },
  btnDraft: {
    background: '#ffffff',
    color: theme.palette.primary.contrastText,
    borderColor: 'd9d9d9',
    fontSize: fontSizePxToRem(12)
  },
  draftContent: {
    maxHeight: 'calc(100vh - 60px) !important'
  }
});

export default styles;
