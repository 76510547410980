import { IEvent } from 'interfaces/event';
import { EventPanelStatistics } from 'interfaces/eventStatics';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import { CDN_URL } from 'settings';

import { EventPanelData } from './types';

class EventPanelService {
  private getEventPanelData(data: IEvent): EventPanelData {
    return {
      eventHeader: {
        isLive: data.is_live,
        title: data.title,
        eventId: data.id,
        date: { start: `${data.start_date} às ${data.start_hour}`, end: `${data.end_date} às ${data.end_hour}` },
        image: CDN_URL + data?.image_path
      },
      eventData: data
    };
  }

  public get(id: string): Rx.Observable<EventPanelData> {
    return eventService.details<IEvent>(id).pipe(RxOp.map(response => this.getEventPanelData(response)));
  }

  public getEventStatistics(id: string): Rx.Observable<EventPanelStatistics> {
    return eventService.dashboardStatistics(id).pipe(RxOp.map(response => response));
  }
}
const eventPanelService = new EventPanelService();
export default eventPanelService;
