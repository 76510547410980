import { UserTheme } from 'styles/types';

const clubBlackTheme: UserTheme = {
  menu: {
    default: {
      background: '#363636',
      color: ''
    },
    selected: {
      background: '#363636',
      borderColor: '#3d3d3d'
    }
  },
  'sub-menu': {
    default: {
      background: '#363636'
    }
  },
  'item-menu': {
    default: {
      color: '#F9FAFB',
      background: '#2f2f2f'
    },
    active: {
      background: '#2C2C2C',
      color: '#474747'
    },
    hover: {
      background: '#2C2C2C',
      color: '#F9FAFB'
    },
    selected: {
      background: '#474747',
      color: '#ECEFF1'
    }
  }
};

export default clubBlackTheme;
