import { ReactComponent as ReactLogo } from 'assets/images/new-blinket-logo.svg';

import { LoaderLine } from '../PageLoader/Line';

export default () => (
  <div className='blk-loading-component'>
    <div style={{ marginBottom: '15px' }}>
      <ReactLogo />
    </div>
    <LoaderLine />
  </div>
);
