import styled from 'styled-components';

type SugestionProps = {
  active: boolean;
};
export const Wrapper = styled.div``;

export const Sugestion = styled.div<SugestionProps>`
  background-color: ${props => (props.active ? '#fafafa' : '#ffffff')};
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: #efebeb;
  }
`;

export const Span = styled.span`
  font-size: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;
