import { ReactNode } from 'react';

import { Button } from 'antd';

import * as S from './styles';

type AddSomethingProps = {
  Button?: typeof Button;
  children?: ReactNode;
  customStyle?: object;
};

const AddSomething = ({ children, customStyle }: AddSomethingProps) => {
  return <S.AddWrapper style={customStyle}>{children}</S.AddWrapper>;
};

AddSomething.Button = S.AddButton;

export default AddSomething;
