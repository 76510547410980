import React, { PureComponent } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import Snackbar from '@material-ui/core/Snackbar';
import { Button } from 'antd';
import { WithStyles } from 'decorators/withStyles';
import { errorMessageFormatter } from 'formatters/errorMessage';
import { TOAST_DEFAULT_TIMEOUT, TOAST_ERROR_TIMEOUT } from 'settings';

import ToastGlobalProvider from './global';
import styles from './styles';
import { IProps, IState } from './types';

@WithStyles(styles)
export default class Toast extends PureComponent<IProps, IState> {
  static Global = ToastGlobalProvider;

  constructor(props: IProps) {
    super(props);
    this.state = { opened: false };
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    if (!nextProps.opened) {
      return {
        ...prevState,
        opened: false
      };
    }

    return {
      opened: nextProps.opened,
      message: nextProps.message || errorMessageFormatter(nextProps.error),
      isError: !!nextProps.error
    };
  }

  static show(message: string, timeout?: number) {
    return ToastGlobalProvider.show(message, null, timeout || TOAST_DEFAULT_TIMEOUT);
  }

  static error(error: any, timeout?: number) {
    return ToastGlobalProvider.show(null, error, timeout || TOAST_ERROR_TIMEOUT);
  }

  handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') return;
    this.props.onClose();
  };

  public render(): JSX.Element {
    const { opened, message, isError } = this.state;
    const { timeout, classes, onClose } = this.props;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={opened}
        autoHideDuration={timeout}
        onClose={this.handleClose}
        message={<span>{message}</span>}
        className={classes.wrapper}
        ContentProps={{ className: isError ? classes.contentError : null }}
        action={[
          <Button
            type='text'
            key='close'
            className='close'
            onClick={onClose}
            id={isError ? 'toast-error-closebutton' : 'toast-alert-closebutton'}
            icon={<CloseOutlined />}
          />
        ]}
        id={isError ? 'toast-error-msg' : 'toast-alert-msg'}
      />
    );
  }
}
