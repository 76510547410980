import LogoEduzz from 'assets/images/logo-eduzz-blue.svg';
import Maitenance from 'assets/images/maintenance.svg';

import * as S from './styles';

const Maintenance = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <S.ImageError src={Maitenance} alt='imagem com duas pessoas e um site de fundo' />
        <S.MessageWrapper>
          <h3>OPS!</h3>
          <h4>Estamos em manutenção, tente voltar mais tarde.</h4>
        </S.MessageWrapper>
      </S.Content>
      <S.Footer>
        <S.EduzzLogo src={LogoEduzz} alt='logo eduzz horizontal' />
        <S.CopyRight>Eduzz 2022. Todos os direitos reservados.</S.CopyRight>
      </S.Footer>
    </S.Wrapper>
  );
};
export default Maintenance;
