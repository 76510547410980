import React from 'react';

import { CloseOutlined, WarningOutlined } from '@ant-design/icons/lib/icons';
import { Button } from 'antd';

import * as S from './styles';
import { ModalDeleteProps } from './types';

const ModalDelete = ({ onClose, handleRemoveOffer, ...props }: ModalDeleteProps) => {
  return (
    <S.Container {...props}>
      <div className='modal-delete-container'>
        <div className='modal-delete-header'>
          <h2>Confirmar exclusão</h2>
          <div className='modal-delete-cancel' onClick={onClose}>
            <CloseOutlined />
          </div>
        </div>

        <div className='modal-delete-content'>
          <WarningOutlined />
          <p>Deseja realmente excluir essa oferta?</p>
        </div>

        <div className='modal-delete-buttons'>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type='primary' onClick={() => handleRemoveOffer()}>
            {' '}
            Confirmar{' '}
          </Button>
        </div>
      </div>
    </S.Container>
  );
};

export default ModalDelete;
