import React from 'react';

import WarningOutline from '@ant-design/icons/WarningOutlined';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'antd';

import * as S from './styles';
import { ModalWarningProps } from './types';

const ModalWarning = ({ title, content, onCancel, onContinue, ...props }: ModalWarningProps) => {
  const modalClassName = `MuiPaper-root ${props.className || ''}`;

  return (
    <S.Modal className={modalClassName} {...props}>
      <DialogTitle>
        <WarningOutline /> {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} id='modalwarnig-back-btn' type='text'>
          Voltar
        </Button>
        <Button
          className='modal-continue-button'
          id='modalwarnig-continue-btn'
          onClick={onContinue}
          type='primary'
          autoFocus
        >
          Continuar
        </Button>
      </DialogActions>
    </S.Modal>
  );
};
export default ModalWarning;
