import { AppStyle } from 'decorators/withStyles';
import { setMargin } from 'helpers/functions';

const styles: AppStyle = theme => ({
  content: {},
  iconButton: {
    color: theme.palette.primary.contrastText
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #ECEFF1',
    borderBottom: '1px solid #ECEFF1',
    padding: 8,
    ...setMargin(null, null, 32, null),
    '@media (max-width:720px)': {
      justifyContent: 'center',
      flexWrap: 'wrap-reverse',
      gap: '5px'
    }
  },
  iconButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px'
  },
  actionButtons: {
    marginLeft: '10px',
    border: '1px solid #ECEFF1',
    padding: '3px 5px',
    color: '#030303'
  },
  yourCustomClass: {
    color: '#000000',
    background: '#FFBC00'
  }
});

export default styles;
