import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { customBreakpoints: breakpoints } }) => css`
    .form-button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 90px;

      button {
        svg {
          margin-left: -10px;
        }
      }

      .form-button-outlined {
        border-color: #d9d9d9;
        color: #000000;

        &:hover {
          border-color: #000000;
          background-color: #ffffff;
        }
      }

      .form-button-container-left {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 10px 0 0;
        height: 100%;
        justify-content: space-between;

        .form-return-button {
          display: flex;
          justify-content: flex-end;
          padding: 0 16px 0 0;
          color: #000000;
        }
      }

      .form-button-container-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }

    @media (${breakpoints.md}) {
      .form-button-container {
        justify-content: flex-end;
        height: fit-content;
        margin: 24px 0 0 0;

        button {
          margin: 0 0 0 12px;
        }

        .form-button-outlined {
          .form-icon-eye {
            display: block;
          }

          .form-icon-file {
            display: block;
          }
        }

        .form-button-container-left {
          flex-direction: row;
          align-items: center;
          margin: 0;
        }

        .form-button-container-right {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  `}
`;
