import { CSSObject } from 'styled-components';

export enum BreakPointsValues {
  xs = '320px',
  sm = '425px',
  md = '768px',
  lg = '1024px',
  xl = '1440px'
}

export type UserTheme = {
  [key: string]: {
    default?: CSSObject;
    active?: CSSObject;
    hover?: CSSObject;
    selected?: CSSObject;
  };
};

export type BreakPoints = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
};
export interface CustomHoustonTheme {
  customBreakpoints: BreakPoints;
  user: UserTheme;
}
export interface StyledHoustonThemeProps {
  children: JSX.Element;
}
