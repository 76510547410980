import styled from 'styled-components';

export const ListOptionalFields = styled.div`
  margin-top: 17px;
  font-size: 13px;
  color: gray;
`;

export const ListItemOptionalFields = styled.div`
  display: list-item;
  list-style: circle;
  margin-left: 26px;
`;
