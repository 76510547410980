import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    minWidth: `calc(100% - ${theme.variables.drawerWidth}px)`,
    [theme.breakpoints.up('md')]: {}
  },
  bgColorGrey: {
    backgroundColor: '#f9fafb'
  }
}));

export default useStyles;
