import { fontSizePxToRem } from 'helpers/functions';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    margin: auto;

    .event-cart-title {
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('semibold')};
      font-size: ${fontSizePxToRem(16)};
    }

    .event-cart-description {
      color: ${colors.primary.contrastText};
      font-size: ${fontSizePxToRem(14)};
      font-weight: ${fontWeight('regular')};
      margin: 0 0 12px 0;
    }

    li {
      color: ${colors.primary.contrastText};
      font-size: ${fontSizePxToRem(14)};
      font-weight: ${fontWeight('regular')};
    }

    .event-links-sales-message-issue {
      background-color: #f5f5f5;
      border: 1px solid #d0d0d0;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 11px 13px;
      color: #37474f;
      font-size: inherit;

      a {
        color: #000000;
      }
    }

    .event-cart-custom-card {
      color: ${colors.primary.contrastText};
      font-size: ${fontSizePxToRem(14)};
      font-weight: ${fontWeight('regular')};
      margin: 12px 0 0 0;
    }

    .event-cart-link {
      display: flex;
      align-items: center;
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('semibold')};
      font-size: ${fontSizePxToRem(14)};
      text-decoration: underline;
      margin: 8px 0 16px 0;
      cursor: pointer;

      span {
        margin: 0 0 0 10px;
      }

      &.desktop {
        display: none;
      }
    }

    @media (${breakpoints.md}) {
      .event-cart-title {
      }

      .event-cart-link {
        margin: 4px 0 24px 0;

        &.desktop {
          display: flex;
        }

        &.mobile {
          display: none;
        }
      }

      .event-cart-custom-card {
        margin: 33px 0 0 0;
      }
    }
  `}
`;
