import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.span`
  border-right: 1px solid #ccc;
  color: #000000;
  overflow: hidden;
  padding-right: 10px;
  text-overflow: ellipsis;
  font-size: 0.75rem;

  ${media.greaterThan('small')`
    overflow: unset;
    font-size: 1rem;
  `}
`;
