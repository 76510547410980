import { CalendarOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Row, Space } from 'antd';
import { compareAsc, format, getDate } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { DateDetail } from 'interfaces/event';
import { isMobile } from 'react-device-detect';

import * as S from './styles';

const menuItemStyle = {
  margin: 0,
  padding: 0
};

interface EventDatesProps {
  fields: DateDetail[];
  onDelete: (arrayId: string, id?: string) => void;
  onDuplicate: (eventDate: DateDetail) => void;
  onEdit: (eventDate: DateDetail) => void;
}

const EventDates = ({ fields, onDelete, onDuplicate, onEdit }: EventDatesProps) => {
  const orderFields = (): DateDetail[] => {
    const orderFields = [...fields];

    orderFields.sort((a, b) => compareAsc(a.date, b.date));

    return orderFields;
  };

  return (
    <Row>
      <Col span={24}>
        {orderFields().map((field, index) => {
          const date = format(field.date, 'dd/MM/yyyy', { locale: ptBR });
          const dayOfWeek = format(field.date, 'EEEE', { locale: ptBR });
          const day = getDate(field.date);
          const month = format(field.date, 'LLLL', { locale: ptBR });
          const year = format(field.date, 'yyyy', { locale: ptBR });
          const startTime = format(new Date(field.start_time), 'HH:mm', { locale: ptBR });
          const endTime = format(new Date(field.end_time), 'HH:mm', { locale: ptBR });

          return (
            <S.DateWrapper key={field.id}>
              <Space direction='horizontal'>
                <CalendarOutlined style={{ fontSize: '1rem', margin: '0 5px' }} />
                <S.Day>Dia {index + 1}:</S.Day>
                {!isMobile && (
                  <S.Info>{`${dayOfWeek}, ${day} de ${month} de ${year} ás ${startTime} até ${endTime}`}</S.Info>
                )}
                {isMobile && (
                  <>
                    <Col span={24}>
                      <S.Info>{date}</S.Info>
                    </Col>
                    <Col span={24}>
                      <S.Info>{`${startTime} até ${endTime}`}</S.Info>
                    </Col>
                  </>
                )}
              </Space>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <S.MenuButton type='text' onClick={() => onEdit(field)} block>
                          Editar
                        </S.MenuButton>
                      ),
                      key: '1',
                      style: menuItemStyle
                    },
                    {
                      label: (
                        <S.MenuButton type='text' onClick={() => onDuplicate(field)} block>
                          Duplicar
                        </S.MenuButton>
                      ),
                      key: '2',
                      style: menuItemStyle
                    },
                    {
                      label: (
                        <S.MenuButton type='text' onClick={() => onDelete(field.arrayId, field.id)} block>
                          Excluir
                        </S.MenuButton>
                      ),
                      key: '3',
                      danger: true,
                      style: menuItemStyle
                    }
                  ],
                  selectable: true
                }}
                placement='bottomRight'
              >
                <Button type='text' icon={<MoreOutlined rotate={90} style={{ fontSize: '25px' }} />} />
              </Dropdown>
            </S.DateWrapper>
          );
        })}
      </Col>
    </Row>
  );
};

export default EventDates;
