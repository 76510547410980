import { format, isBefore } from 'date-fns';
import { IEvent, IEventForm } from 'interfaces/event';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';

class CreateEventService {
  private getFormatedEventData(data: IEventForm): any {
    const { ticket, event_dates } = data;

    const startDate = data.event_dates.reduce((current, previous) => {
      return isBefore(current.date, previous.date) ? current : previous;
    });
    const endDate = data.event_dates.reduce((current, previous) => {
      return isBefore(current.date, previous.date) ? previous : current;
    });

    const eventDates = event_dates.map(eventDate => ({
      ...eventDate,
      end_time: format(new Date(eventDate.end_time), 'HH:mm'),
      start_time: format(new Date(eventDate.start_time), 'HH:mm')
    }));

    const newTicket = ticket.map(item => ({
      ...item,
      is_paid: item.is_paid ? 'on' : '',
      show_on_page: item.show_on_page ? 'on' : '',
      lot: item.lot.map(lot => {
        const lotDateStart = lot.dateStart;
        const lotDateEnd = lot.dateEnd;

        lotDateStart.setSeconds(0);
        lotDateEnd.setSeconds(0);

        return {
          ...lot,
          dateEnd: lotDateEnd,
          dateStart: lotDateStart,
          end_sale_date: lotDateEnd.toLocaleDateString('pt-br'),
          end_sale_time: lotDateEnd.toLocaleTimeString('pt-br'),
          quantity: lot.quantity || 0,
          start_sale_date: lotDateStart.toLocaleDateString('pt-br'),
          start_sale_time: lotDateStart.toLocaleTimeString('pt-br'),
          value: item.is_paid ? lot.value?.toString().replace('R$', '').replace('.', ',').trim() : 0
        };
      })
    }));

    return {
      ...data,
      image_path: data.image_path?.replace(`${process.env.REACT_APP_CDN_URL}`, ''),
      image_base64: data.image_base64?.replace('data:image/png;base64,', ''),
      image_id: undefined,
      event_dates: eventDates,
      end_date: format(endDate.date, 'dd/MM/yyyy'),
      end_hour: format(new Date(endDate.end_time), 'HH:mm'),
      ticket: newTicket,
      start_date: format(startDate.date, 'dd/MM/yyyy'),
      start_hour: format(new Date(startDate.start_time), 'HH:mm')
    };
  }

  public createEvent(data: IEventForm): Rx.Observable<IEvent> {
    const dataFormated = this.getFormatedEventData(data);

    return eventService.createEvent(dataFormated).pipe(RxOp.map(response => response.data[0]));
  }
}

export default CreateEventService;
