import React, { useCallback } from 'react';

import PrivateLayout from 'components/Container/PrivateLayout';
import AsyncComponent from 'components/Shared/AsyncComponent';
import Maintenance from 'Pages/Maintenance';
import { PrintProvider } from 'Pages/PurchaseMade/ApprovedPayment/Ticket/context';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Roles } from 'types/roles';

import AthenticatedRoutes from './AuthenticatedRoutes';
import Gateway from './Gateway';
import eventRoutes from './PublicRoutes/eventRoutes';
import { EventRoutes } from './PublicRoutes/eventRoutes/types';
import generalRoutes from './PublicRoutes/generalRoutes';
import { GeneralRoutes } from './PublicRoutes/generalRoutes/types';
import pushcaseMadeRoutes from './PublicRoutes/pushcaseMadeRoute';
import { PushcaseMadeRoutes } from './PublicRoutes/pushcaseMadeRoute/types';

const Routes = () => {
  const renderGeneralRoutes = useCallback((route: GeneralRoutes) => {
    return (
      <Route
        key={route.path}
        path={route.path}
        exact
        component={AsyncComponent(() => import('components/Pages/' + route.import))}
      />
    );
  }, []);

  const renderPushcaseMadeRoutes = useCallback((route: PushcaseMadeRoutes) => {
    return (
      <Route
        key={route.path}
        path={route.path}
        exact
        component={AsyncComponent(() => import('Pages/' + route.import))}
      />
    );
  }, []);

  const renderEventRoutes = useCallback((route: EventRoutes) => {
    return (
      <Route
        path={route.path}
        key={route.path}
        exact
        component={AsyncComponent(() => import('Pages/' + route.import))}
      />
    );
  }, []);

  const renderMaintenance = () => {
    const isInMaintenance = process.env.REACT_APP_IS_IN_MAINTENANCE === 'true';
    return isInMaintenance && <Route path='/' component={() => <Maintenance />} />;
  };

  return (
    <BrowserRouter getUserConfirmation={() => null}>
      <PrintProvider>
        <Switch>
          {renderMaintenance()}
          {eventRoutes.map(renderEventRoutes)}
          {generalRoutes.map(renderGeneralRoutes)}
          {pushcaseMadeRoutes.map(renderPushcaseMadeRoutes)}
          <PrivateLayout>
            <Gateway path='/' role={Roles.producer} component={AthenticatedRoutes} />
          </PrivateLayout>
          <Route render={() => <Redirect to='/' />} />
        </Switch>
      </PrintProvider>
    </BrowserRouter>
  );
};

export default Routes;
