import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h3`
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;
`;

export const Subtitle = styled.h6`
  color: #595959;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 10px;
`;
