import { AppStyle } from 'decorators/withStyles';
import { setMargin, setPadding } from 'helpers/functions';

const styles: AppStyle = () => ({
  formFontSizeGridItem: {
    ...setPadding(null, 22, null, null),
    textAlign: 'left',
    flexDirection: 'column'
  },
  formHeader: {
    fontWeight: 600,
    fontSize: '1.1em',
    ...setMargin(null, null, 8, null)
  },
  selectLabel: {
    ...setMargin(null, null, 10, null),
    display: 'block'
  },
  selectList: {
    width: '105px',
    textAlign: 'center'
  }
});

export default styles;
