import React from 'react';

import { DrawerProps } from '@material-ui/core';

import { Drawer } from './styles';

const DrawerPersist = ({ children, ...rest }: DrawerProps) => (
  <Drawer variant='permanent' {...rest}>
    {children}
  </Drawer>
);

export default DrawerPersist;
