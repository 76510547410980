import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'antd';
import SelectMultipleCheckbox from 'components/Shared/SelectMultipleCheckbox';

import styles from './styles';
import { IProps } from './types';

const statusData = [
  { value: 'assigned', label: 'Atribuídos' },
  { value: 'unassigned', label: 'Não Atribuídos' },
  { value: 'canceled', label: 'Cancelado' }
];

const attendeesData = [
  { value: 'purchased', label: 'Adicionado pelo participante' },
  { value: 'manually_created', label: 'Adicionado manualmente' },
  { value: 'checkin', label: 'Check-in' }
];

const FilterAdvanced: React.FC<IProps> = ({
  classes,
  tags,
  isOpen,
  tickets,
  selectTagsHandleChange,
  selectTicketsHandleChange,
  selectStatusHandleChange,
  selectAttendeesHandleChange,
  cleanFilter,
  submitFilter,
  selectedTags,
  selectedTickets,
  selectedStatus,
  selectedAttendees
}) => {
  const ordenedTickets = tickets.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

  const selectTagsData = [] as any;
  if (tags && tags.length > 0) {
    tags.forEach(function (entry) {
      selectTagsData.push({ value: entry.title, label: entry.title });
    });
  }

  const selectTicketsData = [] as any;
  if (ordenedTickets && ordenedTickets.length > 0) {
    ordenedTickets.forEach(function (entry) {
      selectTicketsData.push({ value: entry.id, label: entry.name });
    });
  }

  return (
    isOpen && (
      <Grid item xs={12}>
        <Paper className={classes.paperArea} elevation={2}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <InputLabel>Marcadores</InputLabel>
              <SelectMultipleCheckbox
                data={selectTagsData}
                labelSelectOne='Todos os Marcadores'
                onChange={selectTagsHandleChange}
                value={selectedTags}
                id='presencelist-event-advancedfilters-tagfilter'
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <InputLabel>Ingressos</InputLabel>
              <SelectMultipleCheckbox
                data={selectTicketsData}
                labelSelectOne='Todos os Ingressos'
                onChange={selectTicketsHandleChange}
                value={selectedTickets}
                id='presencelist-event-advancedfilters-ticketfilter'
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <InputLabel>Status</InputLabel>
              <SelectMultipleCheckbox
                data={statusData}
                labelSelectOne='Todos os Status'
                onChange={selectStatusHandleChange}
                value={selectedStatus}
                id='presencelist-event-advancedfilters-statusfilter'
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <InputLabel>Participantes</InputLabel>
              <SelectMultipleCheckbox
                data={attendeesData}
                labelSelectOne='Todos os Participantes'
                onChange={selectAttendeesHandleChange}
                value={selectedAttendees}
                id='presencelist-event-advancedfilters-atteendeesfilter'
              />
            </Grid>
          </Grid>

          <div className={classes.actionButtons}>
            <Button type='text' onClick={() => cleanFilter()} id='presencelist-event-cleanfilters-button'>
              Limpar filtros
            </Button>

            <Button type='primary' onClick={() => submitFilter()} id='presencelist-event-confirmfilters-button'>
              Filtrar
            </Button>
          </div>
        </Paper>
      </Grid>
    )
  );
};

export default withStyles(styles)(FilterAdvanced);
