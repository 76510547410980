import { ParcitipantsRoutes } from './types';

const participantsRoutes: ParcitipantsRoutes[] = [
  {
    path: '/presence-list/:eventId/participant/:cliCod',
    import: 'Participants/Detail'
  }
];

export default participantsRoutes;
