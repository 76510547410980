import { useEffect, useState } from 'react';

function useLocation() {
  const [location, setLocation] = useState({ paths: [''] });

  useEffect(() => {
    const paths = window.location.pathname.split('/');
    paths.shift();
    setLocation(prevState => ({ ...prevState, paths }));
  }, [setLocation]);

  return { ...location };
}

export default useLocation;
