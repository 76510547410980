import { Button } from 'antd';
import styled from 'styled-components';

export const AddWrapper = styled.div`
  border: 1px dashed #d9d9d9;
  justify-content: start;
  padding: 15px;
`;

export const AddButton = styled(Button)`
  width: 100%;
  text-align: start;
`;
