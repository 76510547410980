import { AppStyle } from 'decorators/withStyles';

const styles: AppStyle = theme => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonSubmit: {
    background: theme.variables.mainColor,
    color: '#FFFFFF',
    width: 140
  },

  buttonTicket: {
    background: theme.palette.secondary.contrastText,
    color: '#FFFFFF',
    width: 160
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  noMarker: {
    padding: 12,
    textAlign: 'center'
  }
});

export default styles;
