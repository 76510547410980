import { useContext, useState } from 'react';

import { CalendarOutlined, EnvironmentOutlined, GlobalOutlined } from '@ant-design/icons';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Grid } from '@mui/material';
import { Button, Tooltip } from 'antd';
import { triggerEventCreateSurvey } from 'components/Shared/HotjarEventTrigger';
import Toast from 'components/Shared/Toast';
import { format } from 'date-fns';
import { IEventForm } from 'interfaces/event';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import userService from 'services/user';
import { User } from 'services/user/types';

import FormContext from '../../../Context';
import CreateEventService from '../../../Create/service';
import Actions from '../Actions';
import EventDatesModal from './EventDatesModal';
import * as S from './styles';
import TicketTable from './TicketTable';

const notifySaleLinksSubMenu = new CustomEvent('inform-sale-links', { detail: 'open' });

const FormFive = () => {
  const [openEventDatesModal, setOpenEventDatesModal] = useState(false);
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);
  const { handleEdit, isCreate, loadingEdit, jobsLotPending, setCurrentStep, eventCategories } =
    useContext(FormContext);
  const { getValues } = useFormContext<IEventForm>();
  const { push } = useHistory();

  const { city, description, district, event_dates, number, state, type, street, ticket, title, zip, category_id } =
    getValues();

  const createEventService = new CreateEventService();

  const eventDatesFormated = (): string => {
    const firstDateFormated = format(new Date(event_dates[0].date), 'dd/MM/yyyy');
    const lastDateFormated = format(new Date(event_dates[event_dates.length - 1].date), 'dd/MM/yyyy');
    const firstDateStartTimeFormated = format(new Date(event_dates[0].start_time), 'HH:mm');
    const lastDateEndTimeFormated = format(new Date(event_dates[event_dates.length - 1].end_time), 'HH:mm');

    return `${firstDateFormated} às ${firstDateStartTimeFormated} a ${lastDateFormated} as ${lastDateEndTimeFormated}`;
  };

  const eventLocale = (): string => {
    if (type === 'online') {
      return 'Online';
    }

    return `${street}, ${number} - ${district} - CEP: ${zip} - ${city} - ${state}`;
  };

  const handleCloseEventDatesModal = () => {
    setOpenEventDatesModal(false);
  };
  const getUser = () => {
    let user: User;
    userService.getUser().subscribe({
      next: userProfile => {
        user = userProfile;
      }
    });

    return user;
  };

  const handleSave = () => {
    const { api_key, is_producer, cli_cod, user_email } = getUser();
    const dataValues: IEventForm = { ...getValues(), api_key };

    delete dataValues.image_id;
    delete dataValues.image_path;

    setIsCreatingEvent(true);

    createEventService.createEvent(dataValues).subscribe({
      next: response => {
        setIsCreatingEvent(false);
        triggerEventCreateSurvey();

        if (is_producer === 0) {
          userService
            .setMeAsProducer({
              cli_cod,
              email: user_email,
              api_key
            })
            .subscribe({
              next: () => {
                Toast.show('Evento criado com sucesso');
                window['IS_CREATE_EVENT'] = true;
                document.dispatchEvent(notifySaleLinksSubMenu);
                setTimeout(() => {
                  push(`/my-events/${response.id}/basic-data`);
                }, 2000);
              },
              error: ({ data: err }) => {
                if (err.message === 'Você não possui permissão para realizar esta ação') {
                  Toast.error(err.message, 6000);
                } else {
                  Toast.error(false);
                }
              }
            });
        } else {
          Toast.show('Evento criado com sucesso');
          window['IS_CREATE_EVENT'] = true;
          document.dispatchEvent(notifySaleLinksSubMenu);
          push(`/my-events/${response.id}/basic-data`);
        }
      },
      error: (err: any) => {
        if (err.message === 'Você não possui permissão para realizar esta ação') {
          Toast.error(err.message, 6000);
        } else {
          Toast.error('Falha ao criar o evento');
        }
        setIsCreatingEvent(false);
      }
    });
  };

  const handlePrimaryButtonAction = () => {
    if (isCreate) {
      handleSave();
      return;
    }
    handleEdit(getValues());
  };

  const getCategoryTitle = () => {
    const title = eventCategories.filter(category => {
      if (category.id === category_id) {
        return category.title;
      }
    });
    return title.length > 0 ? title[0].title : 'Não há';
  };

  const isLoadingSomething = isCreatingEvent || !!jobsLotPending || loadingEdit;
  const primaryButtonActionText = isLoadingSomething ? 'Salvando' : 'Salvar';

  return (
    <S.Wrapper>
      <Grid item xs={12}>
        <S.Title>Resumo do evento</S.Title>
      </Grid>
      <Grid container spacing={4}>
        <Grid container item xs={12}>
          <Grid container item xs={6} spacing={3}>
            <Grid container item xs={12}>
              <GlobalOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
              <S.CustomizedTypography
                alignItems='center'
                color='#000000'
                display='flex'
                fontSize='0.875rem'
                fontWeight='600'
                lineHeight='150%'
                marginRight='4px'
                variant='h5'
              >
                {title}
              </S.CustomizedTypography>
            </Grid>

            <Grid container item xs={12}>
              <CalendarOutlined style={{ fontSize: '20px', marginRight: '5px' }} />
              <S.CustomizedTypography
                alignItems='center'
                color='#000000'
                display='flex'
                fontSize='0.8rem'
                lineHeight='150%'
                marginRight='4px'
              >
                {eventDatesFormated()}
              </S.CustomizedTypography>
              <Button onClick={() => setOpenEventDatesModal(true)}>Ver detalhes</Button>
            </Grid>

            {openEventDatesModal && (
              <EventDatesModal
                eventDates={event_dates}
                open={openEventDatesModal}
                onClose={handleCloseEventDatesModal}
              />
            )}

            <Grid container item xs={12}>
              {type === 'presential' && <EnvironmentOutlined style={{ fontSize: '20px', marginRight: '5px' }} />}
              {type === 'online' && <GlobalOutlined style={{ fontSize: '20px', marginRight: '5px' }} />}
              <S.CustomizedTypography
                alignItems='center'
                color='#000000'
                display='flex'
                fontSize='0.8rem'
                lineHeight='150%'
              >
                {eventLocale()}
              </S.CustomizedTypography>
            </Grid>

            <Grid container item xs={12}>
              <S.DescriptionEventTitle>Categoria do evento: </S.DescriptionEventTitle>
              <S.CustomizedTypography
                alignItems='center'
                color='#000000'
                display='flex'
                fontSize='0.8rem'
                lineHeight='150%'
                marginLeft={'5px'}
              >
                {getCategoryTitle()}
              </S.CustomizedTypography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <S.DescriptionEventTitle>Sobre o evento</S.DescriptionEventTitle>
            <S.CustomizedTypography>{description}</S.CustomizedTypography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item>
            <S.TicketAndLotsTitle>Ingresso e Lotes</S.TicketAndLotsTitle>
          </Grid>
        </Grid>

        {ticket.map((item, index) => (
          <Grid container item key={index}>
            <Grid item xs={12}>
              <S.CustomizedTypography>{item.name}</S.CustomizedTypography>
              <TicketTable lots={item.lot} />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Actions>
            {isCreate && (
              <Actions.Button type='text' size='large' id='stepback-btn-form-5' onClick={() => setCurrentStep('3')}>
                Voltar
              </Actions.Button>
            )}
            <Tooltip defaultOpen={!!jobsLotPending} open={!!jobsLotPending} title={jobsLotPending} color='#ffbc00'>
              <Actions.Button
                onClick={handlePrimaryButtonAction}
                type='primary'
                id={isCreate ? 'step-forward-btn-form-5' : 'save-btn-form-5'}
                size='large'
                disabled={isCreatingEvent || loadingEdit || !!jobsLotPending}
                icon={!isCreate && <SaveOutlined />}
              >
                {primaryButtonActionText}
              </Actions.Button>
            </Tooltip>
          </Actions>
        </Grid>
      </Grid>
    </S.Wrapper>
  );
};

export default FormFive;
