import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Button } from 'antd';

import { IProps } from './types';

function ExportPdfDialog({ opened, onCancel, onComplete, loading, classes }: IProps) {
  const [letterByPage, setLetterByPage] = React.useState('false');

  function handleChangeLetterByPage(type: string) {
    setLetterByPage(type);
  }

  return (
    <Dialog open={opened} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='sm'>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Escolha o tipo de exportação</div>
          <Button
            type='text'
            onClick={onCancel}
            style={{ color: '#000000' }}
            icon={<CloseOutlined />}
            id='presencelist-exportpdfdialog-closebutton'
          />
        </div>
      </DialogTitle>

      <DialogContent>
        <div>
          <RadioGroup value={letterByPage} onChange={(e: any) => handleChangeLetterByPage(e.target.value)}>
            <FormControlLabel
              value='false'
              control={<Radio color='primary' />}
              label='Lista única'
              id='presencelist-exportpdfdialog-radiosingle'
            />
            <FormControlLabel
              value='true'
              control={<Radio color='primary' />}
              label='Separar por letra'
              id='presencelist-exportpdfdialog-radiobyletter'
            />
          </RadioGroup>
        </div>
      </DialogContent>

      <DialogActions>
        <div>
          <Button
            type='primary'
            onClick={() => onComplete(letterByPage)}
            disabled={loading}
            id='presencelist-exportpdfdialog-confirmbutton'
          >
            Exportar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
export default ExportPdfDialog;
