import { createContext, ReactNode, useState } from 'react';

type PrintContextValues = {
  printing: string[];
  setPrinting: (attendanceIds: string[]) => void;
};

type PrintContextProps = {
  children: ReactNode;
};

const PrintContext = createContext<PrintContextValues>(null);

export const PrintProvider = ({ children }: PrintContextProps) => {
  const [printing, setPrinting] = useState([]);

  return (
    <PrintContext.Provider
      value={{
        printing,
        setPrinting
      }}
    >
      {children}
    </PrintContext.Provider>
  );
};

export default PrintContext;
