import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem } from 'helpers/functions';

const styles: AppStyle = theme => ({
  root: {
    position: 'relative',
    padding: 16,
    minHeight: 198,
    borderLeft: `${theme.palette.primary.main} 12px  solid`,
    [theme.breakpoints.down('sm')]: {
      padding: 12
    }
  },
  title: {
    fontSize: fontSizePxToRem(16),
    fontWeight: 400,
    color: theme.palette.primary.contrastText,
    marginBottom: 8,
    '& > strong': { color: theme.palette.secondary.main },
    [theme.breakpoints.down('xs')]: {
      fontSize: fontSizePxToRem(14)
    }
  },
  description: {
    fontSize: fontSizePxToRem(14),
    color: theme.palette.primary.contrastText,
    maxWidth: 360,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: fontSizePxToRem(12)
    }
  },
  listActions: {
    marginTop: 6,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 430,
    '& > button': {
      display: 'flex',
      justifyContent: 'space-between',
      textTransform: 'none',
      marginTop: 4,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        padding: '0 !important',
        border: 'none !important',
        fontSize: `${fontSizePxToRem(10)} !important`,
        marginTop: 12,
        '& svg': {
          width: 18,
          height: 18
        }
      }
    }
  }
});

export default styles;
