import * as React from 'react';

import { useCustomObservable } from 'hooks';
import userService from 'services/user';
import { User } from 'services/user/types';

import Alert from '@eduzz/fidc-alert';

import useStyles from './styles';

const FIDCAlert = () => {
  const classes = useStyles();
  const { value: user } = useCustomObservable<User, string, string>(() => userService.getUser(), null);

  if (!user) return null;

  return <Alert className={classes.root} eduzzId={user.cli_cod} userName={user.user_name} application='eduzz' />;
};

export default FIDCAlert;
