import React from 'react';

import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';
import { IProps } from './types';

const TextFormattingForm: React.FC<IProps> = ({ classes, onChange, data, displayedInfo }) => {
  const { displayEvent, displayTicket, isDisplayName } = displayedInfo;

  return (
    <>
      {(!!displayEvent || !!displayTicket || !!isDisplayName) && (
        <Typography className={classes.formHeader}>Selecione o tamanho da fonte:</Typography>
      )}

      <Grid container>
        {isDisplayName ? (
          <Grid item sm={4} className={classes.formFontSizeGridItem}>
            <InputLabel className={classes.selectLabel}>Nome:</InputLabel>
            <Select
              onChange={(e: any) =>
                onChange({
                  ...data,
                  nameFontSize: e.target.value
                })
              }
              value={data.nameFontSize}
              className={classes.selectList}
            >
              <MenuItem value='3'>Pequeno</MenuItem>
              <MenuItem value='4'>Médio</MenuItem>
              <MenuItem value='5'>Grande</MenuItem>
            </Select>
          </Grid>
        ) : null}

        {displayTicket ? (
          <Grid item sm={4} className={classes.formFontSizeGridItem}>
            <InputLabel className={classes.selectLabel}>Ingresso: </InputLabel>
            <Select
              onChange={(e: any) => {
                onChange({
                  ...data,
                  ticketFontSize: e.target.value
                });
              }}
              value={data.ticketFontSize}
              className={classes.selectList}
            >
              <MenuItem value='3'>Pequeno</MenuItem>
              <MenuItem value='4'>Médio</MenuItem>
              <MenuItem value='5'>Grande</MenuItem>
            </Select>
          </Grid>
        ) : null}

        {displayEvent ? (
          <Grid item sm={4} className={classes.formFontSizeGridItem}>
            <InputLabel className={classes.selectLabel}>Evento: </InputLabel>
            <Select
              onChange={(e: any) => {
                onChange({
                  ...data,
                  eventFontSize: e.target.value
                });
              }}
              value={data.eventFontSize}
              className={classes.selectList}
            >
              <MenuItem value='3'>Pequeno</MenuItem>
              <MenuItem value='4'>Médio</MenuItem>
              <MenuItem value='5'>Grande</MenuItem>
            </Select>
          </Grid>
        ) : null}
      </Grid>

      <div style={{ paddingBottom: 16 }} />

      <Typography className={classes.formHeader}>Alinhamento do conteúdo:</Typography>

      <Grid container>
        <Grid item sm={12}>
          <Select
            onChange={(e: any) => {
              onChange({
                ...data,
                textAlign: e.target.value
              });
            }}
            value={data.textAlign}
            style={{ width: '250px' }}
          >
            <MenuItem value='left'>Alinhado a Esquerda</MenuItem>
            <MenuItem value='center'>Centralizado</MenuItem>
            <MenuItem value='right'>Alinhado a Direita</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(TextFormattingForm);
