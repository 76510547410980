import React from 'react';

import styled, { css, CSSProperties } from 'styled-components';

const DIV = ({ children, isClubeBlack, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

const CLUBEBLACK = {
  bgColor: '#363636',
  color: '#ECEFF1',
  selected: {
    bgColor: '#474747',
    color: '#ECEFF1'
  },
  hover: {
    bgColor: '#2C2C2C',
    color: '#F9FAFB'
  },
  active: {
    bgColor: '#2C2C2C',
    color: '#474747'
  }
};

export const Container = styled(DIV)`
  ${({ theme: { user }, isClubeBlack }) => css`
    background-color: ${isClubeBlack ? CLUBEBLACK.bgColor : user['menu'].default.background};
    height: 100%;
    width: 300px;

    .drawer-persist > div {
      margin: 70px 0 0 0;
    }

    .main-menu {
      .header {
        height: 80px;
        padding: 24px;
        img {
          width: 123px;
        }
      }
    }

    &.sub-route-selected {
      .main-menu {
        background-color: ${user['menu'].selected.background};
        border-right: solid 1px ${user['menu'].selected.borderColor};
        height: 100%;
        width: 70px;

        .header {
          height: 80px;
          img {
            width: 23px;
          }
        }

        .body {
          .item-menu {
            span {
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }
      }
    }

    .sub-menu {
      background-color: ${user['sub-menu'].default.background};
      left: 70px;
      max-width: 230px;
      position: absolute;
      top: 80px;
      width: 100%;

      .item-sub-menu {
        align-items: center;
        color: ${user['item-menu'].default.color};
        cursor: pointer;
        display: flex;
        height: 48px;
        padding-left: 20px;
        width: 100%;

        &:active {
          background-color: ${user['item-menu'].active.background};
          color: ${user['item-menu'].active.color};
        }

        &:hover {
          background-color: ${user['item-menu'].hover.background};
          color: ${user['item-menu'].hover.color};
          transition: 0.2s;
        }

        &.selected {
          background-color: ${user['item-menu'].selected.background};
          color: ${user['item-menu'].selected.color};
        }

        &.hasNotification {
          position: relative;
        }

        &.hasNotification:after {
          content: '';
          background-color: red;
          position: absolute;
          left: 200px;
          height: 8px;
          width: 8px;
          border-radius: 100%;
        }
      }
    }
  `}
`;
export const ContainerMenu = styled(DIV)<CSSProperties>`
  ${({ theme: { user }, isClubeBlack }) => {
    return css`
      .item-menu {
        color: ${isClubeBlack ? CLUBEBLACK.color : '#546e7a'};
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 48px;
        padding-left: 24px;
        width: 100%;
        span {
          &:first-child {
            margin-right: 10px;
          }
        }
      }

      .item-menu {
        &.selected {
          background-color: ${isClubeBlack && CLUBEBLACK.selected.bgColor};
          color: ${isClubeBlack && CLUBEBLACK.selected.color};
        }

        &:hover {
          background-color: ${isClubeBlack ? CLUBEBLACK.hover.bgColor : user['item-menu'].hover.background};
          color: ${isClubeBlack && CLUBEBLACK.hover.color};
          transition: 0.2s;
        }

        &:active {
          background-color: ${isClubeBlack ? CLUBEBLACK.active.bgColor : user['item-menu'].active.background};
          color: ${isClubeBlack ? CLUBEBLACK.active.color : user['item-menu'].active.color};
        }
      }

      &.item-route-selected {
        .item-menu {
          span {
            &:first-child {
              color: #000000;
            }
          }
        }

        .item-menu::before {
          background-color: #ffbc00;
          border-radius: 0 10px 10px 0;
          content: ' ';
          height: 32px;
          left: 0;
          position: absolute;
          width: 4px;
        }
      }
    `;
  }}
`;
