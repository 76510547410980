import React from 'react';

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Button, Typography, Tooltip } from 'antd';

import * as S from './styles';
import { PageTitleProps } from './types';

const PageTitle = ({ title, subtitle, onIconClick, ...props }: PageTitleProps) => {
  return (
    <S.Container className={props.className}>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography>
        {subtitle}
        {onIconClick && (
          <Tooltip title='Abrir tour' color='#d9d9d9' arrow>
            <Button id='open-event-page-button' type='text' onClick={onIconClick} icon={<QuestionCircleOutlined />} />
          </Tooltip>
        )}
      </Typography>
    </S.Container>
  );
};

export default PageTitle;
