import { useState } from 'react';

import { Card, Checkbox } from 'antd';
import { Modal, Typography, Divider, Row, Col } from 'components/Shared/Antd';
import { useHistory } from 'react-router-dom';
import userService from 'services/user';

const FunctionalitiesModal = ({ open, onCancel }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const onChange = e => {
    setDontShowAgain(e.target.checked);
  };

  const history = useHistory();

  const onCardClick = (title: string) => () => {
    history.push(`${title}?showTabTour=true`);
    onCancel();
  };

  const handleAfterClose = () => {
    if (dontShowAgain) {
      sessionStorage.setItem('dontShowTour', 'true');
      userService
        .hasUsedTour({
          name: 'welcome',
          dismissed: true
        })
        .subscribe();
    }
  };

  return (
    <Modal
      title='Personalize seu evento'
      open={open}
      onCancel={onCancel}
      width={750}
      afterClose={handleAfterClose}
      footer={[
        <Checkbox key='do-not-show-again' onChange={onChange} checked={dontShowAgain} style={{ width: '100%' }}>
          Não exibir novamente
        </Checkbox>
      ]}
    >
      <Typography.Text>Use nossas ferramentas para elevar o nível do seu evento!</Typography.Text>
      <Divider />
      <Row gutter={[8, 8]} style={{ textAlign: 'center' }}>
        <Col md={8} flex={0}>
          <Card
            title='Links de divulgação'
            style={{ height: '100%', cursor: 'pointer' }}
            onClick={onCardClick('sale-links')}
          >
            <Typography.Text>Divulgue seu evento através destes links.</Typography.Text>
          </Card>
        </Col>
        <Col md={8}>
          <Card
            title='Lista de Participantes'
            style={{ height: '100%', cursor: 'pointer' }}
            onClick={onCardClick('presence-list')}
          >
            <Typography.Text>Gerencie e faça o Check-in dos participantes do seu evento!</Typography.Text>
          </Card>
        </Col>
        <Col md={8}>
          <Card title='Painel' style={{ height: '100%', cursor: 'pointer' }} onClick={onCardClick('event-panel')}>
            <Typography.Text>Veja o panorama de vendas do seu evento em tempo real.</Typography.Text>
          </Card>
        </Col>
        <Col md={8}>
          <Card
            title='Página do evento'
            style={{ height: '100%', cursor: 'pointer' }}
            onClick={onCardClick('event-page')}
          >
            <Typography.Text>Personalize a página da venda do seu evento.</Typography.Text>
          </Card>
        </Col>
        <Col md={8}>
          <Card title='Ofertas' style={{ height: '100%', cursor: 'pointer' }} onClick={onCardClick('offers')}>
            <Typography.Text>Crie ofertas únicas para seus participantes.</Typography.Text>
          </Card>
        </Col>
        <Col md={8}>
          <Card title='Carrinho de Compras' style={{ height: '100%', cursor: 'pointer' }} onClick={onCardClick('cart')}>
            <Typography.Text>Disponibilize todos os ingressos em sua página de vendas.</Typography.Text>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default FunctionalitiesModal;
