import React, { Fragment, PureComponent } from 'react';

import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import Toast from 'components/Shared/Toast';
import { IAttendaceList } from 'interfaces/attendanceList';
import RxOp from 'rxjs-operators';
import attendanceListService from 'services/attendanceList';

import { IProps, IState } from './types';

export default class ResendTicketDialog extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false
    };
  }

  onError = (errorMessage: string) => {
    this.setState({ loading: false });

    if (errorMessage === 'Você não possui permissão para realizar esta ação') {
      Toast.error(errorMessage, 6000);
    } else {
      Toast.error('Erro ao reenviar. Tente novamente');
    }
  };

  onSubmit = () => {
    const { eventId, userEmail, onCancel, isMultipleAction, selectedParticipants, isEventLiveOnline } = this.props;

    this.setState({ loading: true });

    if (isMultipleAction) {
      return this.submitMultiple(selectedParticipants);
    }

    attendanceListService
      .resendTicket({ eventId, userEmail })
      .pipe(RxOp.bindComponent(this), RxOp.logError())
      .subscribe({
        next: () => {
          this.setState({ loading: false });
          Toast.show(`${!!isEventLiveOnline ? 'Acesso' : 'Ingresso'} reenviado com sucesso`);
          onCancel();
        },
        error: ({ data: err }) => {
          this.onError(err.message);
        }
      });
  };

  submitMultiple = (selectedParticipants: IAttendaceList[]) => {
    const { eventId, onCancel, isEventLiveOnline, allParticipantsAreSelected } = this.props;
    const inviteKeys: string[] = [];

    selectedParticipants.map((item: any) => {
      const inviteKey = item.invite_key.split(' ');
      inviteKey.map((invite: any) => {
        if (invite.length > 0) {
          inviteKeys.push(invite);
        }
        return invite;
      });
      return item;
    });

    const postData = {
      event_id: eventId,
      invite_keys: allParticipantsAreSelected ? [] : inviteKeys,
      resend_all: allParticipantsAreSelected
    };

    attendanceListService
      .resendTicketMultiple(postData)
      .pipe(RxOp.bindComponent(this), RxOp.logError())
      .subscribe({
        next: () => {
          this.setState({ loading: false });
          Toast.show(`${!!isEventLiveOnline ? 'Acessos' : 'Ingressos'} reenviados com sucesso`);
          onCancel();
        },
        error: ({ data: err }) => {
          this.onError(err.message);
        }
      });
  };

  getSummarizedParticipantsList = () => {
    const { selectedParticipants, allParticipantsAreSelected, totalOfParticipants } = this.props;
    const participantsEmail = selectedParticipants.map(participant => participant.email);
    if (allParticipantsAreSelected && totalOfParticipants > selectedParticipants.length) {
      participantsEmail.push(
        `...mais ${totalOfParticipants - participantsEmail.length} (total ${totalOfParticipants})`
      );
    }

    return participantsEmail;
  };

  render() {
    const { loading, error } = this.state;
    const { opened, onCancel, userEmail, isMultipleAction, isEventLiveOnline } = this.props;

    return (
      <Dialog open={opened} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='sm'>
        {loading && <LoadingOutlined spin />}

        <form>
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{!!isEventLiveOnline ? 'Reenviar Acesso' : 'Reenviar Ingresso'}</div>
              <Button type='text' onClick={onCancel} id='mytickets-resend-close-button' icon={<CloseOutlined />} />
            </div>
          </DialogTitle>

          <DialogContent>
            {isMultipleAction ? (
              <Typography style={{ fontSize: 16, color: '#000000' }}>
                {`Deseja enviar novamente o e-mail de ${
                  !!isEventLiveOnline ? 'acesso' : 'ingresso'
                } para o(s) participante(s) abaixo?`}
                <br />
                {this.getSummarizedParticipantsList().map((participant, index) => (
                  <Fragment key={`${participant}-${index}`}>
                    <strong>{participant}</strong> <br />
                  </Fragment>
                ))}
              </Typography>
            ) : (
              <Typography style={{ fontSize: 16, color: '#000000' }}>
                {`Deseja enviar novamente o e-mail de ${
                  !!isEventLiveOnline ? 'acesso' : 'ingresso'
                } para o participante `}
                <strong>{userEmail}</strong>?
              </Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              type='primary'
              onClick={() => this.onSubmit()}
              disabled={loading || !!error}
              id='mytickets-resend-submit-button'
              style={{ marginTop: 24 }}
            >
              {!loading ? 'Reenviar' : 'Enviando...'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
