import { ButtonProps } from '@mui/material';

import * as S from './styles';
import { CustomizedButtonProps } from './types';

const CustomizedButton = (props: ButtonProps & CustomizedButtonProps) => {
  return <S.CustomizedButton {...props} />;
};

export default CustomizedButton;
