import { green } from '@mui/material/colors';

const variables = {
  lang: 'pt-br',
  drawerWidth: 300,
  headerHeight: 56,
  headerHeightUpSm: 64,
  tabbarHeight: 48,
  contentPadding: 12,
  contentPaddingUpSm: 56,
  containerWidth: 1280,
  mainColor: '#FFBC00',
  mainColorLight: '#EC407A',
  mainColorDark: '#AD1457',
  lightGrey: '#596375',
  danger: '#f44336',
  success: green[500],
  lightSuccess: green[300],
  boxShadow:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  colors: {
    facebook: '#3b5998',
    google: '#de5245',
    textDefault: '#546E7A',
    titleDefault: '#37474F',
    textLight: '#78909C',
    grayDefault: '#B0BEC5',
    lighterGray: '#ECEFF1'
  },
  button: {
    main: '#D81B60',
    hover: '#EC407A',
    pressed: '#AD1457',
    disabled: '#B0BEC5',
    textDefault: '#ffffff',
    textDefaultDisabled: '#546E7A',
    secondaryDisabled: '#B0BEC5'
  }
};

export default variables;
