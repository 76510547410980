import { IEvent } from 'interfaces/event';

export type PageParams = {
  id: string;
};

export interface ActionProps {
  type: ActionTypes;
  value?: IEvent | string | boolean;
}

export enum ActionTypes {
  archiveEvent = 'event/archive',
  duplicateEvent = 'event/duplicate'
}
export interface StateProps {
  duplicateEvent: boolean;
  archiveEvent: boolean;
}
