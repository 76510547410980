import { EventRoutes } from './types';

const eventRoutes: EventRoutes[] = [
  {
    path: '/confirm/:hash',
    import: 'Events/ValidateStartCheckin'
  }
];

export default eventRoutes;
