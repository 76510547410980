import { AppStyle } from 'decorators/withStyles';
import { setMargin } from 'helpers/functions';

const styles: AppStyle = () => ({
  labelStrong: { fontWeight: 600 },
  formControlLabel: {
    ...setMargin(null, null, null, 0)
  },
  radioGroup: {
    ...setMargin(null, null, 16, 15)
  },
  checkList: {
    ...setMargin(null, null, null, 15),
    display: 'flex',
    flexDirection: 'column'
  }
});

export default styles;
