import { IImageSelectorResult } from 'components/Shared/ImageSelector';

import IFormAdapter from '@eduzz/houston-core/formAdapter';

export enum ActionTypes {
  backgroundOption = 'option/background',
  textColorHeader = 'field/HeaderColor',
  textColorBackground = 'field/BackgroundColor',
  cropperConfig = 'config/Cropper',
  cropperResetConfig = 'config/ResetCropper',
  imageHeader = 'image/Header',
  imageBackground = 'image/Background'
}

export interface CropperConfigProps {
  opened: boolean;
  width: number;
  height: number;
  onComplete: (value: IImageSelectorResult) => void;
}

export interface StateProps {
  backgroundOption: string;
  textColorHeader: string;
  textColorBackground: string;
  cropperConfig: CropperConfigProps;
  imageHeader: IImageSelectorResult;
  imageBackground: IImageSelectorResult;
}

export interface ActionProps {
  type: ActionTypes;
  value?: string | CropperConfigProps | IImageSelectorResult;
}

export interface EventHeadingProps {
  form: IFormAdapter<Record<string, never>>;
}
