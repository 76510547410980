import React from 'react';

import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import { Col, Row, Space, Typography, Upload, Progress, Divider, Button } from 'antd';
import useInterval from 'hooks/useInterval';
import { ISheetInfo } from 'interfaces/sheet';
import { useFormContext } from 'react-hook-form';
import attendanceListService from 'services/attendanceList';

import { EventDetailContext } from '../../..';
import * as S from './styles';

interface UploadingState {
  file_name: string;
  msq_id: string;
  showErrorList: boolean;
}

const UploadStep = ({ enableForward, hasUploadCompleted }) => {
  const { eventDetail, addedParticipant } = React.useContext(EventDetailContext);
  const form = useFormContext();

  const [state, setState] = React.useReducer(
    (state: UploadingState, newState: Partial<UploadingState>) => ({
      ...state,
      ...newState
    }),
    {
      file_name: '',
      msq_id: '',
      showErrorList: false
    }
  );

  const { Dragger } = Upload;

  type UploadStatus =
    | { status: 'doing'; progressPercentage: number }
    | { status: 'fail'; errorList: string[]; progressPercentage: number }
    | { status: 'done'; progressPercentage: number }
    | { status: 'removed'; progressPercentage: number };

  const [upload, setUpload] = React.useState<UploadStatus>();
  const [isCheckingUploadStatus, setIsCheckingUploadStatus] = React.useState(false);
  const ticketId = form.getValues('ticket_id');

  //teve que por esse cara pq o antd é doidão
  const dummyRequest = options => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const onChange = info => {
    const reader = new FileReader();
    reader.readAsDataURL(info.file.originFileObj as any);
    const status = info.file.status;

    if (status === 'removed') {
      setUpload({ status: 'removed', progressPercentage: 0 });
      return;
    }

    if (status === 'uploading') {
      return;
    }
    if (info.file.status === 'error') {
      setUpload({ status: 'fail', errorList: ['Erro no arquivo'], progressPercentage: 0 });
      setState({ showErrorList: true });
      return;
    }

    reader.onload = e => {
      const result = e.target.result as any;
      const file_content = result.split(',')[1];
      const fileName = info.file.name;
      const cleanBase64 = file_content.substring(file_content.split(',')[1], file_content.length);

      setState({ file_name: fileName });

      const data: ISheetInfo = {
        event_id: eventDetail.id,
        ticket_id: ticketId,
        file_name: fileName,
        file_upload: cleanBase64,
        use_stock: true
      };

      attendanceListService.importSheet(data).subscribe({
        next: res => {
          const msqId = res.data[0].msq_id;

          attendanceListService.uploadStatus(msqId).subscribe({
            next: res => {
              const { status, progressPercentage, errorList } = res.data[0];

              if (status === 'fail') {
                setUpload({ status, errorList, progressPercentage: 0 });
                return;
              }

              setState({ msq_id: msqId });
              setUpload({ status, progressPercentage });
            },
            error: () => {
              setUpload({ status: 'fail', errorList: ['Erro no arquivo'], progressPercentage: 0 });
            }
          });
        },
        error: () => {
          setUpload({ status: 'fail', errorList: ['Erro no arquivo'], progressPercentage: 0 });
        }
      });
    };
  };

  useInterval(() => {
    if (isCheckingUploadStatus) return;
    if (!upload) return;
    if (upload.status === 'done' || upload.status === 'fail' || upload.status === 'removed') return;

    setIsCheckingUploadStatus(true);
    attendanceListService.uploadStatus(state.msq_id).subscribe({
      next: res => {
        const { status, progressPercentage, errorList } = res.data[0];
        setIsCheckingUploadStatus(false);

        if (status === 'fail') {
          setUpload({ status, errorList, progressPercentage: 0 });
          return;
        }

        if (progressPercentage === 100) {
          form.setValue('file_name', state.file_name);
          addedParticipant();
          enableForward();
          hasUploadCompleted();
        }
        setUpload({ status, progressPercentage });
      }
    });
  }, 3000);

  return (
    <React.Fragment>
      <Row gutter={[8, 12]}>
        <Col xs={24}>
          <Typography.Text>Faça o upload do arquivo de sua planilha de participantes.</Typography.Text>
        </Col>
        {!['done', 'fail', 'doing'].includes(upload?.status) && (
          <Col xs={24} style={{ marginBottom: '70px' }}>
            <Typography.Text>Upload da planilha</Typography.Text>

            <Dragger
              maxCount={1}
              id={'sheet-upload'}
              accept='.xlsx, .csv'
              onChange={onChange}
              customRequest={dummyRequest}
              disabled={upload?.status === 'done'}
            >
              <Space align='center' direction='vertical'>
                <DownloadOutlined />
                <Typography.Text>Arraste e solte aqui</Typography.Text>
                <Typography.Text>ou</Typography.Text>
                <Typography.Link style={{ color: 'black', textDecoration: 'underline' }}>
                  Procure os arquivos
                </Typography.Link>
              </Space>
            </Dragger>
          </Col>
        )}
      </Row>
      {![undefined, 'removed'].includes(upload?.status) && (
        <Row gutter={[4, 12]}>
          <Col xs={24}>
            {!['fail'].includes(upload?.status) && (
              <Progress
                percent={parseFloat(upload?.progressPercentage.toFixed(0))}
                status={upload?.status === 'fail' ? 'exception' : null}
              />
            )}
          </Col>
          <Col xs={24}>
            <FileTextOutlined style={{ marginRight: '5px' }} />
            <Typography.Text>{state.file_name}</Typography.Text>
            <Divider style={{ margin: '5px' }} />
          </Col>
        </Row>
      )}
      {upload?.status === 'fail' && (
        <React.Fragment>
          <S.Container>
            <Typography.Text>
              Não foi possível realizar o upload da planilha para sua lista de participantes por causa dos seguintes
              erros:
            </Typography.Text>
            {upload?.errorList?.map(error => (
              <div key={error}>{error}</div>
            ))}
          </S.Container>
          <Button
            key='retry-button'
            type='primary'
            size='large'
            style={{ color: '#000000' }}
            onClick={() => {
              setState({ file_name: '', msq_id: '', showErrorList: false });
              setUpload({ status: 'removed', progressPercentage: 0 });
            }}
          >
            Tentar novamente
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default UploadStep;
