import { BreakPointsValues } from 'styles/types';

const breakpoints = {
  xs: `min-width: ${BreakPointsValues.xs}`,
  sm: `min-width: ${BreakPointsValues.sm}`,
  md: `min-width: ${BreakPointsValues.md}`,
  lg: `min-width: ${BreakPointsValues.lg}`,
  xl: `min-width: ${BreakPointsValues.xl}`
};

export default breakpoints;
