import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, lineHeight, customBreakpoints: breakpoints } }) => css`
    .action-image-adornment {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .event-heading-main-title {
      font-weight: ${fontWeight('bold')};
      line-height: ${lineHeight('compact')};
      font-size: 14px;
      color: ${colors.primary.contrastText};
      margin: 16px 0 18px;
    }

    .event-heading-color-field {
      div {
        div {
          input {
            z-index: 1;
          }
          div {
            z-index: 1;
          }

          fieldset {
            background-color: white;
          }
        }
      }
    }

    .event-heading-container {
      margin: 12px 0 0 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: white;
      border-radius: 8px;
      border: solid 1px #eceff1;
      padding: 12px;

      .event-heading-title {
        font-weight: ${fontWeight('semibold')};
        line-height: ${lineHeight('normal')};
        font-size: 14px;
        color: ${colors.primary.contrastText};
      }
    }

    .event-heading-text-field {
      background-color: white;

      input {
        pointer-events: unset;
        cursor: unset;
      }

      label {
        font-weight: ${fontWeight('semibold')};
        //styleName: small/Semibold;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: ${colors.primary.contrastText};

        &.last {
          margin: 0;
        }
      }

      &.last {
        margin: 0;
      }
    }
    .event-heading-logo-container {
      .event-heading-logo-image-container {
        display: none;
      }
    }

    .event-heading-hr {
      margin: 16px 0 0 0;
      border: ${colors.grey[200]} solid 1px;
    }

    @media (${breakpoints.md}) {
      .event-heading-main-title {
        margin: 24px 0 27px;
      }

      .event-heading-logo-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin: 0 0 18px 0;

        .event-heading-logo-image-container {
          height: 68px;
          width: 79px;
          border: solid 1px #b0bec5;
          margin: 0 16px 0 0;
          display: flex;
          border-radius: 4px;

          .event-heading-image {
            width: 100%;
            border-radius: 4px;
          }
        }

        .event-heading-text-field {
          margin: 0;
          width: 100%;
        }
      }

      .event-heading-container {
        margin: 16px 0 0 0;

        .event-heading-radio-container {
          display: flex;
          flex-direction: row;
          margin: 4px 0 10px 0;
        }
      }

      .event-heading-hr {
        margin: 23px 0 0 0;
      }
    }
  `}
`;
