import { AppStyle } from 'decorators/withStyles';
import { setMargin } from 'helpers/functions';

const styles: AppStyle = () => ({
  root: {
    maxHeight: 90
  },
  input: {
    borderRadius: '4px 0 0 4px',
    height: '40px'
  },
  sketchPicker: {
    ...setMargin(8, null, null, null),
    position: 'absolute',
    zIndex: 1
  }
});

export default styles;
