import { GeneralRoutes } from './types';

const generalRoutes: GeneralRoutes[] = [
  {
    path: '/gift_ticket/:id',
    import: 'Public/GiftTicket'
  },
  {
    path: '/transfer/:id',
    import: 'Public/GiftTicket'
  }
];

export default generalRoutes;
