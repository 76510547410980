import * as rxjs from 'rxjs';
import { switchMap, map } from 'rxjs-operators';

import { ACCOUNT_PARTNER, ACCOUNT_ENV } from '../settings';
import apiService from './api';
import tokenService, { TokenService } from './token';

export class LoginService {
  private loggingIn = false;

  constructor(private tokenService: TokenService) {}

  private parseAuth(step: rxjs.Observable<any>) {
    step.subscribe(({ jwt_token: token, jwt_refresh_token: refresh }) => {
      this.tokenService.setTokens({ token, refresh });
      window.history.pushState(null, null, '/');
      this.loggingIn = false;
    });
  }

  public auth() {
    if (this.loggingIn) {
      return;
    }

    const refreshToken = window.sessionStorage.getItem('refreshToken');

    if (refreshToken) {
      this.parseAuth(
        apiService.post('/auth_v2/refresh', { jwt_refresh_token: refreshToken }).pipe(map(response => response?.data))
      );
    } else {
      const newLogin: rxjs.Observable<string> = (window as any).Eduzz.Accounts.login(ACCOUNT_PARTNER, ACCOUNT_ENV);

      this.parseAuth(
        newLogin.pipe(
          switchMap(token => apiService.post('/auth_v2/login', { token })),
          map(response => response?.data)
        )
      );
    }
  }

  public logout() {
    this.tokenService.clearToken();
  }
}

const loginService = new LoginService(tokenService);
export default loginService;
