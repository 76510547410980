import styled, { css } from 'styled-components';
import media from 'styled-media-query';

import { wrapperProps } from './types';

const wrapperModifiers = {
  enable: () => css`
    pointer-events: unset;
    cursor: pointer;

    ${Step} {
      color: #000000;
      border-color: #ffbc00;
      background-color: #fff;
      font-weight: 400;
    }

    ${WrapperTitles} {
      h2 {
        color: #000000;
      }
    }
  `,

  lastItem: () => css`
    justify-content: unset;
    width: fit-content;

    ${Line} {
      display: none;
    }
  `,

  active: () => css`
    ${Step} {
      background-color: #ffbc00;
      font-weight: 800;
    }
  `
};

export const Wrapper = styled.div<wrapperProps>`
  ${({ itsLast, isEnable, active }) => css`
    align-items: center;
    cursor: default;
    display: flex;
    pointer-events: none;
    width: 100%;

    ${WrapperTitles} {
      display: none;
    }

    ${media.greaterThan('medium')`

      ${WrapperTitles}{
        display: block;
      };

    `}

    ${itsLast && wrapperModifiers.lastItem()}
    ${isEnable && wrapperModifiers.enable()}
    ${active && wrapperModifiers.active()}
  `}
`;

export const Step = styled.div`
  align-items: center;
  border-color: #ccc;
  border-radius: 50%;
  border: 2px solid;
  color: #ccc;
  display: flex;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
`;

export const Line = styled.hr`
  border: 1px solid;
  border-color: #ffbc00;
  width: 100%;
  height: fit-content;
  margin: 4px;
`;

export const WrapperTitles = styled.div`
  margin: 0 0 0 8px;

  h2,
  h3 {
    color: #000000;
    font-size: 1rem;
    font-weight: 400;
  }

  h2 {
    white-space: nowrap;
  }

  h3 {
    color: #000000;
  }
`;
