import { forwardRef } from 'react';

import IntlCurrencyInput from 'react-intl-currency-input';

import { CurrencyFormatProps } from './types';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  }
};

const CurrencyFormat = forwardRef<any, CurrencyFormatProps>((props, ref) => {
  const { onChange, name } = props;
  return (
    <IntlCurrencyInput
      {...props}
      ref={ref}
      currency='BRL'
      config={currencyConfig}
      onChange={(_, pureValue: string) =>
        onChange({
          target: {
            name: name,
            value: pureValue
          }
        })
      }
    />
  );
});

export default CurrencyFormat;
