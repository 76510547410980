import React, { Reducer, useContext, useReducer, useCallback, memo } from 'react';

import CloseOutlined from '@ant-design/icons/CloseOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { Typography, Input } from 'components/Shared/Antd';
import Field from 'components/Shared/Field';
import ImageSelector, { IImageSelectorResult } from 'components/Shared/ImageSelector';
import { useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';

import FormContext from '../../context';
import * as S from './styles';
import { StateProps, ActionProps, ActionTypes, CropperConfigProps } from './types';

const initialState: StateProps = {
  cropperConfig: { height: 500, width: 500, opened: false, onComplete: value => JSON.stringify(value) },
  localimage: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  resumeimage: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  reactQuillClassNamePromation: '',
  reactQuillClassNameDescription: ''
};

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.cropperConfig:
      return { ...state, cropperConfig: action.value as CropperConfigProps };

    case ActionTypes.cropperResetConfig:
      return { ...state, cropperConfig: { ...initialState.cropperConfig } };

    case ActionTypes.localimage:
      return { ...state, localimage: action.value as IImageSelectorResult };

    case ActionTypes.resumeimage:
      return { ...state, resumeimage: action.value as IImageSelectorResult };

    case ActionTypes.reactQuillClassNamePromation:
      return { ...state, reactQuillClassNamePromation: action.value as string };

    case ActionTypes.reactQuillClassNameDescription:
      return { ...state, reactQuillClassNameDescription: action.value as string };

    default:
      return state;
  }
};

const EventContent = memo(() => {
  const { customTemplateData, handleVerifyData } = useContext(FormContext);
  const form = useFormContext();

  const page_config = customTemplateData.pageContent.page_config;

  const [
    { localimage, resumeimage, cropperConfig, reactQuillClassNamePromation, reactQuillClassNameDescription },
    dispatch
  ] = useReducer(reducer, {
    ...initialState,
    localimage: {
      base64: page_config.localimage,
      filename: page_config.localimageFileName,
      image: { height: 0, width: 0, name: '', url: page_config.localimage || '' }
    },
    resumeimage: {
      base64: page_config.resumeimage,
      filename: page_config.resumeimageFileName,
      image: { height: 0, width: 0, name: '', url: page_config.resumeimage || '' }
    }
  });

  const handleFocus = useCallback(
    (type: ActionTypes.reactQuillClassNameDescription | ActionTypes.reactQuillClassNamePromation, value: boolean) => {
      dispatch({ type, value: value ? 'focus' : '' });
    },
    []
  );

  const handleCloseCropper = useCallback(() => {
    handleVerifyData();
    dispatch({ type: ActionTypes.cropperResetConfig });
  }, [handleVerifyData]);

  const handleSetCropperConfig = useCallback(
    (config: CropperConfigProps) => {
      handleVerifyData();
      dispatch({ type: ActionTypes.cropperConfig, value: config });
    },
    [handleVerifyData]
  );

  const handleCropperImage = useCallback(
    (type: ActionTypes.localimage | ActionTypes.resumeimage, name: string) => {
      const onComplete = (value: IImageSelectorResult) => {
        if (value) {
          form.setValue(name, value.base64.replace(/^data:image\/[a-z]+;base64,/, ''));
          form.setValue(`${name}FileName`, value.filename);
          handleVerifyData();
          dispatch({ type, value });
        }
        handleCloseCropper();
      };

      handleSetCropperConfig({ opened: true, height: 500, width: 500, onComplete });
    },
    [form, handleCloseCropper, handleSetCropperConfig, handleVerifyData]
  );

  const handleRemoveImage = useCallback(
    (type: ActionTypes.resumeimage | ActionTypes.localimage, name: string) => {
      form.setValue(name, '');
      form.setValue(`${name}FileName`, '');
      handleVerifyData();
      dispatch({ type, value: { ...initialState.localimage } });
    },
    [form, handleVerifyData]
  );

  const handleSetQuillValue = useCallback(
    (name: string, value: string) => {
      form.setValue(name, value);
    },
    [form]
  );
  return (
    <S.Container>
      <p className='event-content-title'>CONTEÚDO</p>

      <Typography.Text>Vídeo de apresentação</Typography.Text>
      <Field
        name='main_video_url'
        render={
          <Input
            onChange={handleVerifyData}
            placeholder='(URL do Youtube ou Vimeo)'
            size='large'
            defaultValue={page_config.main_video_url}
          />
        }
      />
      <S.RichTextOutlined className={`${reactQuillClassNameDescription} teste`} style={{ marginTop: '16px' }}>
        <fieldset>
          <legend>Descrição do evento</legend>
          <ReactQuill
            onFocus={() => handleFocus(ActionTypes.reactQuillClassNameDescription, true)}
            onBlur={() => handleFocus(ActionTypes.reactQuillClassNameDescription, false)}
            onKeyPress={handleVerifyData}
            className='rich-text-oulined-input'
            defaultValue={page_config.v2_desc}
            onChange={value => handleSetQuillValue('v2_desc', value)}
          />
        </fieldset>
      </S.RichTextOutlined>

      {page_config.templateVersion === 'v1' && (
        <>
          <div className='event-container-image'>
            <div className='event-container-default-image'>
              {resumeimage.base64 && <img src={resumeimage.base64} alt='' className='event-content-image' />}
            </div>
            <div style={{ width: '100%' }}>
              <Typography.Text>Imagem ilustrativa do evento</Typography.Text>
              <Input
                name='resumeimageFileName'
                onChange={handleVerifyData}
                disabled
                size='large'
                className='event-content-text-field'
                style={{ pointerEvents: 'unset', cursor: 'unset' }}
                value={resumeimage.filename}
                suffix={
                  <div
                    className='action-image-adornment'
                    onClick={() => handleRemoveImage(ActionTypes.resumeimage, 'resumeimage')}
                  >
                    <CloseOutlined style={{ color: '#000000' }} />
                  </div>
                }
                prefix={
                  <div
                    className='action-image-adornment'
                    onClick={() => handleCropperImage(ActionTypes.resumeimage, 'resumeimage')}
                  >
                    <UploadOutlined style={{ color: '#000000' }} />
                  </div>
                }
              />
            </div>
            <ImageSelector {...cropperConfig} />
          </div>

          <div className='event-container-image'>
            <div className='event-container-default-image'>
              {localimage.base64 && <img src={localimage.base64} alt='' className='event-content-image' />}
            </div>

            <div style={{ width: '100%' }}>
              <Typography.Text>Imagem do local</Typography.Text>
              <Input
                name='localimageFileName'
                onChange={handleVerifyData}
                disabled
                size='large'
                className='event-content-text-field'
                style={{ pointerEvents: 'unset', cursor: 'unset' }}
                value={localimage.filename}
                suffix={
                  <div
                    className='action-image-adornment'
                    onClick={() => handleRemoveImage(ActionTypes.localimage, 'localimage')}
                  >
                    <CloseOutlined style={{ color: '#000000' }} />
                  </div>
                }
                prefix={
                  <div
                    className='action-image-adornment'
                    onClick={() => handleCropperImage(ActionTypes.localimage, 'localimage')}
                  >
                    <UploadOutlined style={{ color: '#000000' }} />
                  </div>
                }
              />
            </div>
          </div>
        </>
      )}

      <S.RichTextOutlined className={`${reactQuillClassNamePromation} teste`}>
        <fieldset>
          <legend>Programação</legend>
          <ReactQuill
            onFocus={() => handleFocus(ActionTypes.reactQuillClassNamePromation, true)}
            onBlur={() => handleFocus(ActionTypes.reactQuillClassNamePromation, false)}
            onKeyPress={handleVerifyData}
            className='rich-text-oulined-input'
            defaultValue={page_config.scheduletexthtml}
            onChange={value => handleSetQuillValue('scheduletexthtml', value)}
          />
        </fieldset>
      </S.RichTextOutlined>
    </S.Container>
  );
});

export default EventContent;
