import { ReactNode } from 'react';

import { Button } from 'antd';

import * as S from './styles';

type ActionsProps = {
  Button?: typeof Button;
  children?: ReactNode;
};

const Actions = ({ children }: ActionsProps) => {
  return <S.Wrapper>{children}</S.Wrapper>;
};

Actions.Button = Button;

export default Actions;
