import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    margin: auto;

    h1 {
      font-weight: ${fontWeight('semibold')};
      color: ${colors.primary.contrastText};
      font-size: 16px;
    }

    .custom-offer-description {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('regular')};
      margin: 20px 0 12px 0;
    }

    .discount-coupon-description {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('regular')};
      margin: 0 0 12px 0;
    }

    .custom-offer-tutorial {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('regular')};
      margin: 0 0 0 0;
    }

    .discount-coupon-link {
      align-items: center;
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('regular')};
      font-size: 14px;
      text-decoration: underline;
      margin: 8px 0 16px 0;
      cursor: pointer;
    }

    .custom-offer-link {
      display: flex;
      align-items: center;
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('semibold')};
      font-size: 14px;
      text-decoration: underline;
      margin: 8px 0 16px 0;
      cursor: pointer;
    }

    .event-links-sales-message-issue {
      background-color: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 11px 13px;
      color: #000000;
      font-size: inherit;

      a {
        color: #000000;
      }
    }

    .custom-offers-button {
      display: flex;
      justify-content: flex-end;
      margin: 26px 0 0 0;
    }

    @media (${breakpoints.md}) {
      .custom-offers-button {
        margin: 36px 0 0 0;
      }

      h1 {
        font-size: 20px;
      }
    }
  `}
`;
