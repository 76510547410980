import { IOffer } from 'interfaces/offer';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';

import apiService, { ApiService } from '../api';
import { AddOffers, GetOfferLink, DeleteOffer, DeleteOfferResponse } from './types';

export class OfferService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  public mountCart(offerId: string): Rx.Observable<GetOfferLink> {
    return this.apiService.get(`/promotional_offer/mount_cart/${offerId}`);
  }

  public listOffers(eventId: string): Rx.Observable<IOffer[]> {
    return this.apiService
      .get(`/promotional_offer/list_by_event/${eventId}`)
      .pipe(RxOp.map(response => response.data[0].data));
  }

  public addOffer(data: AddOffers): Rx.Observable<any> {
    return this.apiService.post('/promotional_offer/add_offer', data);
  }

  public deleteOffer(data: DeleteOffer): Rx.Observable<DeleteOfferResponse> {
    return this.apiService.post('/promotional_offer/delete', data);
  }
}

const offerService = new OfferService(apiService);
export default offerService;
