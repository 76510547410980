import React, { useState, useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Alert, Typography } from 'antd';
import ptBR from 'date-fns/locale/pt-BR';
import { DateDetail } from 'interfaces/event';
import { useForm, Controller, FieldArrayMethodProps } from 'react-hook-form';
import { v4 } from 'uuid';

import FormContext from '../../../../Context';
import { schemaEventDates } from './scheme';
import * as S from './styles';

interface EventDateModalProps {
  appendEventDates: (value: Partial<DateDetail> | Partial<DateDetail>[], options?: FieldArrayMethodProps) => void;
  eventDates: DateDetail[];
  editValues?: DateDetail;
  onClose: () => void;
  open: boolean;
  updateEventDates: (index: number, value: Partial<DateDetail>) => void;
  openTicketsPanel: () => void;
}

export type DateDetailForm = DateDetail & Pick<EventDateModalProps, 'eventDates'>;

const inputStyle = {
  field: {
    sx: {
      width: '100%',
      '.MuiOutlinedInput-input': {
        padding: '12px'
      },
      label: {
        transform: 'translate(4px, 50%)'
      },
      'label.Mui-focused': {
        transform: 'translate(14px, -9px) scale(0.75)'
      },
      'label.MuiFormLabel-filled': {
        transform: 'translate(14px, -9px) scale(0.75)'
      }
    }
  }
};

const EventDateForm = ({
  appendEventDates,
  eventDates,
  editValues,
  onClose,
  updateEventDates,
  openTicketsPanel
}: EventDateModalProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues
  } = useForm<DateDetailForm>({
    defaultValues: {
      eventDates: [...eventDates],
      ...editValues
    },
    resolver: yupResolver(schemaEventDates),
    mode: 'onChange'
  });

  const { isCreate } = useContext(FormContext);

  const [dateException, setdateException] = useState(false);

  const dateError = (): boolean => {
    return shouldRenderDatesConflictError() || !!errors?.date;
  };

  const dateErrorMessage = (): string => {
    if (shouldRenderDatesConflictError()) {
      return '';
    }

    return errors?.date?.message;
  };

  const startTimeError = (): boolean => {
    return shouldRenderDatesConflictError() || !!errors?.start_time;
  };

  const startTimeErrorMessage = (): string => {
    if (shouldRenderDatesConflictError()) {
      return '';
    }

    return errors?.start_time?.message;
  };

  const endTimeError = (): boolean => {
    return shouldRenderDatesConflictError() || !!errors?.end_time;
  };

  const endTimeErrorMessage = (): string => {
    if (shouldRenderDatesConflictError()) {
      return '';
    }

    return errors?.end_time?.message;
  };

  const shouldRenderDatesConflictError = () => {
    return errors?.end_time?.type === 'Conflito de datas';
  };

  const handleSave = (data: DateDetailForm) => {
    delete data.eventDates;

    const eventDateIndex = eventDates.findIndex(eventDate => eventDate.arrayId === data.arrayId);

    if (!data.date) {
      setdateException(true);
      return;
    }

    if (eventDates[eventDateIndex]) {
      updateEventDates(eventDateIndex, data);
      return onClose();
    }

    const values: DateDetail = { ...data, identificator: v4(), arrayId: v4() };
    appendEventDates(values);
    isCreate && openTicketsPanel();
    return onClose();
  };

  const title = getValues('arrayId') ? 'Editar dia do evento' : 'Adicionar dia do evento';

  return (
    <S.Wrapper>
      <Typography.Title level={5}>{title}</Typography.Title>

      <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
        <Grid container spacing={3}>
          <Grid container item spacing={1}>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                defaultValue={null}
                name='date'
                render={({ field: { onChange, value } }) => (
                  <DesktopDatePicker
                    label='Selecione a data'
                    disablePast
                    format='dd/MM/yyyy'
                    onChange={onChange}
                    data-testid='date'
                    value={value}
                    slotProps={inputStyle}
                  />
                )}
              />
              {dateError() && <S.ErrorMessage>{dateErrorMessage()}</S.ErrorMessage>}
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                defaultValue={null}
                name='start_time'
                render={({ field: { onChange, value } }) => (
                  <DesktopTimePicker
                    onChange={onChange}
                    label='Início'
                    data-testid='start-time'
                    value={value ? new Date(value) : null}
                    slotProps={inputStyle}
                  />
                )}
              />
              {startTimeError() && <S.ErrorMessage>{startTimeErrorMessage()}</S.ErrorMessage>}
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                defaultValue={null}
                name='end_time'
                render={({ field: { onChange, value } }) => (
                  <DesktopTimePicker
                    onChange={onChange}
                    label='Término'
                    data-testid='end-time'
                    value={value ? new Date(value) : null}
                    slotProps={inputStyle}
                  />
                )}
              />
              {endTimeError() && <S.ErrorMessage>{endTimeErrorMessage()}</S.ErrorMessage>}
            </Grid>
            <Grid item xs={12} md={4}>
              <S.StyledButton
                type='default'
                onClick={() => onClose()}
                size='large'
                style={{
                  height: '47px'
                }}
              >
                Cancelar
              </S.StyledButton>
              <S.StyledButton
                onClick={handleSubmit(handleSave)}
                type='primary'
                disabled={shouldRenderDatesConflictError()}
                size='large'
                style={{
                  height: '47px'
                }}
              >
                Salvar
              </S.StyledButton>
            </Grid>
          </Grid>

          {shouldRenderDatesConflictError() && (
            <Grid item xs={12}>
              <Alert
                type='error'
                message='Conflito de horários, já existe um evento neste horáro, informe um novo horário para salvar.'
                showIcon
              />
            </Grid>
          )}

          {dateException && (
            <Grid alignItems='start' container item>
              <Alert type='error' message='Data inválida.' showIcon />
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    </S.Wrapper>
  );
};

export default EventDateForm;
