import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, lineHeight } }) => css`
    max-width: 370px;
    margin: 16px 0;

    .event-date-title {
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('semibold')};
      line-height: ${lineHeight('compact')};
    }

    .event-date-content {
      hr {
        margin: 4px 0;
        color: ${colors.primary.contrastText};
        opacity: 0.2;
      }

      .event-date-info {
        display: flex;
        flex-direction: row;
        margin: 9.5px 20px 0 0px;
        justify-content: space-between;

        .event-date-date {
          align-items: center;
          display: flex;
        }

        .event-date-time {
          align-items: center;
          display: flex;
        }

        .event-date-date,
        .event-date-time {
          p {
            color: ${colors.primary.contrastText};
            margin: 0 0 0 10px;
          }
        }

        svg {
          fill: ${colors.primary.contrastText};
        }
      }
    }
  `}
`;
