import { GeneralRoutes } from './types';

const generalRoutes: GeneralRoutes[] = [
  {
    path: '/home',
    import: 'Admin/Home'
  },
  {
    path: '/help-center',
    import: 'Admin/HelpCenter'
  }
];

export default generalRoutes;
