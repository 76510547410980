import { Button, Col, Row } from 'antd';

interface IProps {
  allowAction: boolean;
  selectedItems: number;
  isSelected: boolean;
  onClick: () => void;
}

export default function SelectAllPagesItems({ allowAction, selectedItems, isSelected, onClick }: IProps) {
  return (
    <Row style={{ marginBottom: '16px' }}>
      {allowAction && (
        <Col>
          <Button onClick={onClick} style={{ paddingLeft: 0 }} type='link'>
            {isSelected ? 'Limpar seleção' : 'Selecionar de todas as páginas'}
          </Button>
        </Col>
      )}
      <Col>
        <p style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <b>{selectedItems === 0 ? 'Nenhum' : selectedItems}</b>
          &nbsp; {selectedItems > 1 ? 'itens selecionados' : 'item selecionado'}
        </p>
      </Col>
    </Row>
  );
}
