import { AppStyle } from 'decorators/withStyles';

const styles: AppStyle = theme => ({
  color: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none'
  }
});

export default styles;
