import { TableCell, TableRow } from '@mui/material';
import styled from 'styled-components';

type CustomizedTableCellProps = {
  header?: boolean;
};
export const CustomizedTableRow = styled(TableRow)`
  background-color: #ffffff;
`;

export const CustomizedTableCell = styled(TableCell)<CustomizedTableCellProps>`
  color: ${props => props.header && '#000000'};
  font-family: 'Roboto', sans-serif;
  font-size: ${props => (props.header ? '1rem' : '0.875rem')};
  font-weight: ${props => props.header && '500'};
  line-height: ${props => props.header && '1.5rem'};
  padding: ${props => props.header && '16px'};
`;
