import { EventHeader } from 'components/Presentational/EventHeader/types';
import { IEventPageEdit } from 'interfaces/event';

export enum ActionTypes {
  hasChangedData = 'data/change',
  hasSavedData = 'data/saved/sucess',
  isSaving = 'data/save',
  draftSaved = 'data/save/draft',
  openPreview = 'openModal/preview'
}
export interface StateProps {
  hasChangedData: boolean;
  openPreview: boolean;
  isSaving: boolean;
  draftSaved: boolean;
}

export interface ActionProps {
  type: ActionTypes;
  value?: boolean;
}

export type PageParams = {
  id: string;
};

export type PageContent = {
  page_config: IEventPageEdit;
  sale_url: string;
  description: string;
  draft_description: string;
  draft_page_config: IEventPageEdit;
};

export interface CustomTemplateData {
  eventHeader: EventHeader;
  pageContent: PageContent;
}
