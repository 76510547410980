import React from 'react';

import useInterval from 'hooks/useInterval';

const VerifyEventDetails = ({
  loadEventDetailsAction,
  jobsLotPending
}: {
  loadEventDetailsAction?: any;
  jobsLotPending?: string;
}) => {
  useInterval(
    () => {
      loadEventDetailsAction();
    },
    jobsLotPending ? 5000 : null
  );
  return <></>;
};
export default VerifyEventDetails;
