import React from 'react';

import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { Button, Input, Typography } from 'antd';
import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';
import { ExternalLinkIcon } from 'helpers/customIcons';

import * as S from './styles';
import { ModalLinkProps } from './types';

const ModalLink = ({ onClose, offerLink, ...props }: ModalLinkProps) => {
  return (
    <S.Container {...props}>
      <div className='modal-link-content'>
        <div className='modal-link-header'>
          <h2>Link de divulgação da oferta</h2>
          <div className='modal-link-cancel' onClick={onClose}>
            <CloseOutlined />
          </div>
        </div>
        <div className='modal-link-advice'>
          <WarningOutlined />
          <p>
            Atenção! Por segurança, este link é válido apenas para a primeira compra, sendo bloqueado após a compra
            concluída.
          </p>
        </div>
        <div className='modal-link-fields'>
          <Input
            disabled
            prefix={
              <Typography.Link target='_blank' href={offerLink} rel='noreferrer'>
                <ExternalLinkIcon style={{ fontSize: '16px', color: '#000000' }} />
              </Typography.Link>
            }
            value={offerLink}
            style={{ marginRight: '10px' }}
          />
          <CustomButtonAntd
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(offerLink);
            }}
            className='desktop'
          >
            Copiar
          </CustomButtonAntd>

          <Button
            onClick={() => {
              navigator.clipboard.writeText(offerLink);
            }}
            className='mobile'
            icon={<CopyOutlined />}
          >
            Copiar link
          </Button>
        </div>
      </div>
    </S.Container>
  );
};
export default ModalLink;
