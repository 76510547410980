import React, { useState, useEffect, useCallback, useContext } from 'react';

import EnvironmentFilled from '@ant-design/icons/EnvironmentFilled';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { Button, Tooltip } from 'antd';
import MapAutoComplete from 'components/Container/MapAutoComplete';
import { AddressData } from 'components/Container/MapAutoComplete/types';
import ModalNotifyEdit from 'components/Presentational/ModalNotifyEdit';
import GoogleMaps from 'components/Shared/GoogleMaps';
import { IEventForm } from 'interfaces/event';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import FormContext from '../../../Context';
import Actions from '../Actions';
import * as S from './styles';

const FormTwo = () => {
  const [notFoundMyAddressCheckBox, setNotFoundMyAddressCheckBox] = useState(false);
  const [mapAutoCompleteValue, setMapAutoCompleteValue] = useState('');
  const [googleMapsLocation, setGoogleMapsLocation] = useState<any>('Brasil');
  const [openModalNotifyEdit, setOpenModalNotifyEdit] = useState(false);
  const [modalNotifyOption, setModalNotifyOption] = useState(1);

  const { handleEdit, isCreate, loadingEdit, jobsLotPending, setCurrentStep, stepsEnables, setStepsEnables } =
    useContext(FormContext);

  const {
    register,
    formState: { errors, isValid, dirtyFields },
    setValue,
    watch,
    handleSubmit,
    getValues,
    trigger
  } = useFormContext<IEventForm>();

  const watchEventType = watch('type');
  const watchStreamUrl = watch('stream_url');
  const watchCity = watch('city');

  const isPresential = watchEventType === 'presential';
  const isOnline = watchEventType === 'online';

  const handleSetType = (watchEventType: 'presential' | 'online'): void => {
    setValue('type', watchEventType);
  };

  const handleSetAddress = ({ formatedAddress, place }: AddressData) => {
    setValue('stream_url', '');
    setNotFoundMyAddressCheckBox(true);
    setValue('place', place);
    setValue('map_url', formatedAddress.mapEmbedUrl);
    setValue('lat', formatedAddress.lat?.toString().substring(0, 20));
    setValue('lng', formatedAddress.lng?.toString().substring(0, 20));
    setValue('city', formatedAddress.city);
    setValue('state', formatedAddress.stateShortName);
    setValue('country', formatedAddress.country);
    setValue('district', formatedAddress.neighborhood);
    setValue('street', formatedAddress.street);
    setValue('number', formatedAddress.streetNumber);
    setValue('zip', formatedAddress.zipCode);
    setGoogleMapsLocation(place);
    trigger();
  };

  const handleNext = useCallback(() => {
    const enablesSteps = Array.from(stepsEnables);

    if (!enablesSteps.includes('3')) {
      enablesSteps.push('3');
      setStepsEnables(enablesSteps);
    }

    setCurrentStep('3');
  }, [setCurrentStep, setStepsEnables, stepsEnables]);

  const shouldSendNotification = (): boolean => {
    let haveAnyTicketSold = false;
    const tickets = getValues('ticket');

    tickets.some(ticket => {
      ticket.lot.some(lot => {
        if (lot.quantity_sold !== 0) {
          haveAnyTicketSold = true;

          return true;
        }
        return false;
      });
    });

    if (isCreate || !haveAnyTicketSold) {
      return false;
    }
    if (
      dirtyFields.city ||
      dirtyFields.complement ||
      dirtyFields.country ||
      dirtyFields.district ||
      dirtyFields.mapLocation ||
      dirtyFields.map_url ||
      dirtyFields.number ||
      dirtyFields.place ||
      dirtyFields.state ||
      dirtyFields.stream_url ||
      dirtyFields.street ||
      dirtyFields.zip
    ) {
      setValue('notifyAttendancesCustomMsg', 'NOTIFICAR');
      return true;
    }

    return false;
  };

  useEffect(() => {
    const neededFormatUrl = watchStreamUrl?.length === 1 && watchStreamUrl !== 'h';

    if (neededFormatUrl) {
      const text = 'http://' + watchStreamUrl;
      setValue('stream_url', text);
    }
  }, [watchStreamUrl, setValue]);

  useEffect(() => {
    if (watchCity) {
      setNotFoundMyAddressCheckBox(true);
    }

    const place = getValues('place');
    if (place) {
      setMapAutoCompleteValue(place);
    }
  }, [isCreate, watchCity, getValues]);

  const handlePrimaryButtonAction = () => {
    if (!isValid) {
      setNotFoundMyAddressCheckBox(true);
    }

    if (isCreate) {
      handleSubmit(handleNext)();
      return;
    }

    if (shouldSendNotification()) {
      setOpenModalNotifyEdit(true);
      return;
    }

    handleSubmit(handleEdit)();
  };

  const primaryButtonActionText = isCreate ? 'Próximo' : loadingEdit || !!jobsLotPending ? 'Salvando' : 'Salvar';

  return (
    <S.Wrapper>
      <Grid item xs={12}>
        <S.Title>Escolha o tipo do evento</S.Title>
      </Grid>
      <Grid container spacing={3}>
        <Grid container item>
          <div style={{ display: 'flex', gap: '16px' }}>
            <Button
              onClick={() => handleSetType('presential')}
              type={isPresential ? 'primary' : 'default'}
              icon={<EnvironmentFilled />}
            >
              Presencial
            </Button>
            <Button
              onClick={() => handleSetType('online')}
              type={isOnline ? 'primary' : 'default'}
              icon={<GlobalOutlined />}
            >
              Online
            </Button>
          </div>
        </Grid>

        {isPresential && (
          <Grid container item spacing={3}>
            <Grid item xs={12} md={8}>
              <MapAutoComplete
                getAddress={handleSetAddress}
                localValue={mapAutoCompleteValue}
                setLocalValue={setMapAutoCompleteValue}
              />
            </Grid>

            <Grid alignContent='flex-start' alignItems='center' container item xs={12} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={notFoundMyAddressCheckBox}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setNotFoundMyAddressCheckBox(event.target.checked)
                      }
                    />
                  }
                  label='Não achei meu endereço'
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          item
          spacing={3}
          style={{ display: `${!notFoundMyAddressCheckBox || !isPresential ? 'none' : 'flex'}` }}
        >
          <Grid item xs={12} md={5}>
            <TextField
              error={!!errors.city}
              fullWidth
              helperText={errors.city?.message}
              id='city-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Cidade *'
              variant='outlined'
              inputProps={{ maxLength: 50 }}
              {...register('city')}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              error={!!errors.state}
              fullWidth
              helperText={errors.state?.message}
              id='state-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Estado *'
              variant='outlined'
              inputProps={{ maxLength: 50 }}
              {...register('state')}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={!!errors.country}
              helperText={errors.country?.message}
              fullWidth
              id='country-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='País *'
              variant='outlined'
              inputProps={{ maxLength: 50 }}
              {...register('country')}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              error={!!errors.zip}
              helperText={errors.zip?.message}
              fullWidth
              id='zip-code-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='CEP'
              variant='outlined'
              inputProps={{ maxLength: 9 }}
              {...register('zip')}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              error={!!errors.street}
              helperText={errors.street?.message}
              fullWidth
              id='rua-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Rua, Avenida'
              variant='outlined'
              inputProps={{ maxLength: 100 }}
              {...register('street')}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              error={!!errors.number}
              helperText={errors.number?.message}
              fullWidth
              id='number-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Número'
              variant='outlined'
              inputProps={{ maxLength: 10 }}
              {...register('number')}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={!!errors.complement}
              helperText={errors.complement?.message}
              fullWidth
              id='complement-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Complemento'
              variant='outlined'
              inputProps={{ maxLength: 50 }}
              {...register('complement')}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={!!errors.district}
              helperText={errors.district?.message}
              fullWidth
              id='district-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Bairro'
              variant='outlined'
              inputProps={{ maxLength: 50 }}
              {...register('district')}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <TextField
              error={!!errors.reference}
              helperText={errors.reference?.message}
              fullWidth
              id='reference-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Ponto de Referência'
              variant='outlined'
              inputProps={{ maxLength: 100 }}
              {...register('reference')}
            />
          </Grid>
        </Grid>

        {isPresential && (
          <Grid container item>
            <Grid item xs={12}>
              <GoogleMaps address={googleMapsLocation} />
            </Grid>
          </Grid>
        )}

        {isOnline && (
          <Grid container item>
            <Grid item xs={12}>
              <TextField
                error={!!errors.stream_url}
                fullWidth
                helperText={errors.stream_url?.message}
                id='url-text-field'
                InputLabelProps={{
                  shrink: true
                }}
                label='URL de transmissão do evento'
                variant='outlined'
                inputProps={{ maxLength: 255 }}
                {...register('stream_url')}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Actions>
        {isCreate && (
          <Actions.Button
            type='text'
            size='large'
            id='stepback-btn-form-2'
            onClick={() => {
              if (!isEmpty(window['formErrors'])) return;
              setCurrentStep('1');
            }}
          >
            Voltar
          </Actions.Button>
        )}
        <Tooltip defaultOpen={!!jobsLotPending} open={!!jobsLotPending} title={jobsLotPending} color='#ffbc00'>
          <Actions.Button
            onClick={handlePrimaryButtonAction}
            type='primary'
            id={isCreate ? 'step-forward-btn-form-2' : 'save-btn-form-2'}
            size='large'
            disabled={loadingEdit || !!jobsLotPending}
            icon={!isCreate && <SaveOutlined />}
          >
            {primaryButtonActionText}
          </Actions.Button>
        </Tooltip>
      </Actions>
      {openModalNotifyEdit && (
        <ModalNotifyEdit
          handleRadioOption={value => {
            if (value === 3) {
              setValue('notifyAttendancesCustomMsg', 'DISABLED');
            }

            setModalNotifyOption(value);
          }}
          onCancel={() => setOpenModalNotifyEdit(false)}
          onContinue={handleSubmit(handleEdit)}
          open={openModalNotifyEdit}
          optionSelectNotifyAttendancesDialog={modalNotifyOption}
        />
      )}
    </S.Wrapper>
  );
};

export default FormTwo;
