import React from 'react';

import { IProps } from './types';

const Truncate = ({ count, text }: IProps) => {
  const truncate = (value: string) => {
    if (value && value.length >= count) {
      value = value.slice(0, count) + '...';
    }

    return value;
  };

  return (
    <div style={{ display: 'inline-block' }} title={text}>
      {truncate(text)}
    </div>
  );
};

export default Truncate;
