import React, { PureComponent } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import { WithStyles } from 'decorators/withStyles';
import imageCompress from 'helpers/imagerCompress';
import { Cropper } from 'react-image-cropper';

import ImageReader, { ImageReaderResult } from './ImageReader';
import styles from './styles';

export interface IImageSelectorResult {
  image: ImageReaderResult;
  filename: string;
  base64: string;
}

interface IState {
  image?: ImageReaderResult;
  dimensions?: { width: number; height: number };
}

interface IProps {
  opened: boolean;
  width: number;
  height: number;
  onComplete: (image: IImageSelectorResult) => void;
  classes?: any;
}

@WithStyles(styles)
export default class ImageSelector extends PureComponent<IProps, IState> {
  resizeTimeout: NodeJS.Timer;
  cropper: React.RefObject<any> = React.createRef();

  constructor(props: IProps) {
    super(props);
    this.state = { image: null };
  }

  componentDidMount() {
    window.addEventListener('resize', this.reCalculateRegion);
  }

  UNSAFE_componentWillMount() {
    window.removeEventListener('resize', this.reCalculateRegion);
  }

  onExited = () => {
    this.setState({ image: null });
  };

  handleSave = async () => {
    const { width, height, onComplete } = this.props;

    const result = await imageCompress(this.cropper.current.crop(), width, height);

    onComplete({
      image: this.state.image,
      filename: this.state.image.name,
      base64: result
    });
  };

  handleCancel = () => {
    this.props.onComplete(null);
  };

  setImage = (image: ImageReaderResult) => {
    this.setState({ image: null });

    const dimensions = this.calculateRegion(image.width, image.height);
    this.setState({ image, dimensions });
  };

  reCalculateRegion = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      const { image, dimensions } = this.state;
      if (!image) return;

      const newDimensions = this.calculateRegion(image.width, image.height);

      if (newDimensions.width === dimensions.width && newDimensions.height === dimensions.height) {
        return;
      }

      this.setState({ image: null }, () => {
        this.setState({ image, dimensions: newDimensions });
      });
    }, 500);
  };

  calculateRegion = (width: number, height: number): IState['dimensions'] => {
    const dialogWidth = window.innerWidth - 90;
    const dialogHeight = window.innerHeight - 180;

    if (height <= dialogHeight && width <= dialogWidth) {
      return { width: Math.floor(width), height: Math.floor(height) };
    }

    if (width > dialogWidth) {
      const widthRatio = dialogWidth / width;
      return this.calculateRegion(dialogWidth, height * widthRatio);
    }

    const heightRatio = dialogHeight / height;
    return this.calculateRegion(width * heightRatio, dialogHeight);
  };

  render() {
    const { image, dimensions } = this.state;
    const { classes, opened, width, height } = this.props;

    return (
      <>
        <Dialog
          open={opened || false}
          maxWidth={false}
          disableBackdropClick
          disableEscapeKeyDown
          onExited={this.onExited}
          classes={classes}
        >
          <DialogTitle>
            <Grid container spacing={3}>
              <Grid item xs={true}>
                <span className={classes.title}>Selecionar Imagem</span>
                <Typography variant='body1' className={classes.subtitle}>
                  <strong>Tamanho sugerido:</strong> {width} x {height} pixels
                </Typography>
              </Grid>
              {image && (
                <Grid item xs={false}>
                  <ImageReader onLoad={this.setImage} />
                </Grid>
              )}
            </Grid>
          </DialogTitle>

          <DialogContent className={classes.content}>
            {!image && <ImageReader onLoad={this.setImage} droppable />}

            {image && (
              <div className={classes.imageContainer} style={dimensions}>
                <Cropper
                  src={image.url}
                  ratio={width / height}
                  width={width}
                  height={height}
                  allowNewSelection={false}
                  ref={this.cropper}
                />
              </div>
            )}
          </DialogContent>

          <DialogActions>
            <Button type='text' onClick={this.handleCancel} id={'img-dialog-cancel-button'}>
              Cancelar
            </Button>

            <Button type='primary' disabled={!image} onClick={this.handleSave} id={'img-dialog-ok-button'}>
              Selecionar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
