import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, lineHeight, customBreakpoints: breakpoints } }) => css`
    margin: auto;

    .custom-template-warning-draft {
      display: flex;
      background-color: ${colors.warning.light};
      flex-direction: column;
      padding: 13px;
      margin: 16px 0 0 0;

      h2 {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: ${fontWeight('semibold')};
        color: ${colors.primary.contrastText};

        span {
          margin: 0 10px 0 0;
        }
      }

      p {
        font-size: 14px;
        font-weight: ${fontWeight('regular')};
        margin: 13px 0 0 0;
        color: ${colors.primary.contrastText};
      }
    }

    .custom-template-option {
      .template-box-button {
        display: none;
      }
    }

    .custom-template-template-title {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('bold')};
      line-height: ${lineHeight('compact')};
      margin: 0 0 8px 0;
    }

    .custom-template-template-sub-title {
      color: ${colors.primary.contrastText};
      font-size: 16px;
      font-weight: ${fontWeight('regular')};
      line-height: ${lineHeight('normal')};
      margin: 0 0 2px 0;
    }

    .custom-template-title {
      color: ${colors.primary.contrastText};
      font-size: 16px;
      font-weight: ${fontWeight('semibold')};
      line-height: ${lineHeight('normal')};
      margin-bottom: 16px;
    }

    .custom-template-contat-title {
      color: ${colors.primary.contrastText};
      font-size: 14px;
      font-weight: ${fontWeight('bold')};
      line-height: ${lineHeight('compact')};
      margin: 16px 0 26px 0;
    }

    .custom-template-hr {
      margin-top: 16px;
      border: ${colors.grey[200]} solid 1px;
    }

    .custom-template-option-hr {
      margin: 16px 0 0 0;
      border: ${colors.grey[200]} solid 1px;
    }

    .custom-template-text-field {
      background: white;
      label {
        color: ${colors.primary.contrastText};
        font-size: 14px;
        font-weight: ${fontWeight('semibold')};
        line-height: ${lineHeight('compact')};
      }
    }

    @media (${breakpoints}) {
      .custom-template-warning-draft {
        padding: 16px;
        margin: 24px 0 0 0;

        h2 {
          font-size: 14px;
        }

        p {
          font-size: 14px;
          margin: 16px 0 0 0;
        }
      }

      .custom-template-title {
        font-size: 20px;
        line-height: ${lineHeight('comfortable')};
        margin: 16px 0 22px 0;
      }

      .custom-template-hr {
        margin: 12px 0 24px 0;
      }

      .custom-template-option-hr {
        margin: 24px 0 0 0;
      }
    }
  `}
`;
