import React from 'react';

import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { Typography } from 'antd';

import * as S from './styles';
import { EventDateProps, EventDateTime } from './types';

const EventDate = ({ date }: EventDateProps) => {
  const renderEventDate = (date: EventDateTime, index: number) => {
    return (
      <React.Fragment key={date.id}>
        {!!index && <hr />}
        <div className='event-date-info'>
          <div className='event-date-date'>
            <CalendarOutlined />
            <p>{date.date}</p>
          </div>
          <div className='event-date-time'>
            <ClockCircleOutlined />
            <p>
              {date.startTime} às {date.endTime}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <S.Container>
      <Typography.Title level={5}>Dias e horários</Typography.Title>
      <div className='event-date-content'>{date?.map(renderEventDate)}</div>
    </S.Container>
  );
};

export default EventDate;
