import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, lineHeight, customBreakpoints: breakPoints } }) => css`
    a {
      color: ${colors.primary.contrastText};
    }

    .event-links-text-field {
      margin: 0 15px 4px 0;

      &.lot-links {
        input {
          padding: 12px 16px 12px 0px;
        }
      }
    }

    .event-links-title {
      display: flex;
      gap: 8px;
      align-items: center;
      color: ${colors.primary.contrastText};
      font-size: 14px;
      line-height: ${lineHeight('normal')};
      font-weight: ${fontWeight('semibold')};
      margin: 12px 0 10px 0;
    }

    .event-links-button {
      border-color: #d9d9d9;
      color: #000000;
      width: 100%;
    }

    .event-links-button:active {
      border-color: #000000;
      background: #fff;
    }

    .event-links-edit-title {
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('bold')};
      line-height: ${lineHeight('compact')};
      font-size: 14px;
      margin: 4px 0 12px 0;
    }

    .event-links-edit-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 12px 12px 12px;
      border: solid 1px #eceff1;
      background-color: white;
      border-radius: 4px;
    }

    .event-links-sales-message-issue {
      background-color: #f5f5f5;
      border: 1px solid #d0d0d0;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 11px 13px;
      color: #000000;
      font-size: inherit;

      a {
        color: #0d47a1;
      }
    }

    .event-links-sales-page {
      display: flex;
      flex-direction: column;
      padding: 10px;

      .event-links-button-on-desktop {
        display: none;
      }

      .event-links-container-desk {
        .event-links-url {
          display: none;
        }
      }

      .event-links-sales-on-desktop {
        display: none;
      }
    }

    @media (${breakPoints.md}) {
      .event-links-text-field {
        width: 80%;
      }

      .event-links-sales-page {
        .event-links-title {
          font-size: 14px;
          margin: 12px 0 11px 0;

          &.first {
            font-size: 14px;
            margin: 12px 0 11px 0;
          }
        }

        .event-links-container-desk {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .event-links-url {
            line-height: ${lineHeight('normal')};
            font-weight: ${fontWeight('regular')};
            color: ${colors.primary.contrastText};
            font-size: 14px;
            display: flex;
          }

          .event-links-button {
            border-color: #d9d9d9;
            display: flex;
            width: 101px;
          }
        }

        .event-links-sales-inputs {
          align-items: center;
          display: flex;
          flex-direction: row;

          .event-lot {
            margin: 0;

            svg {
              margin: 4px 0 0 0;
            }
          }

          .event-links-sales-on-desktop {
            display: flex;
            height: 45px;
            width: 470px;
            margin: 0;
          }
        }
      }
    }
  `}
`;

export const ContainerPartner = styled.div`
  ${({ theme: { customBreakpoints: breakPoints } }) => css`
    display: flex;
    flex-direction: column;

    .event-link-helper-text {
      display: flex;
      color: #78909c;
      margin: 0;
      font-size: 14px;
      margin-top: 3px;
      text-align: left;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      line-height: 1.2;
      &.desktop {
        display: none;
      }
    }

    .Mui-disabled {
      border: #b0bec5 1px solid;
    }

    .event-links-text-field {
      &.desktop {
        display: none;
      }
    }

    @media (${breakPoints.md}) {
      .event-links-title-desktop-version {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 7px 0 2px 0;

        .event-links-text-field {
          width: 479px;
          display: none;

          &.desktop {
            display: flex;
            margin: 0;
          }
        }

        .event-links-button {
          width: 101px;
        }
      }

      .event-link-helper-text {
        display: none;

        &.desktop {
          color: #78909c;
          display: flex;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.2;
          text-align: left;
        }
      }
    }
  `}
`;

export const ContainerBlinket = styled.div`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakPoints } }) => css`
    display: flex;
    flex-direction: column;

    .event-links-url {
      display: flex;
      font-size: 14px;
      color: ${colors.primary.contrastText};
      font-weight: ${fontWeight('regular')};
    }

    .event-links-text-field {
      &.blinket {
        p {
          display: none;
        }
      }

      &.desktop {
        display: none;
        margin: 0;
      }
    }

    @media (${breakPoints.md}) {
      .event-links-container-desk {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .event-links-text-field {
          width: 307px;
          display: none;

          &.desktop {
            display: flex;
          }
        }

        .event-links-button {
          width: 101px;
        }
      }
    }
  `}
`;

export const ContainerLot = styled.div`
  ${({ theme: { customBreakpoints: breakPoints } }) => css`
    .event-links-container-desk {
      .event-links-text-field {
        max-width: 479px;
      }

      .button {
        max-width: 101px;
      }
    }

    @media (${breakPoints.md}) {
      .event-links-text-field {
        margin: 0;
      }
    }
  `}
`;
