import { PushcaseMadeRoutes } from './types';

const generalRoutes: PushcaseMadeRoutes[] = [
  {
    path: '/obrigado_pela_compra',
    import: 'PurchaseMade'
  },
  {
    path: '/obrigado_pela_compra/seven-summit',
    import: 'PushcaseMadeSevenSummit'
  }
];

export default generalRoutes;
