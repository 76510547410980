type TemplatePageInfos = { name: string; isPartner: boolean; label: string };

type TemplatePages = {
  [key: string]: TemplatePageInfos | undefined;
};

const templatePages: TemplatePages = {
  basic: {
    name: 'v1',
    isPartner: false,
    label: 'basic'
  },
  pop: {
    name: 'v2',
    isPartner: false,
    label: 'pop'
  },
  orbit: {
    name: 'orbit',
    isPartner: true,
    label: 'orbit'
  }
};
export default templatePages;
