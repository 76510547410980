import { AttendanceRoutes } from './types';

const attendanceRoutes: AttendanceRoutes[] = [
  {
    path: '/presence-list/:eventId',
    render: 'AttendanceList'
  },
  {
    path: '/attendance-list/:eventId/:cliCod',
    import: 'Admin/AttendanceList/AttendanceDetail'
  },
  {
    path: '/presence-list/:eventId/participant/:cliCod',
    import: 'Participants/Detail',
    newDirectory: true
  }
];

export default attendanceRoutes;
