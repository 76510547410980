import { Observable } from 'rxjs';

import useObservable from '@eduzz/houston-hooks/useObservable';

const useCustomObservable = <T, E, D>(
  observableGenerator: () => Observable<T>,
  dependencies: D
): { value: T; error: E; completed: boolean; loading: boolean } => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [value, error, completed, loading] = useObservable(observableGenerator, [dependencies]);
  return { value, error, completed, loading };
};

export default useCustomObservable;
