import { Button } from '@mui/material';
import styled from 'styled-components';

import { CustomizedButtonProps } from './types';

export const CustomizedButton = styled(Button)<CustomizedButtonProps>`
  background-color: ${props => (props.primary ? '#FFBC00' : '#ffffff')};
  border: ${props => !props.primary && 'thin solid #D9D9D9'};
  border-radius: 4px;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  padding: 3px 8px;

  &:hover {
    background-color: ${props => (props.primary ? '#FFBC00' : '#ffffff')};
    border-color: ${props => !props.primary && 'rgba(0, 0, 0, 0.85)'};
    box-shadow: none;
    color: rgba(0, 0, 0, 0.85);
  }
`;
