import { AppStyle } from 'decorators/withStyles';

const styles: AppStyle = theme => ({
  loader: {
    width: 70,
    height: 70,
    color: theme.palette.secondary.light
  },
  paper: {
    boxShadow: 'none',
    outline: 'none',
    backgroundColor: 'transparent'
  }
});

export default styles;
