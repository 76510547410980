import { MyEventsRoutes } from './types';

const myEventsRoutes: MyEventsRoutes[] = [
  {
    path: '/my-events',
    import: 'Admin/MyEvents'
  },
  // {
  //   path: '/my-events/:id/event-panel/old',
  //   import: 'Admin/MyEvents/Dashboard'
  // },
  {
    path: '/my-events/:id/edit',
    import: 'Admin/EventEdit'
  }
  // {
  //   path: '/my-events/:id/basic-data',
  //   import: 'MyEvents/BasicData',
  //   newDirectory: true
  // },
  // {
  //   path: '/my-events/:id/event-page',
  //   import: 'MyEvents/EventPage',
  //   newDirectory: true
  // },
  // {
  //   path: '/my-events/:id/event-page/custom-template',
  //   import: 'MyEvents/CustomTemplate',
  //   newDirectory: true
  // },
  // {
  //   path: '/my-events/:id/event-panel',
  //   import: 'MyEvents/EventPanel',
  //   newDirectory: true
  // },
  // {
  //   path: '/my-events/:id/basic-data/old',
  //   import: 'Admin/MyEventsTabs'
  // },
  // {
  //   path: '/my-events/:id/cart',
  //   import: 'MyEvents/EventCart',
  //   newDirectory: true
  // },
  // {
  //   path: '/my-events/:id/offers',
  //   import: 'MyEvents/CustomOffers',
  //   newDirectory: true
  // },
  // {
  //   path: '/my-events/:id/sale-links',
  //   import: 'MyEvents/SaleLinksPanel',
  //   newDirectory: true
  // }
];

export default myEventsRoutes;
