import React, { useMemo } from 'react';

import { useCustomObservable } from 'hooks';
import userService from 'services/user/index';
import { User } from 'services/user/types';
import { ThemeProvider } from 'styled-components';

import breakpoints from './breakpoints';
import { oldHoustonTheme } from './oldhoustontheme';
import * as themes from './themes';
import { CustomHoustonTheme, StyledHoustonThemeProps } from './types';

const StyledHoustonTheme = ({ children }: StyledHoustonThemeProps) => {
  const { value: userData } = useCustomObservable<User, void, void>(() => userService.getUser(), null);

  const userTheme = useMemo(() => (userData?.is_clube_black ? themes.clubBlackTheme : themes.defaultTheme), [userData]);

  const customHounstonTheme: CustomHoustonTheme = useMemo(
    () => ({ ...oldHoustonTheme, customBreakpoints: breakpoints, user: userTheme }),
    [userTheme]
  );

  return <ThemeProvider theme={customHounstonTheme}>{children}</ThemeProvider>;
};

export default StyledHoustonTheme;
