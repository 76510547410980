import { Paper, Typography } from '@mui/material';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

type WrapperDisable = {
  is_active?: boolean;
};

export const Wrapper = styled.div<WrapperDisable>`
  ${({ is_active }) => css`
    filter: ${!!is_active ? 'unset' : 'opacity(0.4) grayscale(1)'};
    pointer-events: ${!!is_active ? 'unset' : 'none'};
  `}
`;

export const CustomizedPaper = styled(Paper)`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px;
  padding: 16px;
`;

export const CustomizedTypography = styled(Typography)`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
`;

export const MenuButton = styled(Button)`
  margin: 0px;
  padding: 0px;
  border: none;
  font-weight: 600;
`;

export const LabelWrapper = styled.div`
  display: flex;
  grid-gap: 5px;
`;
