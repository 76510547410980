import ConfigBuilder from '@react-form-fields/material-ui/config/builder';
import lang from '@react-form-fields/material-ui/lang/pt-br';

const fieldConfig = new ConfigBuilder()
  .fromLang(lang)
  .setValidationOn('onBlur')
  .addMask(
    'money',
    (value: number | string) => {
      if (value === null || value === undefined || value === '') return '';
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(value) || 0);
    },
    value => {
      value = (value || '').toString().replace(/[^\d,]/gi, '');

      if (!value.includes(',')) {
        value = '0,' + value.padStart(2, '0');
      }

      const [, cents] = value.split(',');
      if (cents && cents.length !== 2) {
        value = value
          .replace(',', '')
          .replace(/(\d+)?(\d{2})/gi, '$1,$2')
          .replace(/^,/gi, '0,');
      }

      return parseFloat(value.replace(/\./gi, '').replace(',', '.'));
    }
  )
  .setTrumbowygConfig(
    {
      btns: [
        ['undo', 'redo'],
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['removeformat'],
        ['fullscreen']
      ]
    },
    true
  )
  .build();

export default fieldConfig;
