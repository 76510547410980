import { UrlObject } from 'url';

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/* Realiza a validação com o algoritmo oficial da Receita Federal do Brasil */
/* Fonte: https://www.geradorcpf.com/javascript-validar-cpf.htm */
export const validateCpf = (cpf: string) => {
  if (cpf === '' || !cpf) return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }

  // Validate 1º digit
  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9))) {
    return false;
  }

  // Validate 2ª digit
  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10))) {
    return false;
  }

  return true;
};

export const validatePhone = (value: string) => {
  const regex = new RegExp('^\\([0-9]{2}\\) (([0-9]{4}-[0-9]{4})|([0-9]{5}-[0-9]{4}))$');

  return regex.test(value);
};

export const validateURL = (str: string) => {
  if (str === '') {
    return true;
  }
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const validatePathName = (url: UrlObject) => {
  const validateUrl = (pathname: string, pathnameValidate: string) => {
    const regexp = new RegExp(`/${pathnameValidate}/[a-zA-Z0-9]`);
    return regexp.test(pathname);
  };

  const pages = {
    '/home': 'Início',
    '/event': 'Criar Evento',
    '/my-events': 'Meus Eventos',
    '/presence-list': 'Participantes',
    '/my-tickets': 'Meus Ingressos',
    '/help-center': 'Central de Ajuda'
  };

  const isTicketDetails = validateUrl(url.pathname, 'my-tickets');
  const isEventDetails = validateUrl(url.pathname, 'my-events');
  const isEventEdit = validateUrl(url.pathname, 'event');
  const isPresenceListDetails = validateUrl(url.pathname, 'presence-list');

  let customPage = pages[url.pathname.slice(0, url.pathname.endsWith('/') ? -1 : url.pathname.length)];

  if (isTicketDetails) {
    customPage = 'Meus Ingressos - Detalhes';
  }

  if (isEventDetails) {
    customPage = 'Meus Eventos - Detalhes';
  }

  if (isEventEdit) {
    customPage = 'Meus Eventos - Editar';
  }

  if (isPresenceListDetails) {
    customPage = 'Lista de Participantes - Detalhes';
  }

  return customPage;
};
