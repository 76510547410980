import React from 'react';

import { GOOGLE_MAPS_PLACE_APIKEY } from 'settings';

import { IProps } from './types';

const GoogleMaps = ({ address }: IProps) => {
  return (
    <div>
      <iframe
        title='google-maps'
        style={{ border: 0, width: '100%', height: '300px', marginTop: 16 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_PLACE_APIKEY}&q=${address}`}
      />
    </div>
  );
};

export default GoogleMaps;
