import React, { PureComponent } from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { Button, Typography } from 'antd';
import ComponentLoader from 'components/Shared/ComponentLoader';
import Toast from 'components/Shared/Toast';
import { WithStyles } from 'decorators/withStyles';
import { hjTagging } from 'helpers/functions';
import { IAttendaceList } from 'interfaces/attendanceList';
import { IEvent } from 'interfaces/event';
import { IDataFormatting, IDisplayInfo, IPrintFormatting } from 'interfaces/labelInfo';
import { ITags } from 'interfaces/tags';
import attendanceListService from 'services/attendanceList';

import { IGenerateTagsModel } from '../../types';
import DisplayedFields from './DisplayedFields';
import LabelSummary from './LabelSummary';
import PrintFormatForm from './PrintFormat';
import styles from './styles';
import TextFormattingForm from './TextFormatting';

interface IProps {
  opened: boolean;
  onCancel: () => void;
  classes?: any;
  eventDetail: Partial<IEvent>;
  tags: ITags[];
  attendanceList: Array<IAttendaceList>;
  participantsToPrint?: Partial<IAttendaceList>[];
  allParticipantsAreSelected: boolean;
  totalOfParticipants: number;
  generateTagsModel?: IGenerateTagsModel | null;
  isLoadingGenerateTagsModel: boolean;
}

interface IState {
  loading: boolean;
  error?: null;
  currentStep: number;
  printFormatting: IPrintFormatting;
  dataFormatting: IDataFormatting;
  displayInfo: IDisplayInfo;
  isPrintingFinished: boolean;
  participantChoice: { value: string; label: string }[];
}

const isBool = (something: unknown) => typeof something === 'boolean';

@WithStyles(styles)
export default class LabelManagerDialog extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { generateTagsModel } = props;

    const printFormatting = {
      printFormat: generateTagsModel?.printFormatting.printFormat ?? '8099F',
      labelCount: generateTagsModel?.printFormatting.labelCount ?? 10,
      firstLabel: generateTagsModel?.printFormatting.firstLabel ?? 1
    };

    const displayInfo = {
      isDisplayName: isBool(generateTagsModel?.displayInfo.isDisplayName)
        ? generateTagsModel?.displayInfo.isDisplayName
        : true,
      nameDisplay: generateTagsModel?.displayInfo.nameDisplay ?? 'full',
      displayEvent: isBool(generateTagsModel?.displayInfo.displayEvent)
        ? generateTagsModel?.displayInfo.displayEvent
        : true,
      displayTicket: isBool(generateTagsModel?.displayInfo.displayTicket)
        ? generateTagsModel?.displayInfo.displayTicket
        : true,
      displayQR: isBool(generateTagsModel?.displayInfo.displayQR) ? generateTagsModel?.displayInfo.displayQR : true
    };

    const dataFormatting = {
      nameFontSize: generateTagsModel?.dataFormatting.nameFontSize ?? '4',
      ticketFontSize: generateTagsModel?.dataFormatting.ticketFontSize ?? '4',
      eventFontSize: generateTagsModel?.dataFormatting.eventFontSize ?? '4',
      textAlign: generateTagsModel?.dataFormatting.textAlign ?? 'left'
    };

    this.state = {
      loading: false,
      isPrintingFinished: false,
      currentStep: !!generateTagsModel ? 3 : 0,
      printFormatting,
      displayInfo,
      dataFormatting,
      participantChoice: this.props.participantsToPrint.map(participant => ({
        value: participant.invite_key,
        label: participant.name
      }))
    };

    hjTagging('page:presencelist-labelmanager');
  }
  componentDidUpdate = (nextProps: Readonly<IProps>) => {
    if (JSON.stringify(nextProps.generateTagsModel) !== JSON.stringify(this.props.generateTagsModel)) {
      this.setInitialTagsModel();
    }
  };

  setInitialTagsModel = () => {
    const { generateTagsModel } = this.props;

    this.setState({
      currentStep: 3,
      dataFormatting: generateTagsModel.dataFormatting,
      printFormatting: generateTagsModel.printFormatting,
      displayInfo: generateTagsModel.displayInfo
    });
  };

  handleChangeFormatting = (data: any) => {
    this.setState({ dataFormatting: { ...data } });
  };

  handleChangePrintFormat = (data: string) => {
    this.setState({
      printFormatting: {
        ...this.state.printFormatting,
        labelCount: parseInt(data.substring(data.length - 2, data.length)),
        printFormat: data
      }
    });
  };

  handleChangeFirstLabel = (data: number) => {
    this.setState({
      printFormatting: {
        ...this.state.printFormatting,
        firstLabel: data
      }
    });
  };

  handleChangeNameDisplay = (data: string) => {
    this.setState({
      displayInfo: {
        ...this.state.displayInfo,
        nameDisplay: data
      }
    });
  };

  handleChangeDisplayInfo = (info: string) => {
    this.setState({
      displayInfo: {
        ...this.state.displayInfo,
        [info]: !this.state.displayInfo[info]
      }
    });
  };

  handleDownloadFile = (data: any, filename: string) => {
    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + data;
    a.target = '_blank';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  getParticipantFilters = () => {
    if (this.props.allParticipantsAreSelected) {
      return {
        invite_key_multiple: []
      };
    }
    const participantsId = this.state.participantChoice.map(participant => {
      return participant.value;
    });
    return {
      invite_key_multiple: participantsId
    };
  };

  getPrintFormatting = () => {
    return {
      ...this.state.printFormatting,
      printFormat: this.state.printFormatting.printFormat
    };
  };

  handleExportPDF = () => {
    this.setState({
      loading: true
    });
    const { allParticipantsAreSelected } = this.props;
    attendanceListService
      .generateTags({
        eventId: this.props.eventDetail.id,
        participantFilter: this.getParticipantFilters(),
        allParticipantsAreSelected,
        printFormatting: this.getPrintFormatting(),
        displayInfo: this.state.displayInfo,
        dataFormatting: this.state.dataFormatting
      })
      .subscribe(
        response => {
          this.handleDownloadFile(response, 'etiquetas.pdf');
          Toast.show('As etiquetas foram geradas com sucesso!');
          this.setState({
            loading: false,
            isPrintingFinished: true
          });
        },
        ({ data: err }) => {
          this.setState({
            loading: false
          });
          if (err.message === 'Você não possui permissão para realizar esta ação') {
            Toast.error(err.message, 6000);
          } else if (err.message === 'Request failed with status code 404') {
            Toast.error('O filtro atual não retornou nenhum participante do evento.', 6000);
          } else {
            Toast.error('Ocorreu um erro ao tentar exportar as etiquetas.', 6000);
          }
        }
      );
  };

  getSteps = () => {
    return ['Formato', 'Campos', 'Formatação', 'Resumo'];
  };

  setActiveStep = (prevActiveStep: number, instruction: string) => {
    const newActiveStep = {
      back: () => this.setState({ currentStep: prevActiveStep - 1 }),
      next: () => this.setState({ currentStep: prevActiveStep + 1 }),
      reset: () => this.setState({ currentStep: 0 })
    };
    newActiveStep[instruction]();
  };

  render() {
    const { opened, onCancel, classes, allParticipantsAreSelected, isLoadingGenerateTagsModel } = this.props;

    const {
      currentStep,
      dataFormatting,
      printFormatting,
      displayInfo,
      isPrintingFinished,
      loading,
      participantChoice
    } = this.state;

    const stepContentData = {
      0: (
        <PrintFormatForm
          data={printFormatting}
          onChangePrintFormat={this.handleChangePrintFormat}
          onChangeFirstLabel={this.handleChangeFirstLabel}
        />
      ),
      1: (
        <DisplayedFields
          data={displayInfo}
          onChangeNameDisplay={this.handleChangeNameDisplay}
          onChangeDisplayInfo={this.handleChangeDisplayInfo}
        />
      ),
      2: (
        <TextFormattingForm onChange={this.handleChangeFormatting} data={dataFormatting} displayedInfo={displayInfo} />
      ),
      3: (
        <LabelSummary
          printFormatting={printFormatting}
          displayInfo={displayInfo}
          dataFormatting={dataFormatting}
          participantChoice={participantChoice}
          eventDetail={this.props.eventDetail}
          allParticipantsAreSelected={allParticipantsAreSelected}
          totalOfParticipants={this.props.totalOfParticipants}
        />
      )
    };

    const steps = this.getSteps();

    const handleNext = (currentStep: number) => {
      if (
        !displayInfo.isDisplayName &&
        !displayInfo.displayEvent &&
        !displayInfo.displayQR &&
        !displayInfo.displayTicket
      ) {
        Toast.error('Selecione pelo menos um campo para exibir na etiqueta.');
        return;
      }
      this.setActiveStep(currentStep, 'next');
    };

    const handleBack = (currentStep: number) => {
      this.setActiveStep(currentStep, 'back');
    };

    return (
      <Dialog open={opened} disableEscapeKeyDown fullWidth maxWidth='md' style={{ overflow: 'auto' }}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Gerar Etiquetas</div>
            <Button
              type='text'
              onClick={onCancel}
              style={{ color: '#000000' }}
              id='labeldialog-closedialog-button'
              icon={<CloseOutlined />}
            />
          </div>
        </DialogTitle>

        <DialogContent style={{ marginTop: -15 }}>
          {(loading || isLoadingGenerateTagsModel) && (
            <div className={classes.finished}>
              <ComponentLoader />
            </div>
          )}

          {isPrintingFinished && !loading && !isLoadingGenerateTagsModel && (
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.finished}>
                  <CheckCircleOutlined className={classes.iconFinished} />

                  <Typography.Title level={5}>As etiquetas foram exportadas com sucesso!</Typography.Title>

                  <Typography.Text style={{ textAlign: 'center', maxWidth: '60%' }}>
                    Verifique nos seus downloads.
                    <br />
                    Nas opções de impressão, certifique-se de que o tamanho do papel esteja de acordo com sua folha de
                    etiquetas e que a escala e margens estejam no tamanho padrão.
                  </Typography.Text>
                </div>
              </Grid>
            </Grid>
          )}

          {!isPrintingFinished && !loading && !isLoadingGenerateTagsModel && (
            <Grid container>
              <Hidden smDown>
                <Grid item md={2}>
                  <Stepper activeStep={currentStep} orientation='vertical'>
                    {steps.map(label => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Hidden>

              <Grid item md={10} xs={12}>
                <Stepper
                  activeStep={currentStep}
                  orientation='vertical'
                  connector={<ConnectorDiv />}
                  style={{ marginTop: -20 }}
                >
                  {steps.map((label, index) => (
                    <StepContent key={index}>
                      <div>{stepContentData[index]}</div>
                    </StepContent>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        {!isLoadingGenerateTagsModel && (
          <DialogActions
            style={{
              margin: 0,
              borderTop: '1px solid #e0e2f8',
              padding: '8px 21px'
            }}
          >
            <div className={classes.actionsContainer}>
              {!isPrintingFinished && (
                <>
                  <Button
                    type='text'
                    disabled={currentStep === 0 || (currentStep === 3 && loading)}
                    onClick={() => handleBack(currentStep)}
                    key={'step-back-labeldialog'}
                  >
                    Voltar
                  </Button>

                  <Button
                    key={'next-step-labeldialog'}
                    type='primary'
                    disabled={currentStep === 3 && loading}
                    onClick={() => (currentStep < 3 ? handleNext(currentStep) : this.handleExportPDF())}
                  >
                    {currentStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
                  </Button>
                </>
              )}
              {isPrintingFinished && (
                <Button type='text' onClick={onCancel} key={'cancel-or-close-labeldialog-btn'}>
                  Fechar
                </Button>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

class ConnectorDiv extends React.Component {
  public render() {
    return <div style={{ marginTop: -8 }} />;
  }
}
