import React from 'react';

import EnvironmentFilled from '@ant-design/icons/EnvironmentFilled';
import { TextField, InputAdornment, Paper } from '@mui/material';
import PlacesAutocomplete from 'react-places-autocomplete';
import geoLocation from 'services/GeoLocation';

import * as S from './styles';
import { MapAutoCompleteProps } from './types';

const MapAutoComplete = ({ getAddress, localValue, setLocalValue }: MapAutoCompleteProps) => {
  const handleChange = (value: string): void => {
    setLocalValue(value);
  };

  const handleSelect = async (address: string, _: string, suggestion?: any): Promise<void> => {
    const formartedSuggestion = !!suggestion
      ? `${suggestion.formattedSuggestion.mainText} - ${suggestion.formattedSuggestion.secondaryText}`
      : address;

    const data = await geoLocation.geoLocationToGoogleLocation({ address });

    setLocalValue(formartedSuggestion);

    getAddress({ formatedAddress: data, place: formartedSuggestion });
  };

  return (
    <S.Wrapper>
      <PlacesAutocomplete onChange={handleChange} onSelect={handleSelect} value={localValue}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
          <div>
            <TextField
              fullWidth
              id='local-text-field'
              InputProps={{
                startAdornment: <InputAdornment position='start'>{<EnvironmentFilled />}</InputAdornment>
              }}
              label='Local'
              placeholder='Digite o nome do local do evento, da rua, ou o CEP'
              variant='outlined'
              {...getInputProps({})}
            />

            {loading ? (
              <Paper>Carregando...</Paper>
            ) : (
              !!suggestions.length && (
                <Paper style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  {suggestions.map((suggestion: any) => {
                    return (
                      <S.Sugestion key={suggestion.index} {...getSuggestionItemProps(suggestion)}>
                        <S.Span>{suggestion.description}</S.Span>
                      </S.Sugestion>
                    );
                  })}
                </Paper>
              )
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </S.Wrapper>
  );
};
export default MapAutoComplete;
