import Toast from 'components/Shared/Toast';

export const errorMessage = {
  generic: (complement?: string) => `Ocorreu um erro ${complement || ''}. Tente novamente`
};

export default function errorHandler(err: any, message?: string) {
  if (err.message === 'Você não possui permissão para realizar esta ação') {
    Toast.error(err.message, 6000);
  } else if (err?.code === 'ERR_CUSTOM') {
    Toast.error(err.details);
  } else if (message || err.message) {
    Toast.error(message ?? err.message);
  }
}
