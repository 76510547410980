import React from 'react';

import TablePagination from '@material-ui/core/TablePagination';

import { IProps } from './types';

const TablePaginationDefault = ({ pagination, loading, update, label }: IProps) => {
  if (loading) {
    return null;
  }

  if (!pagination) {
    return null;
  }

  if (pagination.totalRows > 0) {
    return (
      <TablePagination
        component='div'
        rowsPerPageOptions={[12]}
        count={pagination.totalRows}
        rowsPerPage={pagination.size}
        page={pagination.page - 1}
        labelRowsPerPage='Itens por página'
        labelDisplayedRows={({ from, to, count }) => `Exibindo ${from} a ${to} de ${count} ${label ? label : 'itens'}`}
        onChangePage={(event, page) => update(page + 1)}
      />
    );
  }

  return null;
};

export default TablePaginationDefault;
