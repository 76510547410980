import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors, fontWeight, customBreakpoints: breakpoints } }) => css`
    .event-panel-title {
      font-weight: ${fontWeight('semibold')};
      font-size: 16px;
      color: ${colors.primary.contrastText};
    }

    @media (${breakpoints.md}) {
      .event-panel-title {
        font-size: 20px;
      }
    }
  `}
`;
