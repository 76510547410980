import React from 'react';

import { TextBlock } from 'react-placeholder/lib/placeholders';

const CustomLoader = () => (
  <div className='my-awesome-placeholder'>
    <TextBlock rows={7} color='#98989824' style={{ padding: 32 }} />
  </div>
);

export default CustomLoader;
