import React, { Fragment, useEffect, useState } from 'react';

import { DeleteOutlined, PlusSquareOutlined, CloseOutlined } from '@ant-design/icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Spin } from 'antd';
import Loading from 'components/Shared/Loading';
import Toast from 'components/Shared/Toast';
import errorHandler, { errorMessage } from 'errors/errorHandler';
import { IAttendaceList } from 'interfaces/attendanceList';
import { ITags } from 'interfaces/tags';
import attendanceListService from 'services/attendanceList';

import styles from './styles';
import { IProps } from './types';

const LinkMarkerDialog = ({
  opened,
  classes,
  markers,
  selectedParticipants,
  isMultipleAction,
  client,
  allParticipantsAreSelected,
  eventId,
  onCancel
}: IProps) => {
  const [tags, setTags] = useState<ITags[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (client?.invite_key) {
      setLoading(true);
      attendanceListService.getInviteTags(client.invite_key).subscribe(
        ({ data }) => {
          setTags(data);
          setLoading(false);
        },
        ({ data: err }) => {
          setLoading(false);
          errorHandler(err, errorMessage.generic('ao buscar marcador'));
        }
      );
    }
  }, [client]);

  function handleLinkTag(tag: ITags, func: (client: IAttendaceList, tag: ITags) => void, remove?: boolean) {
    setInProgress(true);
    if (isMultipleAction) {
      return linkTagsMultiple(tag);
    }
    attendanceListService
      .linkTag({
        invite_key: client.invite_key,
        tag_id: tag.id
      })
      .subscribe(
        () => {
          Toast.show(`Marcador ${remove ? 'removido' : 'adicionado'}`);
          func(client, tag);
          setInProgress(false);
        },
        ({ data: err }) => {
          errorHandler(err, errorMessage.generic('ao adicionar marcador'));
          setInProgress(false);
        }
      );
  }

  function linkTagsMultiple(tag: ITags) {
    const inviteKeys: string[] = [];
    selectedParticipants.map(item => {
      const inviteKey = item.invite_key.split(' ');
      inviteKey.map((invite: any) => {
        if (invite.length > 0) {
          inviteKeys.push(invite);
        }
        return invite;
      });
      return item;
    });
    attendanceListService
      .linkTagMultiple({
        tag_id: tag.id,
        invite_keys: allParticipantsAreSelected ? [] : inviteKeys,
        eventId: eventId,
        allParticipantsAreSelected
      })
      .subscribe(
        () => {
          Toast.show('Marcador adicionado com sucesso');
          onCompleteLink(client, tag);
          setInProgress(false);
        },
        ({ data: err }) => {
          errorHandler(err, errorMessage.generic('ao adicionar marcador'));
          setInProgress(false);
        }
      );
  }

  function markerIcon(item: ITags, remove: boolean) {
    return (
      <Tooltip title={` ${remove ? 'Remover' : 'Atribuir'} Marcador`}>
        {inProgress ? (
          <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
            <Spin size='small' />
          </div>
        ) : (
          <Button
            type='text'
            onClick={() => handleLinkTag(item, remove ? onCompleteUnlink : onCompleteLink, remove)}
            className={classes.label}
            id={`presencelist-assignmarkerdialog-${remove ? 'removemarkerbutton' : 'addmarkerbutton'}`}
            icon={remove ? <DeleteOutlined /> : <PlusSquareOutlined />}
          />
        )}
      </Tooltip>
    );
  }

  const onCompleteLink = (client: IAttendaceList, tag: ITags) => {
    if (isMultipleAction) return;
    const newTag = {
      ...tag,
      invite_key: client.invite_key
    };
    const newAttendanceTags = [...tags, newTag];

    setTags(newAttendanceTags);
  };

  const onCompleteUnlink = (newClientData: IAttendaceList, tag: ITags) => {
    if (isMultipleAction) return;
    setTags(tags.filter((data: any) => data.id !== tag.id));
  };

  return (
    <Dialog open={opened} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth='sm' className={classes.root}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div>Adicionar Marcador</div>
          <Button
            type='text'
            onClick={onCancel}
            id='presencelist-assignmarkerdialog-closebutton'
            icon={<CloseOutlined />}
          />
        </div>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <div style={{ width: '100%' }}>
            <Fade in={true} timeout={500}>
              <Loading showLoading={loading} content={[{ type: 'list', sizes: { xs: 12 }, height: 180 }]} />
            </Fade>
          </div>
        ) : (
          !!markers.length &&
          markers.map(item => (
            <Fragment key={item.id}>
              <Card>
                <CardContent className={classes.cardRoot}>
                  <div className={classes.cardTitle}>
                    {item.title}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '15px',
                        width: '15px',
                        marginLeft: '10px',
                        backgroundColor: item.color
                      }}
                    />
                  </div>
                  {markerIcon(item, tags.map(tag => tag.id).includes(item.id))}
                </CardContent>
              </Card>

              <div className='pb-8' />
            </Fragment>
          ))
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(LinkMarkerDialog);
