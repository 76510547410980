import { areIntervalsOverlapping, startOfDay, getHours, getMinutes, format, setHours } from 'date-fns';
import { DateDetail } from 'interfaces/event';
import { object, date } from 'yup';

export const schemaEventDates = object({
  date: date().typeError('Campo obrigatório').min(startOfDay(new Date()), 'Data deve ser maior ou igual dia de hoje.'),
  start_time: date()
    .typeError('Campo obrigatório')
    .test('valid-date-time', 'Horário inicial deve ser maior que o horário da data atual', (value, context) => {
      const startTimeHour = getHours(value);
      const startTimeMinutes = getMinutes(value);
      const parentDate = context.parent.date as Date;

      parentDate.setHours(startTimeHour);
      parentDate.setMinutes(startTimeMinutes);

      return parentDate >= new Date();
    }),
  end_time: date()
    .typeError('Campo obrigatório')
    .test('valid-dates-times', (value, context) => {
      if (value < context.parent.start_time) {
        return context.createError({
          message: 'Horário de término deve ser maior que o horário inicial.',
          path: 'end_time'
        });
      }

      if (context.parent.eventDates.length < 1) return true;

      const eventDates = context.parent.eventDates as DateDetail[];
      const currentValue = { ...context.parent };
      const starTime = getHours(new Date(currentValue.start_time));
      const endTime = getHours(new Date(currentValue.end_time));
      const dateWithStarHour = setHours(currentValue.date, starTime);
      const dateWithEndHour = setHours(currentValue.date, endTime);

      const dateToEdit = context.parent.id;

      let overlappingDateText = '';

      eventDates.forEach(item => {
        const existingStarTime = getHours(new Date(item.start_time));
        const existingEndTime = getHours(new Date(item.end_time));
        const existingDateWithStarHour = setHours(item.date, existingStarTime);
        const existingDateWithEndHour = setHours(item.date, existingEndTime);

        const currentItem = item.id;

        if (dateToEdit !== currentItem) {
          const isOverlapping = areIntervalsOverlapping(
            { start: dateWithStarHour, end: dateWithEndHour },
            { start: existingDateWithStarHour, end: existingDateWithEndHour }
          );

          if (isOverlapping) {
            overlappingDateText = `Conflito na data ${format(
              new Date(item.date),
              'dd/MM/yyyy'
            )}, com início as ${format(new Date(item.start_time), 'HH:mm')} e término as ${format(
              new Date(item.end_time),
              'HH:mm'
            )}`;
          }
        }
      });

      if (overlappingDateText !== '') {
        return context.createError({ message: overlappingDateText, path: 'end_time', type: 'Conflito de datas' });
      }

      return true;
    })
});
