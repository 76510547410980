import * as S from './styles';
import { CharCountProps } from './types';

const CharCount = ({ max, value, align }: CharCountProps) => (
  <S.Wrapper align={align}>
    <S.Counter>
      {value?.length || 0} / {max}
    </S.Counter>
  </S.Wrapper>
);

export default CharCount;
