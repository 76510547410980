import React, { useMemo } from 'react';

import { Grid } from '@material-ui/core';
import IndicatorCards from 'components/Pages/Admin/MyEvents/Dashboard/IndicatorCards';

import useStyles from './styles';
import { DashboardProps } from './types';

const Dashboard = ({ statisticsData, eventData, cacheTime }: DashboardProps) => {
  const classes = useStyles();
  const lastUpdated = useMemo(
    () => new Date(cacheTime)?.toLocaleString('pt-BR', { hour: 'numeric', minute: 'numeric' }),

    [cacheTime]
  );
  return (
    <>
      <Grid container justify='space-between' alignItems='flex-end'>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <div className={classes.sectionTitle}>Informações sobre o evento</div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <div className={classes.lastUpdate}>Dados atualizados às {lastUpdated}</div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.content}>
          <div className={classes.eventStatistics}>
            <IndicatorCards statistics={statisticsData} event={eventData} />
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Dashboard;
