import React, { Reducer, useCallback, useContext, memo, useReducer, useRef, useEffect } from 'react';

import CloseOutlined from '@ant-design/icons/CloseOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { RadioChangeEvent } from 'antd';
import TemplateBasic from 'assets/images/template-basic.png';
import TemplatePop from 'assets/images/template-pop.png';
import { Typography, Input, ColorPicker, Radio } from 'components/Shared/Antd';
import Field from 'components/Shared/Field';
import ImageSelector, { IImageSelectorResult } from 'components/Shared/ImageSelector';
import { debounce } from 'helpers/lodash';
import { useFormContext, Controller } from 'react-hook-form';
import eventService from 'services/event';

import FormContext from '../../context';
import * as S from './styles';
import { StateProps, ActionProps, ActionTypes, CropperConfigProps } from './types';

const initialState: StateProps = {
  backgroundOption: 'Cor no fundo',
  cropperConfig: { height: 500, width: 500, opened: false, onComplete: value => JSON.stringify(value) },
  imageBackground: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  imageHeader: { base64: '', filename: '', image: { height: 0, width: 0, name: '', url: '' } },
  textColorBackground: '#FFFFF',
  textColorHeader: '#000000'
};

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.cropperConfig:
      return { ...state, cropperConfig: action.value as CropperConfigProps };

    case ActionTypes.cropperResetConfig:
      return { ...state, cropperConfig: { ...initialState.cropperConfig } };

    case ActionTypes.imageHeader:
      return { ...state, imageHeader: action.value as IImageSelectorResult };

    case ActionTypes.textColorHeader:
      return { ...state, textColorHeader: action.value as string };

    case ActionTypes.imageBackground:
      return { ...state, imageBackground: action.value as IImageSelectorResult };

    case ActionTypes.textColorBackground:
      return { ...state, textColorBackground: action.value as string };

    case ActionTypes.backgroundOption:
      return {
        ...state,
        backgroundOption: action.value as string
      };

    default:
      return state;
  }
};

const EventHeading = memo(() => {
  const { customTemplateData, handleVerifyData } = useContext(FormContext);
  const form = useFormContext();

  const page_config = customTemplateData.pageContent.page_config;
  const sale_url = customTemplateData.pageContent.sale_url;

  const header_color_texts = form.watch('header_color_texts');
  const headeroption = form.watch('headeroption') ?? page_config.headeroption ?? '0';
  const headerbackgroundcolor = form.watch('headerbackgroundcolor');

  useEffect(() => {
    Object.entries(page_config).forEach(([key, value]) => form.setValue(key, value ?? ''));
    form.setValue('sale_url', sale_url ?? '');
    form.setValue('template_type', page_config.templateVersion === 'v1' ? 'basic' : 'pop');

    const regExIsUuid = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    if (regExIsUuid.test(sale_url)) {
      form.setValue('sale_url', '');
    }
    form.trigger('sale_url');
  }, []);

  const [{ backgroundOption, cropperConfig, imageHeader, ...rest }, dispatch] = useReducer(reducer, {
    ...initialState,
    imageHeader: {
      base64: page_config.logo,
      filename: page_config.logoFileName,
      image: { height: 0, width: 0, name: '', url: page_config.logo || '' }
    },
    imageBackground: {
      base64: page_config.headerimg,
      filename: page_config.headerimgFileName,
      image: { height: 0, width: 0, name: '', url: page_config.headerimg || '' }
    },
    textColorHeader: page_config.header_color_texts || initialState.textColorHeader,
    textColorBackground: page_config.headerbackgroundcolor || initialState.textColorBackground,
    backgroundOption: page_config.headeroption === '1' ? 'Imagem no fundo' : 'Cor no fundo'
  });

  const handleBackgroundOption = useCallback(
    (e: RadioChangeEvent) => {
      form.setValue('headeroption', e.target.value);
      handleVerifyData();
      dispatch({
        type: ActionTypes.backgroundOption,
        value: e.target.value === 1 ? 'Imagem no fundo' : 'Cor no fundo'
      });
    },
    [form, handleVerifyData]
  );

  const handleSetCropperConfig = useCallback(
    (config: CropperConfigProps) => {
      handleVerifyData();
      dispatch({ type: ActionTypes.cropperConfig, value: config });
    },
    [handleVerifyData]
  );

  const handleCloseCropper = useCallback(() => {
    handleVerifyData();
    dispatch({ type: ActionTypes.cropperResetConfig });
  }, [handleVerifyData]);

  const handleCropperImage = useCallback(
    (type: ActionTypes.imageBackground | ActionTypes.imageHeader, height: number, width: number, name: string) => {
      const onComplete = (value: IImageSelectorResult) => {
        if (value) {
          form.setValue(name, value.base64.replace(/^data:image\/[a-z]+;base64,/, ''));
          form.setValue(`${name}FileName`, value.filename);
          handleVerifyData();
          dispatch({ type, value });
        }

        handleCloseCropper();
      };

      handleSetCropperConfig({ opened: true, height, width, onComplete });
    },
    [form, handleCloseCropper, handleSetCropperConfig, handleVerifyData]
  );

  const handleRemoveImage = useCallback(
    (type: ActionTypes.imageHeader | ActionTypes.imageBackground, name: string) => {
      handleVerifyData();
      form.setValue(name, '');
      dispatch({ type, value: { ...initialState.imageHeader } });
    },
    [form, handleVerifyData]
  );

  const existentSaleUrl = sale_url;
  const debouncedCheckSaleUrl = useRef(debounce(nextValue => checkSaleUrl(nextValue), 1000)).current;

  const checkSaleUrl = (saleUrl: string) => {
    if (!saleUrl) {
      form.setError('sale_url', { message: 'Digite um nome para sua página de venda' });
      return;
    }
    if (saleUrl === existentSaleUrl) {
      return;
    }
    handleVerifyData();
    const hasSaleUrl = !!saleUrl && saleUrl.length >= 3;

    const validateSaleUrl = () => {
      form.setError('sale_url', { message: 'Validando' });
      eventService.availableSaleUrl(saleUrl).subscribe({
        next: data => {
          if (!data) {
            form.setError('sale_url', { message: 'Inválido' });
            return;
          }
          form.clearErrors('sale_url');
        },
        error: ({ data }) => {
          if (data?.code === 'ERR_CUSTOM') {
            form.setError('sale_url', {
              message: 'URL já usada. Escolha outra'
            });
          } else {
            form.setError('sale_url', {
              message: 'Inválido'
            });
          }
        }
      });
    };

    hasSaleUrl && validateSaleUrl();
  };

  const saleUrlError = form?.formState?.errors?.sale_url?.message;

  return (
    <S.Container>
      <Field
        name='template_type'
        render={
          <Radio.Group style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Radio value='basic'>
              <div style={{ display: 'flex', gap: '16px', alignItems: 'center', marginLeft: '8px' }}>
                <img src={TemplateBasic} alt='Imagem do estilo de template basic' />
                <Typography.Text>Template Basic</Typography.Text>
              </div>
            </Radio>
            <Radio value='pop'>
              <div style={{ display: 'flex', gap: '16px', alignItems: 'center', marginLeft: '8px' }}>
                <img src={TemplatePop} alt='Imagem do estilo de template pop' />
                <Typography.Text>Template Pop</Typography.Text>
              </div>
            </Radio>
          </Radio.Group>
        }
      />
      <div style={{ marginTop: '16px', marginBottom: '24px' }}>
        <Controller
          control={form.control}
          name='sale_url'
          render={({ field: { onChange, value } }) => (
            <>
              <Typography.Text>URL da página de venda *</Typography.Text>
              <Input
                size='large'
                prefix={<div style={{ opacity: '0.7' }}>{process.env.REACT_APP_BLINKET_EVENT_URL + '/'}</div>}
                onChange={event => {
                  const formatedSaleUrl = event.target.value
                    .toLowerCase()
                    .trim()
                    .normalize('NFD')
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');

                  onChange(formatedSaleUrl);
                  debouncedCheckSaleUrl(formatedSaleUrl);
                }}
                id='sale_url-form-one'
                value={value}
              />
              <div style={{ color: 'red', height: '16px', marginBottom: '8px' }}>{saleUrlError}</div>
            </>
          )}
        />

        <Typography.Text style={{ marginTop: '16px' }}>ID Facebook Pixel</Typography.Text>
        <Field name='pixel_key' render={<Input onChange={handleVerifyData} size='large' />} />
      </div>

      <ImageSelector {...cropperConfig} />

      <p className='event-heading-main-title'>CABEÇALHO DA PÁGINA</p>
      <div className='event-heading-logo-container'>
        <div className='event-heading-logo-image-container'>
          {imageHeader.base64 && <img src={imageHeader.base64} alt='logo do evento' className='event-heading-image' />}
        </div>

        <div style={{ width: '100%' }}>
          <Typography.Text>Logotipo do evento</Typography.Text>
          <Input
            onChange={handleVerifyData}
            name='imgHeader'
            disabled
            size='large'
            className='event-heading-text-field'
            style={{ pointerEvents: 'unset', cursor: 'unset' }}
            value={imageHeader.filename}
            prefix={
              <div
                className='action-image-adornment'
                onClick={() => handleCropperImage(ActionTypes.imageHeader, 500, 500, 'logo')}
              >
                <UploadOutlined style={{ color: '#000000' }} />
              </div>
            }
            suffix={
              <div
                className='action-image-adornment'
                onClick={() => handleRemoveImage(ActionTypes.imageHeader, 'logo')}
              >
                <CloseOutlined style={{ color: '#000000' }} />
              </div>
            }
          />
        </div>
      </div>

      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <ColorPicker
          className='event-heading-logo-image-container'
          value={header_color_texts}
          onChange={(_, hex) => {
            form.setValue('header_color_texts', hex);
            handleVerifyData();
          }}
        >
          <div style={{ width: '100%' }}>
            <Typography.Text>Cor do texto</Typography.Text>
            <Field
              name='header_color_texts'
              render={
                <Input
                  disabled
                  className='event-heading-text-field'
                  style={{ pointerEvents: 'unset', cursor: 'unset', color: 'black' }}
                  size='large'
                />
              }
            />
          </div>
        </ColorPicker>
      </div>

      <div className='event-heading-container'>
        <p className='event-heading-title'>Fundo do cabeçalho</p>
        <Field
          name='headeroption'
          render={
            <Radio.Group onChange={handleBackgroundOption} style={{ marginTop: '16px', marginBottom: '16px' }}>
              <Radio value='0'>Cor no fundo</Radio>
              <Radio value='1'>Imagem no fundo</Radio>
            </Radio.Group>
          }
        />
        <div>
          {headeroption === '0' ? (
            <ColorPicker
              value={headerbackgroundcolor}
              onChange={(_, hex) => {
                form.setValue('headerbackgroundcolor', hex);
                handleVerifyData();
              }}
            >
              <div style={{ width: '100%' }}>
                <Field
                  name='headerbackgroundcolor'
                  render={
                    <Input
                      disabled
                      className='event-heading-text-field'
                      style={{ pointerEvents: 'unset', cursor: 'unset', color: 'black' }}
                      size='large'
                    />
                  }
                />
              </div>
            </ColorPicker>
          ) : (
            <>
              <Input
                onChange={handleVerifyData}
                name='backgroundImage'
                value={rest.imageBackground.filename}
                disabled
                size='large'
                className='event-heading-text-field'
                style={{ pointerEvents: 'unset', cursor: 'unset' }}
                suffix={
                  <div
                    className='action-image-adornment'
                    onClick={() => handleRemoveImage(ActionTypes.imageBackground, 'headerimg')}
                  >
                    <CloseOutlined style={{ color: '#000000' }} />
                  </div>
                }
                prefix={
                  <div
                    className='action-image-adornment'
                    onClick={() => handleCropperImage(ActionTypes.imageBackground, 600, 1920, 'headerimg')}
                  >
                    <UploadOutlined style={{ color: '#000000' }} />
                  </div>
                }
              />
            </>
          )}
        </div>
      </div>
    </S.Container>
  );
});

export default EventHeading;
