import React from 'react';

import { Typography, Input as InputAntd } from 'components/Shared/Antd';
import Field from 'components/Shared/Field';

type InputProps = {
  legend: string;
  placeholder: string;
  name: string;
};

const Input = ({ legend, placeholder, name }: InputProps) => {
  return (
    <>
      <Typography.Text>{legend}</Typography.Text>
      <Field name={name} render={<InputAntd size='large' placeholder={placeholder} maxLength={255} />} />
    </>
  );
};
export default Input;
