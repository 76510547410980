import { IS_PROD } from 'settings';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    gtag: any;
  }
}

type GTMUser = {
  user_id: number;
  belt: string;
};

export function initializeGTM(user: GTMUser) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(user);
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  const script = document.createElement('script');
  const head = document.getElementsByTagName('head')[0];
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MM46LDB');
  `;
  head.insertBefore(script, head.firstChild);
}

type EventProps = {
  category: string;
  action: string;
  label?: string;
};

export const sendEventToGA = ({ category, action, label }: EventProps) => {
  if (IS_PROD) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label
    });
  }
};
